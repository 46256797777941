import styles from './ListLoader.module.scss';
interface IListLoader {
  count?: number;
  containerStyle?: React.CSSProperties;
  leftContentStyle?: React.CSSProperties;
  rightContentStyle?: React.CSSProperties;
  line1Style?: React.CSSProperties;
  line2Style?: React.CSSProperties;
  line3Style?: React.CSSProperties;
  hideLine1?: boolean;
  hideLine2?: boolean;
  hideLine3?: boolean;
}
const ListLoader = ({
  count = 3,
  containerStyle = {},
  leftContentStyle = {},
  rightContentStyle = {},
  line1Style = {},
  line2Style = {},
  line3Style = {},
  hideLine1 = false,
  hideLine2 = false,
  hideLine3 = false,
}: IListLoader) => {
  return (
    <div>
      {[...Array(count).keys()].map(v => (
        <div key={`loading-${v}`} className={`${styles.loadingDiv} my-2`} style={containerStyle}>
          <div className={styles.loadingImg} style={leftContentStyle}></div>
          <div className={styles.loadingContent} style={rightContentStyle}>
            {!hideLine1 && <div className={styles.loadingTitle} style={line1Style}></div>}
            {!hideLine2 && <div className={styles.loadingSecondaryLine} style={line2Style}></div>}
            {!hideLine3 && <div className={styles.loadingThirdLine} style={line3Style}></div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ListLoader;
