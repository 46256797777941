import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { ReactComponent as NotifcationIcon } from '../../assets/icons/bell.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/bars.svg';
import { ReactComponent as CartIcon } from '../../assets/icons/cart-2.svg';
import { Modal } from 'react-bootstrap';
import Notifications from 'components/Notifications/Notifications';
import IconButton from 'components/IconButton/IconButton';
import { ReactComponent as LiveFeedIcon } from '../../assets/icons/live-feed.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

const HeaderMobileView = ({
  toggleSideNav,
  cartItemCount,
  isSupplier,
  isLoggedIn,
  notificationOpen,
  onCloseNotification,
  onOpenNotification,
  notificationCount,
  liveFeedCount,
  totalPoints = 0,
  navigateTo,
}) => {
  return (
    <div className="fixed-top mobile_menuBg">
      <div className="container">
        <div className="mobile_menu p-2 d-lg-none d-xl-none">
          <div className="row align-items-center" style={{ width: '100%' }}>
            <div className="col-7 col-md-9 mobilelogo">
              <Link to={ROUTES.HOME}>
                <Logo style={{ width: '70%' }} />
              </Link>
            </div>
            <div className="col-5 col-md-3 d-flex justify-content-end p-0">
              <div className="loginBox d-flex align-items-center ">
                {isLoggedIn && (
                  <>
                    {isSupplier ? (
                      <Link to={`${ROUTES.LIVE_FEED}`}>
                        <div className="cart d-flex">
                          <LiveFeedIcon height={24} width={24} />
                          <span className="badge bg-secondary text-white icon-badge">
                            {liveFeedCount}
                          </span>
                        </div>
                        <div className="mx-2"></div>
                      </Link>
                    ) : (
                      <>
                        <div
                          onClick={navigateTo}
                          className="me-2 p-1 d-flex align-items-center"
                          style={{ borderRadius: 16, border: '1px solid' }}>
                          <img className="m-0" src={require('../../assets/images/star.png')} />
                          <span className="font-size-11 ms-1">
                            <b>{totalPoints}</b>
                          </span>
                        </div>
                        <Link to={`${ROUTES.CART}`} className="text-black me-3">
                          <div className="cart d-flex">
                            <CartIcon width={20} height={20} />
                            <span className="badge bg-secondary text-white icon-badge">
                              {cartItemCount}
                            </span>
                          </div>
                        </Link>
                      </>
                    )}
                  </>
                )}
                <div style={{ position: 'relative' }} className="d-flex me-3">
                  <NotifcationIcon width={20} height={20} onClick={onOpenNotification} />
                  <span className="badge bg-secondary text-white icon-badge">
                    {notificationCount}
                  </span>
                </div>
                {toggleSideNav && <MenuIcon width={20} height={20} onClick={toggleSideNav} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal fullscreen show={notificationOpen} onHide={onCloseNotification}>
        <div className="p-2 ps-4">
          <IconButton onClick={onCloseNotification}>
            <i className="fa fa-arrow-left" />
            <span className="ps-2">Back</span>
          </IconButton>
        </div>
        <Notifications onCloseNotification={onCloseNotification} />
      </Modal>
    </div>
  );
};

export default HeaderMobileView;
