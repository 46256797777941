import { memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const ResendOtpButton = ({ start = 15, handleResendOtp }) => {
  const [cnt, setCnt] = useState(start);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (cnt !== 0) setCnt(s => s - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [cnt]);
  const onResend = useCallback(e => {
    handleResendOtp(e);
    setCnt(start);
  }, []);
  return cnt ? (
    <span>Resend otp in...{cnt}</span>
  ) : (
    <Button onClick={onResend} variant="flat" size="sm" color="primary">
      RESEND OTP
    </Button>
  );
};
export default memo(ResendOtpButton);
