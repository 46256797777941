import './DashboardLayout.scss';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import SideBar from 'components/SideBar/SideBar';
import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { provinceSelector } from 'store/app/selectors';
import useBreakpoint from 'services/hooks/useBreakpoint';

interface DashboardLayoutInterface {
  children: ReactNode;
  fixedHeader?: boolean;
  onMobileSearchClick?: any;
  toggleHeaderOnScrolling?: boolean;
}
const DashboardLayout = ({
  children,
  fixedHeader,
  onMobileSearchClick,
  toggleHeaderOnScrolling = true,
}: DashboardLayoutInterface) => {
  const { xs } = useBreakpoint();
  const [open, setOpen] = useState(false);
  const toggleSideNav = () => setOpen(!open);

  return (
    <div className="dashboard-layout-root">
      <Header
        fixedHeader={fixedHeader}
        onMobileSearchClick={onMobileSearchClick}
        toggleHeaderOnScrolling={toggleHeaderOnScrolling}
        toggleSideNav={toggleSideNav}
        noContainer={true}
      />
      <div className="row dashboard-main-content">
        {xs ? (
          <SideBar open={open} sm={xs} setOpen={setOpen} />
        ) : (
          <div className="col-lg-2 p-0">
            <SideBar open={open} sm={xs} setOpen={setOpen} />
          </div>
        )}
        <div className="col-lg-10 col-sm-12  col-md-12">{children}</div>
      </div>

      <Footer />
    </div>
  );
};

export default DashboardLayout;
