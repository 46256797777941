import { useCallback, useEffect, useState } from 'react';

const horizontalKeys = ['ArrowLeft', 'ArrowRight'];
const verticalKeys = ['ArrowUp', 'ArrowDown'];
const arrowKeys = [...horizontalKeys, ...verticalKeys];

interface ArrowKeyNavigationParams<T> {
	direction?: 'horizontal' | 'vertical' | 'both';
	element?: HTMLElement;
	event?: 'keydown' | 'keyup' | 'keypress';
	initialSelected?: T;
	isFinite?: boolean;
	items: T[];
}

interface NavigationState<T> {
	current: T | null;
	index: number;
	key: string | null;
}

const useArrowKeyNavigation = <T>({
	direction = 'vertical',
	element,
	event = 'keydown',
	initialSelected,
	isFinite,
	items,
}: ArrowKeyNavigationParams<T>) => {
	const [state, setState] = useState<NavigationState<T>>(() => {
		const index = initialSelected ? items.indexOf(initialSelected) : -1;

		return {
			current: initialSelected || null,
			index,
			key: null,
		};
	});

	const handleArrowKey = useCallback(
		({ key }: KeyboardEvent) => {
			if (!arrowKeys.includes(key)) return;

			const shouldHandleEvent =
				direction === 'both' ||
				(direction === 'horizontal' && horizontalKeys.includes(key)) ||
				(direction === 'vertical' && verticalKeys.includes(key));

			if (shouldHandleEvent) {
				const isNext = ['ArrowRight', 'ArrowDown'].includes(key);

				setState(prevItem => {
					let targetItems = [];
					const nextItems = items.slice(prevItem.index + 1);
					const prevItems = items.slice(0, prevItem.index);

					if (isFinite) {
						targetItems = isNext ? nextItems : prevItems;
					} else {
						targetItems = isNext
							? [...nextItems, ...prevItems]
							: [...prevItems.reverse(), ...nextItems.reverse()];
					}

					return {
						current: targetItems[0],
						index: items.indexOf(targetItems[0]),
						key,
					};
				});
			}
		},
		[direction, isFinite, items],
	);

	useEffect(() => {
		const targetElement = element || document;
		targetElement.addEventListener(event, handleArrowKey as EventListener);

		return () => targetElement.removeEventListener(event, handleArrowKey as EventListener);
	}, [element, event, handleArrowKey]);

	return state;
};

export default useArrowKeyNavigation;
