import {
  SEARCH_ORDER,
  SEARCH_ORDER_FAILED,
  SEARCH_ORDER_SUCCESS,
  GET_ORDER,
  GET_ORDER_FAILED,
  GET_ORDER_SUCCESS,
  SAVE_ORDER,
  SAVE_ORDER_FAILED,
  SAVE_ORDER_SUCCESS,
  ORDER_ACTION,
  ORDER_ACTION_SUCCESS,
  ORDER_ACTION_FAILED,
} from './actions';
import { IOrderReducer } from './interfaces';

const initialState: IOrderReducer = {
  order: {
    data: null,
    error: null,
    loading: false,
  },
  orders: {
    data: [],
    error: null,
    loading: false,
  },
  orderAction: {
    loading: false,
    id: null,
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SEARCH_ORDER:
      return {
        ...state,
        orders: {
          ...state?.order,
          loading: true,
        },
      };
    case SEARCH_ORDER_SUCCESS:
      return {
        ...state,
        orders: {
          ...state?.order,
          data: action.payload,
          loading: false,
        },
      };
    case SEARCH_ORDER_FAILED:
      return {
        ...state,
        orders: {
          ...state?.order,
          error: action.payload,
          loading: false,
        },
      };

    case GET_ORDER:
      return {
        ...state,
        order: {
          ...state?.order,
          loading: true,
        },
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        order: {
          ...state?.order,
          data: action.payload,
          loading: false,
        },
      };
    case GET_ORDER_FAILED:
      return {
        ...state,
        order: {
          ...state?.order,
          error: action.payload,
          loading: false,
        },
      };

    case SAVE_ORDER:
      return {
        ...state,
        orders: {
          ...state?.orders,
          loading: true,
        },
      };
    case SAVE_ORDER_SUCCESS:
      return {
        ...state,
        orders: {
          ...state?.orders,
          loading: false,
        },
      };
    case SAVE_ORDER_FAILED:
      return {
        ...state,
        orders: {
          ...state?.orders,
          loading: false,
        },
      };
    case ORDER_ACTION:
      return {
        ...state,
        orderAction: {
          ...state?.orderAction,
          id: action.payload.id,
          loading: true,
        },
      };
    case ORDER_ACTION_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            order_item: state?.order?.data?.order_item?.map(v =>
              v?.id === parseInt(action?.payload?.id)
                ? { ...v, orderStatus: action?.payload?.orderStatus }
                : v,
            ),
          },
        },
        orders: {
          ...state.orders,
          data: {
            ...state.orders?.data,
            data: state?.orders?.data?.data?.map(v =>
              v?.order_item?.find(vv => vv?.id === parseInt(action.payload.id))
                ? {
                    ...v,
                    order_item: v?.order_item?.map(vvv =>
                      vvv.id === parseInt(action.payload.id)
                        ? { ...vvv, orderStatus: action?.payload?.orderStatus }
                        : vvv,
                    ),
                  }
                : v,
            ),
          },
        },
        orderAction: {
          ...state?.orderAction,
          id: null,
          loading: false,
        },
      };
    case ORDER_ACTION_FAILED:
      return {
        ...state,
        orderAction: {
          ...state?.orderAction,
          id: null,
          loading: false,
        },
      };

    default:
      return state;
  }
};
