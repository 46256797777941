export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

export const getRoles = () => ({
  type: GET_ROLES,
  payload: {},
});

export const getRolesSuccess = roles => ({
  type: GET_ROLES_SUCCESS,
  payload: roles,
});

export const getRolesFailed = errorMessage => ({
  type: GET_ROLES_FAILED,
  payload: errorMessage,
});
