import { FC, ReactNode } from 'react';
import { Store } from 'react-notifications-component';

export interface NotificationProps {
  title: string | FC | ReactNode;
  message: string | FC | ReactNode;
  type: 'success' | 'danger' | 'info' | 'default' | 'warning';
}

export const addNotification = (args: NotificationProps) =>
  Store.addNotification({
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      pauseOnHover: true,
    },
    ...args,
  });
