import { Response, Search, SparePartsSubCategory } from './interfaces';

export const FETCH_SPARE_PARTS = 'FETCH_SPARE_PARTS';
export const FETCH_SPARE_PARTS_SUCCESS = 'FETCH_SPARE_PARTS_SUCCESS';
export const FETCH_SPARE_PARTS_FAILURE = 'FETCH_SPARE_PARTS_FAILURE';

export const FETCH_VEHICLE_MAKE = 'FETCH_VEHICLE_MAKE';
export const FETCH_VEHICLE_MAKE_SUCCESS = 'FETCH_VEHICLE_MAKE_SUCCESS';
export const FETCH_VEHICLE_MAKE_FAILURE = 'FETCH_VEHICLE_MAKE_FAILURE';

export const FETCH_VEHICLE_MODEL = 'FETCH_VEHICLE_MODEL';
export const FETCH_VEHICLE_MODEL_SUCCESS = 'FETCH_VEHICLE_MODEL_SUCCESS';
export const FETCH_VEHICLE_MODEL_FAILURE = 'FETCH_VEHICLE_MODEL_FAILURE';

export const FETCH_SPARE_PARTS_CATEGORY = 'FETCH_SPARE_PARTS_CATEGORY';
export const FETCH_SPARE_PARTS_CATEGORY_SUCCESS = 'FETCH_SPARE_PARTS_CATEGORY_SUCCESS';
export const FETCH_SPARE_PARTS_CATEGORY_FAILURE = 'FETCH_SPARE_PARTS_CATEGORY_FAILURE';

export const FETCH_SPARE_PARTS_SUB_CATEGORY = 'FETCH_SPARE_PARTS_SUB_CATEGORY';
export const FETCH_SPARE_PARTS_SUB_CATEGORY_SUCCESS = 'FETCH_SPARE_PARTS_SUB_CATEGORY_SUCCESS';
export const FETCH_SPARE_PARTS_SUB_CATEGORY_FAILURE = 'FETCH_SPARE_PARTS_SUB_CATEGORY_FAILURE';

export const FETCH_CSRF_TOKEN = 'FETCH_CSRF_TOKEN';
export const FETCH_CSRF_TOKEN_SUCCESS = 'FETCH_CSRF_TOKEN_SUCCESS';
export const FETCH_CSRF_TOKEN_FAILURE = 'FETCH_CSRF_TOKEN_FAILURE';

export const fetchCsrfToken = () => ({
  type: FETCH_CSRF_TOKEN,
});

export const fetchSparePart = (payload: Search) => ({
  type: FETCH_SPARE_PARTS,
  payload,
});

export const fetchSparePartSuccess = (payload: any) => ({
  type: FETCH_SPARE_PARTS_SUCCESS,
  payload,
});

export const fetchSparePartFailure = (payload: any) => ({
  type: FETCH_SPARE_PARTS_FAILURE,
  payload,
});

export const fetchVehicleMake = (payload: Search) => ({
  type: FETCH_VEHICLE_MAKE,
  payload,
});

export const fetchVehicleMakeSuccess = (payload: any) => ({
  type: FETCH_VEHICLE_MAKE_SUCCESS,
  payload,
});
export const fetchVehicleMakeFailure = (payload: any) => ({
  type: FETCH_VEHICLE_MAKE_FAILURE,
  payload,
});

export const fetchVehicleModel = (payload: any) => ({
  type: FETCH_VEHICLE_MODEL,
  payload,
});

export const fetchVehicleModelSuccess = (payload: any) => ({
  type: FETCH_VEHICLE_MODEL_SUCCESS,
  payload,
});
export const fetchVehicleModelFailure = (payload: any) => ({
  type: FETCH_VEHICLE_MODEL_FAILURE,
  payload,
});

export const fetchSparePartsCategory = (payload: any) => ({
  type: FETCH_SPARE_PARTS_CATEGORY,
  payload,
});

export const fetchSparePartsCategorySuccess = (payload: any) => ({
  type: FETCH_SPARE_PARTS_CATEGORY_SUCCESS,
  payload,
});

export const fetchSparePartsCategoryFailure = (payload: any) => ({
  type: FETCH_SPARE_PARTS_CATEGORY_FAILURE,
  payload,
});

export const fetchSparePartsSubCategory = ({
  search = '',
  categoryId = undefined,
}: SparePartsSubCategory) => ({
  type: FETCH_SPARE_PARTS_SUB_CATEGORY,
  payload: { search, categoryId },
});

export const fetchSparePartsSubCategorySuccess = (payload: any) => ({
  type: FETCH_SPARE_PARTS_SUB_CATEGORY_SUCCESS,
  payload,
});

export const fetchSparePartsSubCategoryFailure = (payload: any) => ({
  type: FETCH_SPARE_PARTS_SUB_CATEGORY_FAILURE,
  payload,
});
