import { getUserSession } from 'services/auth';
import {
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD_SUCCESS,
  CONFIRM_FORGOT_PASSWORD_FAILURE,
  CLEAR_FORGOT_PASSWORD_EMAIL,
  FETCH_USER_SETTINGS_SUCCESS,
  GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILED,
  LOGIN_USER,
  LOGIN_USER_WITH_PHONE_SUCCESS,
  LOGIN_USER_WITH_EMAIL_SUCCESS,
  LOGIN_USER_FAILED,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  LOGOUT_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  CONFIRM_OTP,
  CONFIRM_OTP_SUCCESS,
  CONFIRM_OTP_FAILED,
  USER_UPDATE,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILED,
  GET_USER_ADDRESS_SUCCESS,
  GET_USER_ADDRESS_FAILED,
  GET_USER_ADDRESS,
  SAVE_USER_ADDRESS,
  SAVE_USER_ADDRESS_SUCCESS,
  SAVE_USER_ADDRESS_FAILED,
  DELETE_USER_ADDRESS,
  DELETE_USER_ADDRESS_SUCCESS,
  DELETE_USER_ADDRESS_FAILED,
  RESET_ERROR,
  EDIT_USER_ADDRESS,
  EDIT_USER_ADDRESS_SUCCESS,
  EDIT_USER_ADDRESS_FAILED,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
  LOGIN_WITH_OTP,
  LOGIN_WITH_OTP_SUCCESS,
  LOGIN_WITH_OTP_FAILED,
} from 'store/user/actions';

const initialState = {
  user: null,
  loading: false,
  error: null,
  otpData: null,
  forgotPasswordStatus: null,
  resetPasswordStatus: null,
  activateUserStatus: null,
  userAddress: {
    data: null,
    loading: false,
    error: null,
  },
  businesses: {
    data: null,
    error: null,
    loading: false,
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: action.payload,
      };

    case CONFIRM_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: null,
      };

    case CONFIRM_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        forgotPassword: null,
      };
    case CLEAR_FORGOT_PASSWORD_EMAIL:
      return {
        ...state,
        forgotPassword: null,
      };
    case GOOGLE_LOGIN:
      return { ...state, error: null, loading: true };
    case GOOGLE_LOGIN_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case GOOGLE_LOGIN_FAILED:
      return { ...state, error: action.payload, loading: false };

    case LOGIN_USER:
      return { ...state, error: null, loading: true };
    case LOGIN_USER_WITH_PHONE_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case LOGIN_USER_WITH_EMAIL_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null };
    case LOGIN_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case REGISTER_USER:
      return { ...state, error: null, loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, otpData: action.payload, loading: false, error: null };
    case REGISTER_USER_FAILED:
      return { ...state, error: action.payload, loading: false };

    case LOGOUT_USER:
      return { ...state, loading: true, user: null, error: null };
    case LOGOUT_USER_SUCCESS:
      return { ...state, loading: false, user: null };
    case LOGOUT_USER_FAILED:
      return { ...state, loading: false, user: null };

    case RESET_PASSWORD:
      return { ...state, error: null, loading: true };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetStatus: action.payload,
        loading: false,
        error: null,
      };
    case RESET_PASSWORD_FAILED:
      return { ...state, error: action.payload, loading: false };

    case CONFIRM_OTP:
      return { ...state, error: null, loading: true };
    case CONFIRM_OTP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        otpData: null,
        loading: false,
        error: null,
      };
    case CONFIRM_OTP_FAILED:
      return { ...state, error: action.payload, loading: false };

    case LOGIN_WITH_OTP:
      return { ...state, error: null, loading: true };
    case LOGIN_WITH_OTP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        otpData: null,
        loading: false,
        error: null,
      };
    case LOGIN_WITH_OTP_FAILED:
      return { ...state, error: action.payload, loading: false };

    case USER_UPDATE:
      return {
        ...state,
        user: action.payload,
      };

    case GET_USER_ADDRESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          loading: true,
        },
      };
    case GET_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_USER_ADDRESS_FAILED:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          error: action.payload,
          loading: false,
        },
      };

    case SAVE_USER_ADDRESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          loading: true,
        },
      };
    case SAVE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          data: [action.payload, ...state?.userAddress?.data],
          error: null,
          loading: false,
        },
      };
    case SAVE_USER_ADDRESS_FAILED:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          error: action.payload,
          loading: false,
        },
      };

    case EDIT_USER_ADDRESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          loading: true,
        },
      };
    case EDIT_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          data: action?.payload?.id
            ? state?.userAddress?.data?.map(v =>
                v?.id === action?.payload?.id ? action?.payload : v,
              )
            : state?.userAddress?.data,
          error: null,
          loading: false,
        },
      };
    case EDIT_USER_ADDRESS_FAILED:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          error: action.payload,
          loading: false,
        },
      };

    case DELETE_USER_ADDRESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          loading: true,
        },
      };
    case DELETE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          data: state?.userAddress?.data?.filter(v => v?.id !== action?.payload?.id),
          error: null,
          loading: false,
        },
      };
    case DELETE_USER_ADDRESS_FAILED:
      return {
        ...state,
        userAddress: {
          ...state?.userAddress,
          error: action.payload,
          loading: false,
        },
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state?.user,
          loading: true,
        },
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state?.user,
          ...action?.payload,
        },
        error: null,
        loading: false,
      };
    case UPDATE_PROFILE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case RESET_ERROR: {
      return { ...state, error: null };
    }
    default:
      return state;
  }
};
