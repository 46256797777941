import { IPointHistoryReducer } from './interfaces';
import {
  GET_POINT_HISTORY,
  GET_POINT_HISTORY_FAILED,
  GET_POINT_HISTORY_SUCCESS,
  GET_TOTAL_POINT,
  GET_TOTAL_POINT_FAILED,
  GET_TOTAL_POINT_SUCCESS,
  REDEEM_POINTS,
  REDEEM_POINTS_FAILED,
  REDEEM_POINTS_SUCCESS,
} from './actions';

const initialState: IPointHistoryReducer = {
  pointHistory: {
    data: {
      current_page: 0,
      data: [],
      first_page_url: '',
      from: 0,
      next_page_url: '',
      path: null,
      per_page: 0,
      prev_page_url: null,
      to: null,
    },
    loading: false,
    error: null,
  },
  totalPoints: {
    data: 0,
    loading: false,
    error: null,
  },
  redeemPointState: {
    data: null,
    loading: false,
    error: null,
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_POINT_HISTORY:
      return {
        ...state,
        pointHistory: {
          ...state.pointHistory,
          loading: true,
        },
      };
    case GET_POINT_HISTORY_SUCCESS:
      return {
        ...state,
        pointHistory: {
          ...state.pointHistory,
          loading: false,
          data: action.payload,
        },
      };
    case GET_POINT_HISTORY_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_TOTAL_POINT:
      return {
        ...state,
        totalPoints: {
          ...state.totalPoints,
          loading: true,
        },
      };
    case GET_TOTAL_POINT_SUCCESS:
      return {
        ...state,
        totalPoints: {
          ...state.totalPoints,
          loading: false,
          data: action?.payload,
        },
      };
    case GET_TOTAL_POINT_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case REDEEM_POINTS:
      return {
        ...state,
        redeemPointState: {
          ...state.redeemPointState,
          loading: true,
        },
      };
    case REDEEM_POINTS_SUCCESS:
      return {
        ...state,
        totalPoints: {
          ...state.totalPoints,
          data: state?.totalPoints?.data + action?.payload?.points,
        },
        pointHistory: {
          ...state.pointHistory,
          data: {
            ...state.pointHistory.data,
            data: [action.payload, ...state.pointHistory.data?.data],
          },
        },
        redeemPointState: {
          ...state.redeemPointState,
          loading: false,
        },
      };
    case REDEEM_POINTS_FAILED:
      return {
        ...state,
        redeemPointState: {
          ...state.redeemPointState,
          loading: false,
        },
      };

    default:
      return state;
  }
};
