import { ChatMessagePayload } from './interfaces';

export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_FAILED = 'GET_CHAT_FAILED';

export const GET_CHAT_ROOM = 'GET_CHAT_ROOM';
export const GET_CHAT_ROOM_SUCCESS = 'GET_CHAT_ROOM_SUCCESS';
export const GET_CHAT_ROOM_FAILED = 'GET_CHAT_FAILED';

export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';
export const ADD_CHAT_MESSAGE_SUCCESS = 'ADD_CHAT_MESSAGE_SUCCESS';
export const ADD_CHAT_MESSAGE_FAILED = 'ADD_CHAT_MESSAGE_FAILED';

export const getChatRoom = (payload?: any) => ({
  type: GET_CHAT_ROOM,
  payload,
});

export const getChatRoomSuccess = (payload: any) => ({
  type: GET_CHAT_ROOM_SUCCESS,
  payload,
});

export const getChatRoomFailed = (payload: any) => ({
  type: GET_CHAT_ROOM_FAILED,
  payload,
});

export const getChatMessages = ({ chat_room_id }: { chat_room_id: number }) => ({
  type: GET_CHAT_MESSAGES,
  payload: { chat_room_id },
});

export const getChatMessagesSuccess = (payload: any) => ({
  type: GET_CHAT_MESSAGES_SUCCESS,
  payload,
});

export const getChatMessagesFailed = (payload: any) => ({
  type: GET_CHAT_MESSAGES_FAILED,
  payload,
});

export const addChatMessage = (payload: any) => ({
  type: ADD_CHAT_MESSAGE,
  payload,
});

export const addChatMessageSuccess = (payload: any) => ({
  type: ADD_CHAT_MESSAGE_SUCCESS,
  payload,
});

export const addChatMessageFailed = (payload: any) => ({
  type: ADD_CHAT_MESSAGE_FAILED,
  payload,
});
