import { Redirect, Route } from 'react-router-dom';

import ProtectedLayout from 'components/ProtectedLayout/ProtectedLayout';
import { ROUTES } from 'routes/constants';
import { getUserSession } from 'services/auth';

interface Props {
  children: JSX.Element;
  path: string;
  exact?: boolean;
}

const ProtectedRoute = ({ children, path, exact = false }: Props) => {
  const user: any = getUserSession();

  if (user?.token) {
    return <Route path={path}>{children}</Route>;
  }

  return (
    <Redirect
      to={{
        pathname: ROUTES.LOGIN,
        state: { path },
      }}
    />
  );
};

export default ProtectedRoute;
