import { find as _find } from 'lodash';
import { createSelector } from 'reselect';

import { RootReducer } from '../rootReducer';

export const pointsHistorySelector = createSelector(
  (state: RootReducer) => state.pointHistory,
  ({ pointHistory }) => ({ ...pointHistory }),
);

export const totalPointSelector = createSelector(
  (state: RootReducer) => state.pointHistory,
  ({ totalPoints }) => ({ ...totalPoints }),
);

export const redeemPointStateSelector = createSelector(
  (state: RootReducer) => state.pointHistory,
  ({ redeemPointState }) => ({ ...redeemPointState }),
);
