import { joiResolver } from '@hookform/resolvers/joi';
import { Error } from 'components/Input/Input.styled';
import Joi from 'joi';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import formValidationRules from 'services/formValidationRules';
import { userOtpSelector } from 'store/user/selectors';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { useEffect, useState } from 'react';
import { confirmOtp, loginWithOtp, registerUserSuccess } from 'store/user/actions';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import ResendOtpButton from 'components/ResendOtpButton/ResendOtpButton';
import { sendForgotPinOtp } from 'services/api';
import { appNotification } from 'store/app/actions';
import { RootReducer } from 'store/rootReducer';
import useQuery from 'hooks/useQuery';
import SearchDropdown from 'components/SearchDropdown/SearchDropdown';
import { countryCodeSelector } from 'store/app/selectors';
import ReactCountryFlag from 'react-country-flag';

const schema = Joi.object({
  otp: formValidationRules.otp,
  phoneCode: Joi.number().required(),
  phoneNumber: Joi.number().required(),
});

const OtpLogin = () => {
  const { countryCode: countryCodeOption, loading: countryCodeLoader } =
    useSelector(countryCodeSelector);
  const [otpData, setOtpData] = useState(null);
  const { loading } = useSelector((s: RootReducer) => s.user);
  const [countryCode, setCountryCode] = useState('AE');
  const params = useQuery();
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    resolver: joiResolver(schema),
    defaultValues: {
      phoneCode: params.get('phoneCode') || 971,
      phoneNumber: params.get('phoneNumber') || '',
      otp: null,
    },
  });
  const onSubmit = data => {
    console.log('data', { ...otpData, ...data });
    dispatch(
      loginWithOtp({
        payload: { ...otpData, ...data },
      }),
    );
  };
  const handlePhoneCodeSelect = option => {
    setCountryCode(option.countryCode);
  };
  const sendOtp = async (phoneNumber, phoneCode) => {
    const response = await sendForgotPinOtp({ phoneNumber, phoneCode });
    if (response?.status === 'Success') {
      setOtpData(response?.data);
      dispatch(appNotification({ type: 'success', message: 'Otp has been sent to your number' }));
    } else
      dispatch(
        appNotification({ type: 'danger', message: 'Oops something went wrong while sending otp' }),
      );
  };
  useEffect(() => {
    // sendOtp(params.get('phoneNumber'), params.get('phoneCode'));
    if (countryCodeOption)
      setValue(
        'phoneCode',
        countryCodeOption.find(v => v?.phoneCode === params.get('phoneCode')),
      );
    setValue('phoneNumber', params.get('phoneNumber'));
  }, [countryCodeOption, params.get('phoneNumber'), params.get('phoneCode')]);
  return (
    <AuthLayout
      leftContent={null}
      leftImage={require('../../assets/images/login.png')}
      renderForm={
        <>
          <p className="text-secondary">
            Verify your phone number by Entering your 6 digit one time password that is sent to your
            {params?.get('phoneNumber')}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-4 col-lg-4 pr-0">
                <div className="form-group mb-2">
                  <Controller
                    name="phoneCode"
                    control={control}
                    render={({
                      field: { onBlur, onChange, value, name, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <SearchDropdown
                          noIcon={true}
                          placeholder="Code"
                          containerClass="countrycode-dd"
                          inputProps={{
                            style: { padding: '20px 14px' },
                            autoComplete: 'new-password',
                            form: 'off',
                            disabled: !!params.get('phoneCode'),
                          }}
                          name={name}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          options={countryCodeOption ? countryCodeOption : []}
                          optionLabel="phone"
                          optionValue="phone"
                          afterSelect={handlePhoneCodeSelect}
                          renderOption={option => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ReactCountryFlag countryCode={option?.countryCode} svg />
                              <span className="ms-1">{option?.phone}</span>
                            </div>
                          )}
                        />
                        {!error?.message && <Error>{error?.message}</Error>}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-8 col-lg-8">
                <div className="form-group mb-2">
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({
                      field: { name, onBlur, onChange, ref, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <input
                          type="text"
                          className={`form-control ${error && 'input-field-error'}`}
                          id="exampleInput3"
                          disabled={!!params.get('phoneNumber')}
                          name={name}
                          onBlur={onBlur}
                          onChange={onChange}
                          ref={ref}
                          value={value || ''}
                          form="off"
                          autoComplete="new-password"
                          aria-autocomplete="none"
                          placeholder="Enter phone number"
                        />
                        <Error>{error?.message}</Error>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="verify-otp" className="input-label mb-2">
                Enter 6 Digit Otp
              </label>

              <Controller
                name="otp"
                control={control}
                render={({
                  field: { onBlur, onChange, name, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="password"
                      className={`form-control ${error && 'input-field-error'}`}
                      id="verify-otp"
                      placeholder="- - - - - -"
                      value={value}
                      name={name}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                    <Error>{error?.message}</Error>
                  </>
                )}
              />
            </div>
            <ResendOtpButton handleResendOtp={sendOtp} />
            <div>
              <button type="submit" disabled={loading} className="btn btn-block  mt-3 btn-primary">
                {loading ? 'Verifying...' : 'Verify'}
              </button>
            </div>
          </form>
        </>
      }
    />
  );
  // return (
  //   <div className="business-register-page business-verify-otp-root">
  //     <div className="container">
  //       <div className="row">
  //         <div className="col-xl-8 col-lg-6">
  //           <div className="registerinfo text-center">
  //             <div className="registertittle text-left">
  //               <img src={require('../../assets/images/logo.png')} className="img-fluid mb-5" />

  //               <p>
  //                 If you already have an <br /> account you can{' '}
  //                 <Link to={ROUTES.LOGIN}>Log in</Link> here!
  //               </p>
  //             </div>
  //             <img
  //               src={require('../../assets/images/login.png')}
  //               className="img-fluid d-none d-lg-block"
  //             />
  //           </div>
  //         </div>

  //         <div className="col-xl-4 col-lg-6">
  //           <p className="text-secondary">
  //             Verify your phone number by Entering your 6 digit one time password that is sent to
  //             your phone number
  //           </p>
  //           <div className="signupform">
  //             <form onSubmit={handleSubmit(onSubmit)}>
  //               <div className="form-group mb-3">
  //                 <label htmlFor="verify-otp" className="input-label">
  //                   Enter 6 Digit Otp
  //                 </label>
  //                 <Controller
  //                   name="otp"
  //                   control={control}
  //                   render={({
  //                     field: { onBlur, onChange, name, value, ref },
  //                     fieldState: { error },
  //                   }) => (
  //                     <>
  //                       <input
  //                         type="number"
  //                         className={`form-control ${error && 'input-field-error'}`}
  //                         id="verify-otp"
  //                         placeholder="- - - - - -"
  //                         value={value}
  //                         name={name}
  //                         ref={ref}
  //                         onChange={onChange}
  //                         onBlur={onBlur}
  //                       />
  //                       <Error>{error?.message}</Error>
  //                     </>
  //                   )}
  //                 />
  //               </div>
  //               <div>
  //                 <button type="submit" className="btn btn-block  mt-3 btn-primary">
  //                   Verify
  //                 </button>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default OtpLogin;
