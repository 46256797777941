import { createSelector } from 'reselect';

import { RootReducer } from '../rootReducer';

export const chatRoomSelector = createSelector(
  (state: RootReducer) => state.chat,
  ({ chatRoom }) => ({ ...chatRoom }),
);

export const chatMessagesSelector = createSelector(
  (state: RootReducer) => state.chat,
  ({ chatMessages }) => ({ ...chatMessages }),
);
