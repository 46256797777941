import Joi, { Root } from 'joi';

const instance = Joi.defaults(schema =>
	schema.options({
		errors: { wrap: { label: ' ' } },
	}),
);

export type { Root };
export default instance;
