export const SEARCH_SUPPLIER_BUSINESS = 'SEARCH_SUPPLIER_BUSINESS';
export const SEARCH_SUPPLIER_BUSINESS_SUCCESS = 'SEARCH_SUPPLIER_BUSINESS_SUCCESS';
export const SEARCH_SUPPLIER_BUSINESS_FAILED = 'SEARCH_SUPPLIER_BUSINESS_FAILED';

export const SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE = 'SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE';
export const SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE_SUCCESS =
  'SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE_SUCCESS';
export const SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE_FAILED = 'SEARCH_SUPPLIER_BUSINESS_FAILED';

export const RESET_SEARCH_SUPPLIER_BUSINESS = 'RESET_SEARCH_SUPPLIER_BUSINESS';

export const SUPPLIER_BUSINESS_DETAIL = 'SUPPLIER_BUSINESS_DETAIL';
export const SUPPLIER_BUSINESS_DETAIL_SUCCESS = 'SUPPLIER_BUSINESS_DETAIL_SUCCESS';
export const SUPPLIER_BUSINESS_DETAIL_FAILED = 'SUPPLIER_BUSINESS_DETAIL_FAILED';
export const SELECT_SUPPLIER_BUSINESS = 'SELECT_SUPPLIER_BUSINESS';
export const SET_RFQ_SELECTION_PREFERENCE = 'SET_RFQ_SELECTION_PREFERENCE';

export const RESET_SELECTED_SUPPLIER_BUSINESS = 'RESET_SELECTED_SUPPLIER_BUSINESS';

export const searchSupplierBusiness = ({ payload }: { payload: any }) => ({
  type: SEARCH_SUPPLIER_BUSINESS,
  payload,
});

export const searchSupplierBusinessSuccess = (data: any) => ({
  type: SEARCH_SUPPLIER_BUSINESS_SUCCESS,
  payload: data,
});

export const searchSupplierBusinessFailed = (error: any) => ({
  type: SEARCH_SUPPLIER_BUSINESS_FAILED,
  payload: error,
});

export const resetSearchSupplierBusiness = () => ({
  type: RESET_SEARCH_SUPPLIER_BUSINESS,
});

export const searchSupplierBusinessNextPage = ({ payload }: { payload: any }) => ({
  type: SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE,
  payload,
});

export const searchSupplierBusinessNextPageSuccess = (data: any) => ({
  type: SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE_SUCCESS,
  payload: data,
});

export const searchSupplierBusinessNextPageFailed = (error: any) => ({
  type: SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE_FAILED,
  payload: error,
});

export const supplierBusinessDetail = ({ payload }) => ({
  type: SUPPLIER_BUSINESS_DETAIL,
  payload,
});

export const supplierBusinessDetailSuccess = data => ({
  type: SUPPLIER_BUSINESS_DETAIL_SUCCESS,
  payload: data,
});

export const supplierBusinessDetailFailed = error => ({
  type: SUPPLIER_BUSINESS_DETAIL_FAILED,
  payload: error,
});

export const selectSupplierBusiness = (payload: any[]) => ({
  type: SELECT_SUPPLIER_BUSINESS,
  payload,
});

export const setRfqSelectionPreference = (payload: boolean) => ({
  type: SET_RFQ_SELECTION_PREFERENCE,
  payload,
});

export const resetSelectSupplierBusiness = () => ({
  type: RESET_SELECTED_SUPPLIER_BUSINESS,
});
