import {
  FETCH_COUNTRY,
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_FAILURE,
  FETCH_PROVINCE,
  FETCH_PROVINCE_SUCCESS,
  FETCH_PROVINCE_FAILURE,
  APP_NOTIFICATION,
  FETCH_COUNTRY_CODE,
  FETCH_COUNTRY_CODE_SUCCESS,
  FETCH_COUNTRY_CODE_FAILURE,
  SAVE_CURRENT_LOCATION,
  FETCH_CITIES,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
} from './actions';
import { IAppReducerInitialState } from './interfaces';

const initialState: IAppReducerInitialState = {
  data: null,
  error: null,
  currentLocation: null,
  country: {
    data: null,
    error: null,
    loading: false,
  },
  countryCode: {
    data: null,
    error: null,
    loading: false,
  },
  alertMsg: {
    type: 'info',
    message: '',
  },
  province: {
    data: null,
    error: null,
    loading: false,
  },
  cities: {
    data: null,
    error: null,
    loading: false,
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_COUNTRY:
      return {
        ...state,
        country: {
          ...state.country,
          loading: true,
        },
      };
    case FETCH_COUNTRY_SUCCESS:
      return {
        ...state,
        country: {
          ...state.country,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_COUNTRY_FAILURE:
      return {
        ...state,
        country: {
          ...state.country,
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    case FETCH_COUNTRY_CODE:
      return {
        ...state,
        countryCode: {
          ...state.countryCode,
          loading: true,
        },
      };
    case FETCH_COUNTRY_CODE_SUCCESS:
      return {
        ...state,
        countryCode: {
          ...state.countryCode,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_COUNTRY_CODE_FAILURE:
      return {
        ...state,
        countryCode: {
          ...state.countryCode,
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    case FETCH_PROVINCE:
      return {
        ...state,
        province: {
          ...state.province,
          loading: true,
        },
      };
    case FETCH_PROVINCE_SUCCESS:
      return {
        ...state,
        province: {
          ...state.province,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_PROVINCE_FAILURE:
      return {
        ...state,
        province: {
          ...state.province,
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    case FETCH_CITIES:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: true,
        },
      };
    case FETCH_CITIES_SUCCESS:
      return {
        ...state,
        cities: {
          ...state.cities,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_CITIES_FAILURE:
      return {
        ...state,
        cities: {
          ...state.cities,
          data: null,
          error: action.payload,
          loading: false,
        },
      };

    case APP_NOTIFICATION:
      return { ...state, alertMsg: { ...action.payload } };
    case SAVE_CURRENT_LOCATION:
      return { ...state, currentLocation: action.payload.currentLocation };

    default:
      return state;
  }
};
