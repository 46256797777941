import {
  FETCH_SPARE_PARTS_CATEGORY,
  FETCH_SPARE_PARTS_CATEGORY_FAILURE,
  FETCH_SPARE_PARTS_CATEGORY_SUCCESS,
  FETCH_SPARE_PARTS,
  FETCH_SPARE_PARTS_FAILURE,
  FETCH_SPARE_PARTS_SUCCESS,
  FETCH_VEHICLE_MAKE,
  FETCH_VEHICLE_MAKE_FAILURE,
  FETCH_VEHICLE_MAKE_SUCCESS,
  FETCH_SPARE_PARTS_SUB_CATEGORY,
  FETCH_SPARE_PARTS_SUB_CATEGORY_SUCCESS,
  FETCH_SPARE_PARTS_SUB_CATEGORY_FAILURE,
  FETCH_VEHICLE_MODEL,
  FETCH_VEHICLE_MODEL_SUCCESS,
  FETCH_VEHICLE_MODEL_FAILURE,
} from './actions';
import { IHomeReducerInitialState } from './interfaces';

const initialState: IHomeReducerInitialState = {
  data: null,
  error: null,
  spareParts: {
    data: null,
    error: null,
    loading: false,
  },
  vehicleMakes: {
    data: null,
    error: null,
    loading: false,
  },
  vehicleModel: {
    data: null,
    error: null,
    loading: false,
  },
  sparePartsCategories: {
    data: null,
    error: null,
    loading: false,
  },
  sparePartsSubCateogories: {
    data: null,
    error: null,
    loading: false,
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_SPARE_PARTS:
      return {
        ...state,
        spareParts: {
          ...state.spareParts,
          loading: true,
        },
      };
    case FETCH_SPARE_PARTS_SUCCESS:
      return {
        ...state,
        spareParts: {
          ...state.spareParts,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_SPARE_PARTS_FAILURE:
      return {
        ...state,
        spareParts: {
          ...state.spareParts,
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    case FETCH_VEHICLE_MAKE:
      return {
        ...state,
        vehicleMakes: {
          ...state.vehicleMakes,
          loading: true,
        },
      };
    case FETCH_VEHICLE_MAKE_SUCCESS:
      return {
        ...state,
        vehicleMakes: {
          ...state.vehicleMakes,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_VEHICLE_MAKE_FAILURE:
      return {
        ...state,
        vehicleMakes: {
          ...state.vehicleMakes,
          data: null,
          error: action.payload,
          loading: false,
        },
      };

    case FETCH_VEHICLE_MODEL:
      return {
        ...state,
        vehicleModel: {
          ...state.vehicleModel,
          loading: true,
        },
      };
    case FETCH_VEHICLE_MODEL_SUCCESS:
      return {
        ...state,
        vehicleModel: {
          ...state.vehicleModel,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_VEHICLE_MODEL_FAILURE:
      return {
        ...state,
        vehicleModel: {
          ...state.vehicleModel,
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    case FETCH_SPARE_PARTS_CATEGORY:
      return {
        ...state,
        spareParts: {
          ...state.spareParts,
          loading: true,
        },
      };
    case FETCH_SPARE_PARTS_CATEGORY:
      return {
        ...state,
        sparePartsCategories: {
          ...state.sparePartsCategories,
          loading: true,
        },
      };
    case FETCH_SPARE_PARTS_CATEGORY_SUCCESS:
      return {
        ...state,
        sparePartsCategories: {
          ...state.sparePartsCategories,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_SPARE_PARTS_CATEGORY_FAILURE:
      return {
        ...state,
        sparePartsCategories: {
          ...state.sparePartsCategories,
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    case FETCH_SPARE_PARTS_SUB_CATEGORY:
      return {
        ...state,
        sparePartsSubCateogories: {
          ...state.sparePartsSubCateogories,
          loading: true,
        },
      };
    case FETCH_SPARE_PARTS_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        sparePartsSubCateogories: {
          ...state.sparePartsSubCateogories,
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_SPARE_PARTS_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        sparePartsSubCateogories: {
          ...state.sparePartsSubCateogories,
          data: null,
          error: action.payload,
          loading: false,
        },
      };

    default:
      return state;
  }
};
