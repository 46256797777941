import IconButton from 'components/IconButton/IconButton';
import { CSSProperties, ReactElement, ReactNode, useCallback } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
export interface ICustomModal extends ModalProps {
  title?: string;
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal?: () => void;
  openModal?: () => void;
  children: ReactNode;
  handleOnClose?: () => void;
  renderFooterButtons?: ReactElement;
  contentStyle?: CSSProperties;
  cancelButtonText?: string;
  submitButtonText?: string;
  noFooter?: boolean;
  noHeader?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
}
const CustomModal = ({
  title,
  open,
  openModal,
  setOpen,
  closeModal,
  children,
  handleOnClose,
  cancelButtonText,
  submitButtonText,
  renderFooterButtons,
  noFooter = false,
  noHeader = false,
  onSubmit,
  onCancel,
  ...props
}: ICustomModal) => {
  const handleSubmit = () => {
    closeModal();
    onSubmit();
  };
  return (
    <Modal show={open} onExit={handleOnClose || null} {...props}>
      {!noHeader && (
        <Modal.Header>
          <div className="d-flex">
            {title && <span>{title}</span>}
            <IconButton onClick={closeModal}>
              <i className="fa fa-close" />
            </IconButton>
          </div>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {!noFooter &&
        (renderFooterButtons ? (
          <Modal.Footer>{renderFooterButtons}</Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button onClick={closeModal} variant="outline-secondary">
              {cancelButtonText || 'Cancel'}
            </Button>
            <Button onClick={handleSubmit}>{submitButtonText || 'Submit'}</Button>
          </Modal.Footer>
        ))}
    </Modal>
  );
};

export default CustomModal;
