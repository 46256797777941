import {
  GET_CART,
  GET_CART_SUCCESS,
  GET_CART_FAILED,
  SAVE_CART,
  SAVE_CART_SUCCESS,
  SAVE_CART_FAILED,
  REMOVE_CART,
  REMOVE_CART_SUCCESS,
  REMOVE_CART_FAILED,
} from './actions';
import { ICartReducerInitialState } from './interfaces';

const initialState: ICartReducerInitialState = {
  data: [],
  error: null,
  loading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CART:
      return {
        ...state,
        loading: true,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case GET_CART_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SAVE_CART:
      return {
        ...state,
        loading: true,
      };
    case SAVE_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
        error: null,
      };
    case SAVE_CART_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case REMOVE_CART:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_CART_SUCCESS:
      return {
        ...state,
        data: state?.data?.filter(v => v?.id !== action?.payload?.id),
        loading: false,
      };
    case REMOVE_CART_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
