import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  MessageModel,
  MainContainer,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
} from '@chatscope/chat-ui-kit-react';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import IconButton from 'components/IconButton/IconButton';
import OutsideWrapper from 'components/OutsideWrapper/OutsideWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { getChatMessages, getChatRoom } from 'store/chat/actions';
import { chatMessagesSelector, chatRoomSelector } from 'store/chat/selectors';
import ChatBox from 'components/ChatBox/ChatBox';
import { Spinner } from 'react-bootstrap';
const AkashIco = require('../../assets/images/no-image.png');

const AttachmentOption = () => {
  return (
    <div
      className="p-2"
      style={{
        position: 'absolute',
        bottom: 75,
        background: 'white',
        borderRadius: 20,
        boxShadow: '#bebebe 2px 2px 6px',
      }}>
      <div>
        <label
          htmlFor="chat-att-image"
          style={{ borderRadius: 10, padding: 10, cursor: 'pointer' }}>
          <i className="font-size-24 fa fa-file-image-o" />
          <span className="ms-2">Image</span>
          <input
            id="chat-att-image"
            accept="image/*"
            type="file"
            name="image"
            style={{ display: 'none' }}
          />
        </label>
      </div>
      <div>
        <label
          htmlFor="chat-att-video"
          style={{ borderRadius: 10, padding: 10, cursor: 'pointer' }}>
          <i className="font-size-24 fa fa-file-video-o" />
          <span className="ms-2">Video</span>
          <input
            id="chat-att-video"
            accept="video/*"
            type="file"
            name="video"
            style={{ display: 'none' }}
          />
        </label>
        <label></label>
      </div>
      <div>
        <label htmlFor="chat-att-file" style={{ borderRadius: 10, padding: 10, cursor: 'pointer' }}>
          <i className="font-size-24 fa fa-file-o" />
          <span className="ms-2">File</span>
          <input
            id="chat-att-file"
            accept="file/*"
            type="file"
            name="file"
            style={{ display: 'none' }}
          />
        </label>
      </div>
    </div>
  );
};
const mock: MessageModel[] = [
  {
    message: 'Hello my friend',
    sentTime: '15 mins ago',
    sender: 'Motonexa',
    direction: 'outgoing',
    position: 'single',
  },
  {
    message: 'Hello my friend',
    sentTime: '15 mins ago',
    sender: 'Akash',
    direction: 'incoming',
    position: 'single',
  },
];
interface IChat {
  closeChat: (e: any) => void;
  selectedRoom?: any;
  rooms: any[];
  loading: boolean;
  currentUser: any;
}
const Chat = ({ closeChat, selectedRoom, rooms = [], loading = true, currentUser }: IChat) => {
  const [activeRoom, setActiveRoom] = useState(selectedRoom || rooms?.[0] || null);
  const handleRoomChange = room => (e: any) => {
    setActiveRoom(room);
  };
  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="grow" />
        <span className="text-secondary mt-2">Loading chat, please wait...</span>
      </div>
    );
  return (
    <>
      <IconButton onClick={closeChat}>
        <i className="fa fa-close" />
      </IconButton>
      <MainContainer responsive style={{ height: '90vh', width: '100%' }}>
        {!rooms?.length ? (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ width: '100%' }}>
            <i className="fa fa-comments-o text-secondary" style={{ fontSize: 66 }}></i>
            <h3 className="text-secondary">No chat available</h3>
          </div>
        ) : (
          <>
            {rooms?.length > 1 && (
              <Sidebar position="left" scrollable={false}>
                <Search placeholder="Search..." />
                <ConversationList>
                  {rooms?.map(v => (
                    <Conversation
                      key={`room-${v?.id}`}
                      name={v?.name}
                      lastSenderName={v?.messages?.[0]?.user?.name || 'User'}
                      info={
                        v?.messages?.[0]?.type === 'text'
                          ? v?.messages?.[0]?.value?.message
                          : v?.messages?.[0]?.type
                      }
                      onClick={handleRoomChange(v)}></Conversation>
                  ))}
                </ConversationList>
              </Sidebar>
            )}
            <ChatBox activeRoom={rooms?.[0]} currentUser={currentUser} />
          </>
        )}
      </MainContainer>
    </>
  );
};

export default Chat;
