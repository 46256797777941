import { useState } from 'react';
interface IModalHook {
  handleClose?: () => void;
}
export const useModal = (props?: IModalHook) => {
  const [open, setOpen] = useState(false);
  const onClose = () => {
    props?.handleClose && props?.handleClose();
    setOpen(false);
  };
  const onOpen = () => setOpen(true);
  return {
    open,
    setOpen,
    onClose,
    onOpen,
  };
};
