import { forwardRef, ReactNode, Ref } from 'react';
import { ButtonProps } from 'react-bootstrap';
import { StyledButton } from './IconButton.styled';
interface IconButtonProps extends ButtonProps {
  children: ReactNode;
  ref: Ref<HTMLButtonElement>;
}
const IconButton = (props: IconButtonProps) => {
  const { children, ref, ...rest } = props;
  return (
    <StyledButton ref={ref} variant="flat" {...rest}>
      {children}
    </StyledButton>
  );
};

export default forwardRef((props: IconButtonProps, ref) => <IconButton ref={ref} {...props} />);
