import './authLayout.scss';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

interface IAuthLayout {
  leftContent: any;
  leftImage: any;
  LeftTitle?: ReactNode;
  formTitle?: ReactNode;
  renderForm?: ReactNode;
  loading?: boolean;
  rootProps?: React.HTMLAttributes<HTMLDivElement>;
}
const AuthLayout = ({
  LeftTitle,
  leftContent,
  leftImage,
  formTitle,
  renderForm,
  loading,
  rootProps,
}: IAuthLayout) => (
  <div {...rootProps} className={`auth-layout-root ${rootProps?.className}`}>
    <div className="container">
      <div className="row">
        <div className="col-xl-8 col-lg-6">
          <div className="registerinfo text-center">
            <div className="registertittle text-left">
              <Link to={ROUTES.HOME}>
                <Logo style={{ width: '70%' }} />
              </Link>
              <h1>{LeftTitle}</h1>
              {leftContent}
            </div>
            <img src={leftImage} className="img-fluid d-none d-lg-block" />
          </div>
        </div>
        <div className="col-xl-4 col-lg-6" style={{ position: 'relative' }}>
          {loading && (
            <>
              <div className="form-loader">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
              <div className="form-loader-backdrop"></div>
            </>
          )}
          <div className="signupform">
            <>
              <h2 className="mb-3 d-none d-lg-block">{formTitle}</h2>
              {renderForm}
            </>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AuthLayout;
