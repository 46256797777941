import { useMediaQuery } from 'react-responsive';

const useBreakpoint = () => {
  const xs = useMediaQuery({ minWidth: 0, maxWidth: 575 });
  const sm = useMediaQuery({ minWidth: 576 });
  const md = useMediaQuery({ minWidth: 768 });
  const lg = useMediaQuery({ minWidth: 992 });
  const xl = useMediaQuery({ minWidth: 1200 });
  const xxl = useMediaQuery({ minWidth: 1400 });
  return { xs, sm, md, lg, xl, xxl };
};

export default useBreakpoint;
