import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  addRfqRequestFailed,
  addRfqRequestSuccess,
  ADD_RFQ_REQUEST,
  cancelRfqRequestFailed,
  cancelRfqRequestSuccess,
  CANCEL_RFQ_REQUEST,
  getRfqRequestDetailFailed,
  getRfqRequestDetailResponsesFailed,
  getRfqRequestDetailResponsesSuccess,
  getRfqRequestDetailSuccess,
  getRfqRequestFailed,
  getRfqRequestSuccess,
  GET_RFQ_REQUEST,
  GET_RFQ_REQUEST_DETAIL,
  GET_RFQ_REQUEST_DETAIL_RESPONSES,
  saveRfqRequestFailed,
  saveRfqRequestSuccess,
  SAVE_RFQ_REQUEST,
} from './actions';

import i18n from 'config/i18n';
import {
  addRfqRequestApi,
  cancelRfqRequestApi,
  getRfqApi,
  getRfqDetailApi,
  getRfqDetailResponsesApi,
  saveRfqRequestApi,
} from 'services/api';
import { addNotification } from 'services/notifications';
import { ActionType, ActionTypeWithCallback, ActionTypeWithHistory } from 'store/rootReducer';
import { logError } from 'store/user/actions';
import { ROUTES } from 'routes/constants';
import { IResponse } from 'store/app/interfaces';
import { resetSelectSupplierBusiness } from 'store/supplier/actions';
import { appNotification } from 'store/app/actions';

function* saveRfqRequestSaga({ payload }: ActionTypeWithCallback) {
  try {
    const data: Response = yield call(saveRfqRequestApi, payload?.payload);
    yield put(saveRfqRequestSuccess(data));
    yield put(resetSelectSupplierBusiness());
    payload.onSuccess(data);
  } catch (error: any) {
    yield put(logError(error));
    if (error.response.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(saveRfqRequestFailed(message));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);

      yield put(saveRfqRequestFailed(errors));
    }
    if (error?.response?.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}
function* getRfqRequestSaga({ payload }: ActionTypeWithHistory) {
  try {
    const data: IResponse = yield call(getRfqApi, payload);
    yield put(getRfqRequestSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(getRfqRequestFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(getRfqRequestFailed(errors));
    }
  }
}

function* getRfqRequestDetailSaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(getRfqDetailApi, payload);
    yield put(getRfqRequestDetailSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(getRfqRequestDetailFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(getRfqRequestDetailFailed(errors));
    }
  }
}

function* getRfqRequestDetailResponsesSaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(getRfqDetailResponsesApi, payload);
    yield put(getRfqRequestDetailResponsesSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(getRfqRequestDetailResponsesFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(getRfqRequestDetailResponsesFailed(errors));
    }
  }
}

function* cancelRfqRequestSaga({ payload }: ActionTypeWithCallback) {
  try {
    const data: IResponse = yield call(cancelRfqRequestApi, payload.payload);
    yield put(cancelRfqRequestSuccess(payload?.payload));
    payload.onSuccess(data);
  } catch (error: any) {
    let message = 'Oops something went wrong';
    yield put(cancelRfqRequestFailed(message));

    yield put(appNotification({ type: 'danger', message }));
  }
}

function* addRfqRequestSaga({ payload }: ActionTypeWithCallback) {
  try {
    const data: Response = yield call(addRfqRequestApi, payload?.payload);
    yield put(addRfqRequestSuccess(data));
    yield put(resetSelectSupplierBusiness());
    payload.onSuccess(data);
  } catch (error: any) {
    yield put(logError(error));
    if (error.response.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(addRfqRequestFailed(message));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);

      yield put(addRfqRequestFailed(errors));
    }
    if (error?.response?.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}

function* RfqSaga() {
  yield all([
    takeLatest(SAVE_RFQ_REQUEST, saveRfqRequestSaga),
    takeLatest(GET_RFQ_REQUEST, getRfqRequestSaga),
    takeLatest(GET_RFQ_REQUEST_DETAIL, getRfqRequestDetailSaga),
    takeLatest(GET_RFQ_REQUEST_DETAIL_RESPONSES, getRfqRequestDetailResponsesSaga),
    takeLatest(CANCEL_RFQ_REQUEST, cancelRfqRequestSaga),
    takeLatest(ADD_RFQ_REQUEST, addRfqRequestSaga),
  ]);
}

export default RfqSaga;
