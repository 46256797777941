import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  getChatRoomSuccess,
  getChatRoomFailed,
  addChatMessageSuccess,
  addChatMessageFailed,
  GET_CHAT_ROOM,
  GET_CHAT_MESSAGES,
  getChatMessagesSuccess,
  getChatMessagesFailed,
  ADD_CHAT_MESSAGE,
} from './actions';

import { addChatMessageApi, getChatMessagesApi, getChatRoomApi } from 'services/api';
import { ActionType, ActionTypeWithCallback } from 'store/rootReducer';
import { IResponse } from 'store/app/interfaces';
import { appNotification } from 'store/app/actions';

function* getChatRoomSaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(getChatRoomApi);
    const { data: result } = data;
    yield put(getChatRoomSuccess(result));
  } catch (e: any) {
    yield put(getChatRoomFailed(e?.response?.data));
  }
}

function* getChatMessagesSaga({ payload: { chat_room_id } }: ActionType) {
  try {
    const response: IResponse = yield call(getChatMessagesApi, { chat_room_id });
    if (response?.status === 'Success') {
      yield put(getChatMessagesSuccess(response?.data));
    } else {
      yield put(appNotification({ type: 'danger', message: response?.message }));
    }
  } catch (e: any) {
    yield put(getChatMessagesFailed(e?.response?.data));
  }
}

function* addChatMessageSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response: IResponse = yield call(addChatMessageApi, payload);
    if (response?.status === 'Success') {
      if (onSuccess) onSuccess(response?.data);
      yield put(addChatMessageSuccess(response?.data));
    } else {
      yield put(appNotification({ type: 'danger', message: response?.message }));
    }
  } catch (e: any) {
    if (onError) onError(e?.response);
    yield put(addChatMessageFailed(e?.response?.data));
  }
}

function* chatSaga() {
  yield all([
    takeLatest(GET_CHAT_ROOM, getChatRoomSaga),
    takeLatest(GET_CHAT_MESSAGES, getChatMessagesSaga),
    takeLatest(ADD_CHAT_MESSAGE, addChatMessageSaga),
  ]);
}

export default chatSaga;
