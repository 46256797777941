import { IHomeSelector } from 'pages/Home/Home';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import './footer.scss';
const Footer = () => {
  const { sparePartsCategories } = useSelector((state: IHomeSelector) => state.home);
  return (
    <footer className="footer-root">
      <div className="container d-md-block d-none">
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="index.html">
                    <img
                      src={require('../../assets/images/footerlogo.png')}
                      className="img-fluid"
                      alt="logo"
                    />
                  </a>
                </div>
                <div className="footer-text">
                  <p className="mb-4"></p>
                  <p className="mb-5">
                    {'Want motonexa in your city?'}
                    <br />
                    <span> {'Reach out to us'} </span>
                    {` and we'll bring motonexa to
                  you.`}
                  </p>
                  <div className="appstores">
                    {/* <a href="">
                      <img
                        src={require('../../assets/images/appstore.png')}
                        className="img-fluid"
                      />
                    </a> */}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.motonexaapp"
                      target="_blank"
                      rel="noreferrer">
                      <img
                        src={require('../../assets/images/googlestore.png')}
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-6 mb-30">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>Explore</h3>
                    </div>
                    <ul>
                      <li>
                        <a href="#">Privacy policy</a>
                      </li>
                      <li>
                        <Link to={ROUTES.TERMS_OF_SERVICE} target="_blank">
                          Terms & conditions
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.DASHBOARD} target="_blank">
                          Disclaimer
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.COOKIE_POLICY} target="_blank">
                          Cookie policy
                        </Link>
                      </li>
                      {/* <li>
                        <a href="#">Listing Guidelines</a>
                      </li> */}
                      <li>
                        <Link to={ROUTES.LIST_SUPPLIER}>Search spare parts</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>Company</h3>
                    </div>
                    <ul>
                      <li>
                        <Link to={ROUTES.ABOUT_US}>About us</Link>
                      </li>
                      <li>
                        <a href="#how-it-works">How it works</a>
                      </li>
                      {/* <li>
                        <a href="#">careers</a>
                      </li>
                      <li>
                        <a href="#">Blog</a>
                      </li> */}
                      <li>
                        <Link to={ROUTES.CONTACT_US}>Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3>Popular Categories</h3>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <ul>
                          {sparePartsCategories?.data?.slice(0, 6)?.map((v: any, i: number) => (
                            <li key={`popular-link-spare-part-category-${v?.id}`}>
                              <Link to={`${ROUTES.LIST_SUPPLIER}?category=${v?.id}`}>
                                {v?.categoryName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">
                <p>&copy; {new Date().getFullYear()} by motonexa.com | All Right Reserved</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 text-right">
              <div className="footer-menu">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/Motonexa/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/Motonexa/"
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/motonexa"
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/motonexa"
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
