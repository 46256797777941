import axios from 'axios';

import { PUBLIC_ROUTES, ROUTES } from 'routes/constants';
import { clearUserSession, getCsrfToken, getUserSession } from 'services/auth';
import store from 'store/store';
import { logoutUserSuccess } from 'store/user/actions';

axios.interceptors.request.use(instance => {
  const user: any = getUserSession();
  const { csrfToken } = getCsrfToken();
  if (user) instance.headers.Authorization = `Bearer ${user?.token}`;
  instance.headers['X-Requested-With'] = 'XMLHttpRequest';
  if (csrfToken) instance.headers['X-XSRF-TOKEN'] = csrfToken;
  instance.withCredentials = true;
  return instance;
});

axios.interceptors.response.use(
  response => response,
  error => {
    // @todo implement refresh token
    if (
      error.response?.status === 403 ||
      error.response?.status === 401 ||
      error.response?.status === 419
    ) {
      clearUserSession();
      // document.location.pathname = ROUTES.LOGOUT;
      store.dispatch(logoutUserSuccess());
      if (!PUBLIC_ROUTES.includes(document.location.pathname)) {
        document.location.pathname = ROUTES.HOME;
      }
    }

    return Promise.reject(error);
  },
);

export default axios;
