export const ROUTES = {
  // Public
  LOGIN: '/login',
  SIGNUP: '/signup',
  REGISTER_BUSINESS: '/register-your-business',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_ACCOUNT: '/verify-account',
  TERMS_OF_SERVICE: '/terms-of-service',
  HOME: '/',
  LIST_SUPPLIER: '/supplier',
  DETAIL_SUPPLIER: '/supplier/:id',
  ADD_BUSINESS: '/add-business',
  VERIFY_OTP: '/verify-otp',
  FORGOT_PIN: '/forgot-pin',
  RESET_PIN: '/reset-pin',
  CONTACT_US: '/contact-us',
  ABOUT_US: '/about-us',
  DISCLAIMER: '/disclaimer',
  COOKIE_POLICY: '/cookie-policy',
  // Protected
  LOGOUT: '/logout',
  DASHBOARD: '/dashboard',
  LIVE_FEED: '/live-feed',
  CART: '/cart',
  CREATE_RFQ: '/create-rfq',
  ADD_RFQ: '/add-rfq',
  RFQ_LIST: '/rfq-list',
  RFQ_DETAIL: '/rfq-detail/:id',
  RFQ_RESPONSE_LIST: '/rfq-response',
  RFQ_RESPONSE_DETAIL: '/rfq-response-detail/:id',
  ORDERS: '/my-orders',
  PROFILE: '/profile',
  CHAT: '/chat',
  ADD_SERVICE_PROVIDER_BUSINESS: '/add-service-provider-business',
  DOWNLOAD_PDF: '/download-pdf',
  LOGIN_WITH_OTP: '/otp-login',
  POINT_HISTORY: '/point-history',
};

export const PUBLIC_ROUTES = [
  ROUTES.FORGOT_PASSWORD,
  ROUTES.LOGIN,
  ROUTES.SIGNUP,
  ROUTES.VERIFY_ACCOUNT,
  ROUTES.TERMS_OF_SERVICE,
  ROUTES.HOME,
  ROUTES.ADD_BUSINESS,
  ROUTES.LIST_SUPPLIER,
  ROUTES.REGISTER_BUSINESS,
  ROUTES.VERIFY_OTP,
  ROUTES.FORGOT_PIN,
  ROUTES.RESET_PIN,
  ROUTES.DETAIL_SUPPLIER,
  ROUTES.DISCLAIMER,
  ROUTES.COOKIE_POLICY,
  ROUTES.LOGIN_WITH_OTP,
];
