import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

import {
  ChatContainer,
  MessageInput,
  ConversationHeader,
  Avatar,
  MessageList,
  TypingIndicator,
  MessageSeparator,
  Message,
  MessageModel,
} from '@chatscope/chat-ui-kit-react';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import IconButton from 'components/IconButton/IconButton';
import OutsideWrapper from 'components/OutsideWrapper/OutsideWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { addChatMessage, getChatMessages, getChatRoom } from 'store/chat/actions';
import { chatMessagesSelector, chatRoomSelector } from 'store/chat/selectors';
import { Spinner } from 'react-bootstrap';
import { getUserSession } from 'services/auth';
import { currentRoleSelector } from 'store/user/selectors';
import { IChatMessage } from 'store/chat/interfaces';
import { uploadFileInChatMessageApi } from 'services/api';
import { usePusherSocket } from 'services/hooks/useSocketHook';
import ChatAttachmentOption from 'components/ChatAttachmentOption/ChatAttachmentOption';
const AkashIco = require('../../assets/images/no-image.png');
export interface IImageArg {
  file: File[];
  preview: string[];
}

const ChatBox = ({ currentUser, activeRoom }) => {
  const dispatch = useDispatch();

  const { data: chats, loading: chatLoading, error: chatError } = useSelector(chatMessagesSelector);
  const attachmentRef = useRef(null);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [data, setData] = useState(chats);
  const currentRole = useSelector(currentRoleSelector);
  const user = getUserSession();
  const handleSend = (message: string) => {
    const sentTime = dayjs().format('HH:mm');
    const payload: IChatMessage = {
      chat_room_id: activeRoom?.id,
      user_id: user?.id,
      value: {
        message,
        sentTime,
        sender: user?.name,
        direction: 'outgoing',
        position: 'single',
      },
      status: 'pending',
      type: 'text',
    };
    setData(s => [...s, { ...payload }]);
    payload.status = 'sent';
    dispatch(
      addChatMessage({
        payload,
        onSuccess: resp => {
          setData(s => s.map(v => (v?.status === 'pending' ? { ...resp, status: 'sent' } : v)));
        },
        onError: resp => {},
      }),
    );
  };
  const toggleAttachmentMenu = () => setOpenAttachment(!openAttachment);

  const handleImageUpload = async (file: IImageArg) => {
    toggleAttachmentMenu();
    const sentTime = dayjs().format('HH:mm');
    const chatData = [];
    const preview = [];
    file.preview.forEach((v, i) => {
      const s = {
        chat_room_id: activeRoom?.id,
        user_id: user?.id,
        value: {
          direction: 'outgoing',
          sentTime,
          payload: {
            src: '',
            alt: `preview-${i}`,
            width: 300,
          },
        },
        status: 'pending',
        type: 'image',
      };
      chatData.push({ ...s, status: 'sent' });
      preview.push({
        ...s,
        value: { ...s?.value, payload: { ...s?.value?.payload, src: v } },
      });
    });
    setData(s => [...s, ...preview]);
    dispatch(
      addChatMessage({
        payload: { photo: file.file, chatData, type: 'image' },
        onSuccess: resp => {
          setData(s => s.map(v => (v?.status === 'pending' ? resp : v)));
        },
        onError: err => {
          console.log('image error', err);
        },
      }),
    );
    // const imageUrls = await uploadFileInChatMessageApi({ photo: file.file, chatData });
  };
  const handleFileUpload = file => {
    console.log('file', file);
  };
  const handleVideoUpload = file => {};

  useEffect(() => {
    if (activeRoom?.id) {
      dispatch(getChatMessages({ chat_room_id: activeRoom?.id }));
    }
  }, [activeRoom?.id]);

  useEffect(() => {
    if (!chatLoading && chats?.length) {
      setData(chats);
    }
  }, [chatLoading]);

  usePusherSocket(
    {
      callBack: notification => {
        if (!notification?.chatMessage?.[0])
          setData(s => [...s.filter(v => v?.id !== undefined), notification?.chatMessage]);
        else setData(s => [...s.filter(v => v?.id !== undefined), ...notification?.chatMessage]);
      },
      type: `chat.${activeRoom?.id}`,
    },
    [activeRoom?.id],
  );

  if (chatLoading)
    return (
      <div style={{ flex: 1 }} className="justify-content-center d-flex align-items-center">
        <Spinner animation="grow" />
        <span className="text-secondary mt-2">Loading chat, please wait...</span>
      </div>
    );
  return (
    <div style={{ flex: 1 }}>
      <ChatContainer>
        <ConversationHeader>
          <Avatar
            src={activeRoom?.image || AkashIco}
            name={
              currentRole === 'service-provider' || currentRole === 'user'
                ? 'Motonexa Support'
                : activeRoom?.name
            }
          />
          <ConversationHeader.Content
            userName={
              currentRole === 'service-provider' || currentRole === 'user'
                ? 'Motonexa Support'
                : activeRoom?.name
            }
            info="online"
          />
        </ConversationHeader>
        <MessageList typingIndicator={<TypingIndicator content="Akash is typing" />}>
          {/* <MessageSeparator content="Saturday, 30 November 2019" /> */}

          {data?.map(({ value: v, user_id, ...rest }, i) => (
            <Message
              type={rest?.type}
              key={`item-${v?.sender}-${v?.direction}-${v?.sentTime}-${rest?.id}-${100000 - i}`}
              model={{
                ...v,
                direction: parseInt(user_id) === currentUser?.id ? 'outgoing' : 'incoming',
              }}>
              <Message.Footer className="justify-content-end">
                {rest?.status === 'pending' ? (
                  <div className="d-flex align-items-center">
                    <Spinner
                      animation="border"
                      style={{
                        height: '0.5rem',
                        width: '0.5rem',
                        color: '#bdbdbd',
                      }}
                    />
                    {rest?.type !== 'text' && (
                      <span className="text-muted" style={{ fontSize: 11 }}>
                        <i> Uploading...</i>
                      </span>
                    )}
                  </div>
                ) : (
                  <span style={{ float: 'right' }}>
                    <span>{v?.sentTime}</span>
                    <i className="fa fa-check" />
                    {v?.status === 'received' && (
                      <i className="fa fa-check" style={{ marginLeft: -12 }} />
                    )}
                  </span>
                )}
              </Message.Footer>
            </Message>
          ))}

          {/* <MessageSeparator content="Saturday, 31 November 2019" /> */}
        </MessageList>

        <MessageInput
          ref={attachmentRef}
          placeholder="Type message here"
          onSend={handleSend}
          onAttachClick={toggleAttachmentMenu}
        />
      </ChatContainer>
      {openAttachment && (
        <OutsideWrapper handleOutsideClick={toggleAttachmentMenu}>
          <ChatAttachmentOption
            onFileUpload={handleFileUpload}
            onImageUpload={handleImageUpload}
            onVideoUpload={handleVideoUpload}
          />
        </OutsideWrapper>
      )}
    </div>
  );
};

export default ChatBox;
