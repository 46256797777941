import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  searchSupplierBusinessFailed,
  searchSupplierBusinessNextPageSuccess,
  searchSupplierBusinessSuccess,
  SEARCH_SUPPLIER_BUSINESS,
  SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE,
  supplierBusinessDetailFailed,
  supplierBusinessDetailSuccess,
  SUPPLIER_BUSINESS_DETAIL,
} from './actions';

import i18n from 'config/i18n';
import {
  searchSupplierBusinessApi,
  supplierBusinessDetailApi,
} from 'services/api';
import { addNotification } from 'services/notifications';
import {
  ActionType,
  ActionTypeWithCallback,
  ActionTypeWithHistory,
  PayloadCallback,
  PayloadHistory,
} from 'store/rootReducer';
import { logError, updateUser } from 'store/user/actions';
import { ROUTES } from 'routes/constants';
import { getUserSession, saveUserSession } from 'services/auth';
import { IResponse } from 'store/app/interfaces';

function* searchSupplierBusinessSaga({ payload }: ActionTypeWithHistory) {
  try {
    const data: IResponse = yield call(searchSupplierBusinessApi, payload);
    yield put(searchSupplierBusinessSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(searchSupplierBusinessFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(searchSupplierBusinessFailed(errors));
    }
  }
}

function* searchSupplierBusinessNextPageSaga({ payload }: ActionTypeWithHistory) {
  try {
    const data: IResponse = yield call(searchSupplierBusinessApi, payload);
    yield put(searchSupplierBusinessNextPageSuccess(data?.data));
  } catch (error: any) {
    console.log('eerror', error);

    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(searchSupplierBusinessFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(searchSupplierBusinessFailed(errors));
    }
  }
}

function* supplierBusinessDetailSaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(supplierBusinessDetailApi, payload);
    yield put(supplierBusinessDetailSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(supplierBusinessDetailFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(supplierBusinessDetailFailed(errors));
    }
  }
}

function* supplierBusinessSaga() {
  yield all([
    takeLatest(SEARCH_SUPPLIER_BUSINESS, searchSupplierBusinessSaga),
    takeLatest(SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE, searchSupplierBusinessNextPageSaga),
    takeLatest(SUPPLIER_BUSINESS_DETAIL, supplierBusinessDetailSaga),
  ]);
}

export default supplierBusinessSaga;
