import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
  Ref,
  ReactNode,
} from 'react';
import './searchDropdown.scss';
import useArrowKeyNavigation from 'services/hooks/useArrowKeyNavigation';
import useOutsideClick from 'services/hooks/useOutsideClick';
import { DropdownOption, DropdownProps } from '../Dropdown/Dropdown.interfaces';
import { Spinner } from 'react-bootstrap';

interface IGroupOption {
  header: string;
  data: any[];
}
interface SearchDropdownProps extends InputHTMLAttributes<HTMLInputElement> {
  options: any[];
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  afterSelect?: (selectedOption: DropdownOption) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  containerClass?: string;
  inputRef?: Ref<HTMLInputElement>;
  defaultOption?: any;
  placeholder?: string;
  loading?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  noIcon?: boolean;
  optionLabel?: string;
  optionValue?: string;
  renderOption?: (option: any) => ReactNode;
  renderInput?: (option: any) => string; // necessary if renderOption is passed
  disableSearch?: boolean;
  clearInputValue?: boolean;
  isGroupBy?: boolean;
}
const SearchDropdown = ({
  options,
  containerClass,
  inputRef,
  onChange,
  afterSelect,
  onBlur,
  name,
  value,
  defaultOption,
  placeholder,
  loading = false,
  inputProps,
  noIcon = false,
  optionLabel = 'label',
  optionValue = 'value',
  renderOption,
  renderInput,
  disableSearch = false,
  clearInputValue = false,
  isGroupBy = false,
  ...props
}: SearchDropdownProps) => {
  // const {
  //   t,
  //   handleInputChange,
  //   handleInputFocus,
  //   handleKeyboardInteraction,
  //   isOpen,
  //   isRequired,
  //   dropdownRef,
  //   inputRef,
  //   inputValue,
  //   restProps: rest,
  //   error,
  //   label,
  //   name,
  //   optionList,
  //   disabledOptions,
  //   onSelectOption,
  // } = useDropdown(props);
  const [open, setOpen] = useState(false);

  // const { t } = useTranslation();
  // const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [optionList, setOptionList] = useState(options);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { current } = useArrowKeyNavigation({
    items: optionList,
    element: dropdownRef.current as HTMLElement,
  });

  useOutsideClick(dropdownRef, () => setOpen(false));

  const handleSelectOption = useCallback(
    (option: DropdownOption) => {
      if (option) {
        setInputValue(renderInput ? renderInput(option) : option?.[optionLabel]);
        setOpen(false);

        if (onChange) {
          onChange({
            type: 'change',
            target: {
              name,
              value: option?.[optionValue],
            },
          } as ChangeEvent<HTMLInputElement>);
        }
        if (afterSelect) {
          afterSelect(option);
        }
      }
    },
    [name, onChange],
  );

  const handleInputFocus = useCallback(
    event => {
      setOpen(true);
      setOptionList(options);
      // event.target.select();
    },
    [options],
  );
  const toggleOpen = useCallback(
    event => {
      setOpen(!open);
      setOptionList(options);
      event.target.select();
    },
    [options],
  );
  const handleInputChange = useCallback(
    event => {
      const targetValue = event.target.value;
      const filteredList = options.filter(option =>
        option?.[optionLabel]
          .toString()
          .toLowerCase()
          .includes(targetValue.toString().toLowerCase()),
      );

      setOptionList(filteredList);
      setInputValue(targetValue);
      // if (onChange) {
      //   onChange({
      //     type: 'change',
      //     target: {
      //       name,
      //       value: targetValue,
      //     },
      //   } as ChangeEvent<HTMLInputElement>);
      // }
    },
    [options],
  );

  const handleKeyboardInteraction = useCallback(
    event => {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        event.stopPropagation();
        event.preventDefault();

        const targetElement: HTMLLIElement = dropdownRef.current.querySelector(
          `[data-value="${current?.[optionValue]}"]`,
        ) as HTMLLIElement;

        if (targetElement) targetElement.focus();
      } else if (event.key === 'Enter') {
        handleSelectOption(current as DropdownOption);
      }
    },
    [current, handleSelectOption],
  );
  const onSelectOption = useCallback(
    (option: DropdownOption) => (e: any) => handleSelectOption(option),
    [options],
  );
  useEffect(() => {
    if (defaultOption?.[optionValue]) {
      handleSelectOption(defaultOption);
      // setInputValue(defaultOption?.[optionLabel]);
      // setOpen(false);
    }
  }, [defaultOption?.[optionValue]]);

  useEffect(() => {
    if (clearInputValue) setInputValue(null);
  }, [clearInputValue]);
  // useEffect(() => {
  //   if (value) setInputValue(value.toString());
  // }, [value]);
  // useEffect(() => {
  //   console.log(name, options);

  //   setOptionList(options);
  // }, [options]);
  return (
    <div className="search-dropdown-root">
      <div className={containerClass}>
        {/* <select className="" ref={inputRef}>
        {options.map((option, i) => (
          <option key={`${option.value}`} value={option.value} data-display-text={option?.[optionLabel]}>
            {option?.[optionLabel]}
          </option>
        ))}
      </select> */}
        <div
          className={`dropdown ${open ? 'open' : ''}`}
          tabIndex={0}
          ref={dropdownRef}
          // onClick={toggleOpen}
          onKeyDown={handleKeyboardInteraction}>
          <input
            type="hidden"
            name={name}
            ref={inputRef}
            defaultValue={defaultOption?.[optionValue]}
          />
          {disableSearch ? (
            <div className="current" onClick={toggleOpen}>
              {inputValue}
            </div>
          ) : (
            <input
              // ref={inputRef}
              type="text"
              className="current search-dropdown-input"
              placeholder={placeholder}
              value={loading ? 'Loading...' : inputValue || ''}
              onFocus={handleInputFocus}
              onChange={handleInputChange}
              disabled={loading}
              title={inputValue}
              {...inputProps}
            />
          )}

          <div className={`list ${open ? 'open' : ''}`}>
            <ul className="list-ul">
              {optionList?.length ? (
                optionList.map((option, i) =>
                  option?.header ? (
                    <li key={`header-${option?.header}`} style={{ color: '#3c3c3c' }}>
                      {option?.header}
                    </li>
                  ) : option?.data ? (
                    option?.data.map((childOption, j) => (
                      <li key={`child-opton-${childOption?.[optionValue]}`}>
                        {childOption?.[optionLabel]}
                      </li>
                    ))
                  ) : (
                    <li
                      onClick={onSelectOption(option)}
                      key={`${option.key}-${i}` || `${option?.[optionValue]}`}
                      className="option"
                      tabIndex={0}
                      data-value={option?.[optionValue]}>
                      {renderOption ? renderOption(option) : option?.[optionLabel]}
                    </li>
                  ),
                )
              ) : (
                <li key={'no-result-dd'} className="option" data-value={'0'}>
                  {loading ? 'Loading...' : 'No result'}
                </li>
              )}
            </ul>
          </div>
          {!noIcon && (
            <img
              onClick={e => handleInputFocus(e)}
              src={require('../../assets/images/down.png')}
              className="img-fluid arrow-icon"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default forwardRef<HTMLInputElement, SearchDropdownProps>((props, ref) => (
  <SearchDropdown {...props} inputRef={ref} />
));
