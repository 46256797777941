import { fork } from 'redux-saga/effects';
import appSaga from './app/sagas';
import homeSaga from './home/sagas';
import rolesSaga from './roles/sagas';
import supplierBusinessSaga from './supplier/sagas';
import userSaga from './user/sagas';
import rfqSaga from './rfq/sagas';
import rfqResponseSaga from './rfqResponse/sagas';
import cartSaga from './cart/sagas';
import businessSaga from './business/sagas';
import orderSaga from './orders/sagas';
import notificationSaga from './notifications/sagas';
import reviewSaga from './reviews/sagas';
import chatSaga from './chat/sagas';
import pointHistorySaga from './pointHistory/sagas';

export function* rootSaga() {
  yield fork(appSaga);
  yield fork(homeSaga);
  yield fork(userSaga);
  yield fork(rolesSaga);
  yield fork(supplierBusinessSaga);
  yield fork(rfqSaga);
  yield fork(rfqResponseSaga);
  yield fork(cartSaga);
  yield fork(businessSaga);
  yield fork(orderSaga);
  yield fork(notificationSaga);
  yield fork(reviewSaga);
  yield fork(chatSaga);
  yield fork(pointHistorySaga);
}
