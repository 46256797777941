// import dayjs from 'dayjs';
import jsonToFormData from 'json-form-data';
import { getUserSession } from './auth';

import axios from 'config/axios';
import {
  ConfirmForgotPasswordPayload,
  ConfirmSignupPayload,
  ForgotPasswordPayload,
  GetTokenPayload,
  SignupPayload,
  CheckAvailabilityPayload,
  ChangePasswordPayload,
} from 'store/user/interfaces';
import { CitiesPayload, ProvincePayload, Search } from 'store/app/interfaces';
import { IResponse } from 'store/app/interfaces';
import { AxiosResponse } from 'axios';
import { getFormData } from './utils';
import { IOrderActionPayload } from 'store/orders/interfaces';
import { ChatMessagePayload } from 'store/chat/interfaces';

const user = getUserSession();

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_API_URL;

export const csrfToken = async () => {
  const { data } = await axios.get(`${BASE_URL}/sanctum/csrf-cookie`);
  return data;
};
export const getSpartParts = async (payload: Search) => {
  const { data } = await axios.get(
    `${API_URL}/supplier-business/search-parts?search=${payload?.search}`,
  );
  return data;
};
export const getCounrty = async (payload: Search) => {
  const { data } = await axios.get(`${API_URL}/countries?search=${payload?.search}`);
  return data;
};

export const getSingleCountry = async (payload: { id: number }) => {
  const { data } = await axios.get(`${API_URL}/countries/${payload.id}`);
  return data;
};

export const getProvince = async (payload: ProvincePayload) => {
  let qs = `?search=${payload?.search}`;
  if (payload?.countryId) qs += `&country=${payload?.countryId}`;
  if (payload?.provinceId) qs += `&province=${payload?.provinceId}`;

  const { data } = await axios.get(`${API_URL}/province${qs}`);
  return data;
};

export const getCitiesApi = async (payload: CitiesPayload) => {
  let qs = `?search=${payload?.search}`;
  if (payload?.countryId) qs += `&country=${payload?.countryId}`;
  if (payload?.provinceId) qs += `&province=${payload?.provinceId}`;
  const { data } = await axios.get(`${API_URL}/cities${qs}`);
  return data;
};

export const getCitiesWithPaginateApi = async (payload: CitiesPayload) => {
  let qs = `?search=${payload?.search}`;
  if (payload?.countryId) qs += `&country=${payload?.countryId}`;
  if (payload?.provinceId) qs += `&province=${payload?.provinceId}`;
  const { data } = await axios.get(`${API_URL}/cities-with-paginate${qs}`);
  return data?.data;
};
export const getVehicleMake = async (payload: Search) => {
  const { data } = await axios.get(
    `${API_URL}/vehicleMake?search=${payload?.search}&sortField=car_make_category&sortOrder=asc`,
  );
  return data;
};
export const getSpartPartsCategory = async (payload: any) => {
  const { data } = await axios.get(
    `${API_URL}/spare-parts-categories/search?search=${payload?.search}&level=${payload.level}`,
  );
  return data;
};

export const getOneSpartPartsCategory = async (payload: any) => {
  const { data } = await axios.get(`${API_URL}/spare-parts-categories/getOne/${payload.id}`);
  return data;
};
export const getSpartPartsSubCategory = async (payload: any) => {
  let str = [];
  if (payload?.categoryId) str.push(`categoryId=${payload.categoryId}`);
  if (payload?.search) str.push(`search=${payload.search}`);
  if (payload?.level) str.push(`level=${payload.level}`);
  const queryString = str.join('&');
  const { data } = await axios.get(`${API_URL}/spare-parts-categories/search?${queryString}`);
  return data;
};
export const whoAmIApi = async () => {
  const { data } = await axios.get(`${API_URL}/me`);
  return data;
};

export const forgotPasswordApi = async ({ email }: ForgotPasswordPayload) => {
  const { data } = await axios.post(`${API_URL}/forgot`, { email });
  return data;
};

export const confirmForgotPasswordApi = async (payload: ConfirmForgotPasswordPayload) => {
  const { data } = await axios.post(`${API_URL}/confirm_forgot`, payload);
  return data;
};

export const changePassword = async (payload: ChangePasswordPayload) => {
  await axios.put(`${API_URL}/accounts/user/password`, payload);
};

export const signupUserApi = async (payload: SignupPayload) => {
  const { data } = await axios.post(`${API_URL}/partner/register`, payload);
  return data;
};
export const socialLoginApi = async (payload: any) => {
  const { data }: AxiosResponse<IResponse> = await axios.post(
    `${API_URL}/user/${payload?.action}/${payload?.provider}`,
    payload,
  );
  return data;
};

export const confirmSignupApi = async (payload: ConfirmSignupPayload) => {
  const { data } = await axios.post(`${API_URL}/confirm_signup`, payload);
  return data;
};

export const getToken = async ({ email, password }: GetTokenPayload) => {
  const { data } = await axios.post(`${API_URL}/token`, {
    email,
    password,
  });
  return data;
};

export const fetchUserSettingsApi = async () => {
  const { data } = await axios.get(`${API_URL}/portfolio-user-settings/master-data?ids`);
  return data;
};

export const checkIfUserExistsApi = async (payload: CheckAvailabilityPayload) => {
  const { data } = await axios.post(`${API_URL}/accounts/user/exists `, payload);
  return data;
};

export const getCountryCode = async (payload: Search) => {
  const { data } = await axios.get(`${API_URL}/country-code?search=${payload?.search}`);
  return data;
};

export const loginApi = async (payload: any) => {
  const { data } = await axios.post(`${API_URL}/partner/login `, payload);
  return data;
};

export const logoutApi = async (token: string) => {
  const { data } = await axios.post(
    `${API_URL}/auth/logout`,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data;
};

export const registerApi = async (payload: any) => {
  const { data } = await axios.post(`${API_URL}/partner/register `, payload);
  return data;
};

export const confirmOtpApi = async (payload: any) => {
  const { data } = await axios.post(`${API_URL}/partner/confirm-otp `, payload);
  return data;
};

export const loginWithOtpApi = async (payload: any) => {
  const { data } = await axios.post(`${API_URL}/partner/login-with-otp `, payload);
  return data;
};

export const resetPasswardApi = async (payload: any) => {
  const { data } = await axios.post(`${API_URL}/users/password-reset `, payload);
  return data;
};

export const getBusinessByUserApi = async (payload: any) => {
  const { data } = await axios.post(`${API_URL}/partner/getBusinessIdByUser `, payload);
  return data;
};

export const getSupplierDealershipTypeOption = async input => {
  const { data } = await axios.get(`${API_URL}/dealershiptype-list?search=${input}`);
  return data;
};

export const getAllSparePartsSubCategories = async input => {
  const { data } = await axios.get(
    `${API_URL}/spare-parts-categories/getAllSparePartsSubCategories?search=${input}`,
  );
  return data;
};

export const sendForgotPinOtp = async payload => {
  const { data } = await axios.post(`${API_URL}/partner/sendForgotPinOtp`, payload);
  return data;
};

export const verifyForgotPinOtp = async payload => {
  const { data } = await axios.post(`${API_URL}/partner/verifyForgotPinOtp`, payload);
  return data;
};

export const sendResetPin = async payload => {
  const { data } = await axios.post(`${API_URL}/partner/sendResetPin`, payload);
  return data;
};

export const saveSupplierBusinessApi = async payload => {
  console.log('p', payload);
  const formData = jsonToFormData(payload);
  let url = 'supplier-business';
  if (payload?.id) url += `/${payload?.id}?_method=PUT`;

  const { data } = await axios.post(`${API_URL}/${url}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const addNewServiceProviderBusinessApi = async payload => {
  console.log('p', payload);
  const formData = jsonToFormData(payload);
  let url = 'service-provider-business/addNewBusiness';
  if (payload?.id) url += `/${payload?.id}?_method=PUT`;

  const { data } = await axios.post(`${API_URL}/${url}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const saveSeviceProviderBusinessApi = async payload => {
  console.log('p', payload);
  const formData = jsonToFormData(payload);
  let url = 'service-provider';
  if (payload?.id) url += `/${payload?.id}?_method=PUT`;

  const { data } = await axios.post(`${API_URL}/${url}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const saveBusinessInStepApi = async payload => {
  console.log('p', payload);
  const formData = jsonToFormData(payload);
  // const formData = new FormData();
  // Object.keys(payload).map(key => formData.append(key, payload[key]));
  let url = 'supplier-business/step';
  const { data } = await axios.post(`${API_URL}/${url}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const searchSupplierBusinessApi = async (payload: any) => {
  const {
    page = 0,
    sortField = 'created_at',
    sortOrder = 'desc',
    sizePerPage = 10,
    ...restPayload
  } = payload;
  const { data } = await axios.post(
    `${API_URL}/supplier-business/search?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&sizePerPage=${sizePerPage}`,
    restPayload,
  );
  return data;
};

export const supplierBusinessDetailApi = async (payload: any) => {
  const { id } = payload;
  const { data } = await axios.get(`${API_URL}/supplier-business/show/${id}`);
  return data;
};

export const getCarModel = async ({ search = '', vehicleMake = '' }: any) => {
  const { data } = await axios.get(
    `${API_URL}/car-model?vehicleMake=${vehicleMake}&search=${search}`,
  );
  return data;
};

export const getCarGeneration = async ({ search, vehicleModel }: any) => {
  const { data } = await axios.get(
    `${API_URL}/car-generation?vehicleModel=${vehicleModel}&search=${search}`,
  );
  return data;
};
export const getCarYear = async ({ search, vehicleModel }: any) => {
  const { data } = await axios.get(
    `${API_URL}/car-year?vehicleModel=${vehicleModel}&search=${search}`,
  );
  return data;
};

export const getCarYearList = async () => {
  const { data } = await axios.get(`${API_URL}/car-year`);
  return data;
};
export const getCarModification = async ({ search, carGeneration }: any) => {
  const { data } = await axios.get(
    `${API_URL}/car-modification?carGeneration=${carGeneration}&search=${search}`,
  );
  return data;
};

export const getCarModificationByYear = async ({ search, carModel }: any) => {
  const { data } = await axios.get(
    `${API_URL}/car-modification?carModel=${carModel}&search=${search}`,
  );
  return data;
};

export const saveRfqRequestApi = async payload => {
  console.log('p', payload);

  // const formData = new FormData();
  // Object.keys(payload).map(key => formData.append(key, payload[key]));
  const formData = jsonToFormData(payload);
  let url = 'rfq';
  if (payload?.id) url += `/${payload?.id}?_method=PUT`;

  const { data } = await axios.post(`${API_URL}/${url}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const getRfqApi = async (payload: any) => {
  const {
    page = 0,
    sortField = 'created_at',
    sortOrder = 'desc',
    sizePerPage = 10,
    search,
    status,
    ...restPayload
  } = payload;
  let qs = `search=${search}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&sizePerPage=${sizePerPage}`;
  if (status) qs += `&status=${status}`;
  const { data } = await axios.get(`${API_URL}/rfq?${qs}`);

  return data;
};

export const getRfqDetailApi = async ({ id }: any) => {
  const { data } = await axios.get(`${API_URL}/rfq/${id}`);
  return data;
};
export const getRfqDetailResponsesApi = async ({ rfqRequestId }: any) => {
  const { data } = await axios.get(`${API_URL}/rfq/responses/${rfqRequestId}`);
  return data;
};

export const getImageUrl = async ({ path }: any) => {
  const { data } = await axios.get(`${API_URL}/image?path=${path}`);
  return data;
};
export const getImageUrls = async ({ paths }: any) => {
  const { data } = await axios.post(`${API_URL}/images`, { paths });
  return data;
};
export const getAudioUrl = async ({ path }: any) => {
  const { data } = await axios.get(`${API_URL}/audio?path=${path}`, {
    headers: {
      'content-type': 'audio/mpeg',
    },
  });
  return data;
};
export const getRfqNos = async () => {
  const { data } = await axios.get(`${API_URL}/rfqNos`);
  return data;
};
export const getRfqRequestNos = async () => {
  const { data } = await axios.get(`${API_URL}/rfqRequestNos`);
  return data;
};

export const cancelRfqRequestApi = async ({ id }: any) => {
  const { data } = await axios.post(`${API_URL}/rfq/cancel`, { id });
  return data;
};

export const getAllSupplierBusinessApi = async () => {
  const { data } = await axios.get(`${API_URL}/supplier-business`);
  return data?.data;
};

export const getSupplierBusinessApi = async id => {
  const { data } = await axios.get(`${API_URL}/supplier-business/${id}`);
  return data?.data;
};

export const getAllServiceProviderBusinessApi = async () => {
  const { data } = await axios.get(`${API_URL}/service-provider-business`);
  return data?.data;
};

export const getRfqResponseApi = async (payload: any) => {
  const {
    page = 0,
    sortField = 'created_at',
    sortOrder = 'desc',
    sizePerPage = 10,
    search,
    status,
    ...restPayload
  } = payload;
  let qs = `search=${search}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&sizePerPage=${sizePerPage}`;
  if (status) qs += `&status=${status}`;
  const { data } = await axios.get(`${API_URL}/rfq-response?${qs}`);
  return data;
};

export const deleteRfqResponseApi = async payload => {
  const { data } = await axios.delete(`${API_URL}/rfq-response/${payload?.id}`);
  return data;
};

export const getRfqResponseDetailApi = async ({ id }: any) => {
  const { data } = await axios.get(`${API_URL}/rfq-response/${id}`);
  return data;
};

export const getRfqResponseQuotationDetailApi = async ({ id }: any) => {
  const { data } = await axios.get(`${API_URL}/rfq-response/quotation/${id}`);
  return data;
};

export const saveRfqResponseApi = async payload => {
  const formData = jsonToFormData(payload);
  let url = 'rfq-response';
  if (payload?.id) url += `/${payload?.id}?_method=PUT`;

  const { data } = await axios.post(`${API_URL}/${url}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const getBusinessInStepApi = async () => {
  const { data } = await axios.get(`${API_URL}/supplier-business/showBusinessStep`);
  return data;
};

export const getAllCartApi = async () => {
  const { data } = await axios.get(`${API_URL}/cart`);
  return data;
};

export const getCartApi = async ({ id }: { id?: number }) => {
  const { data } = await axios.get(`${API_URL}/cart/${id}`);
  return data;
};

export const saveCartApi = async payload => {
  const { data } = await axios.post(`${API_URL}/cart`, payload);
  return data;
};

export const deleteCartApi = async payload => {
  const { data } = await axios.delete(`${API_URL}/cart/${payload?.id}`);
  return data;
};

export const getUserAddressApi = async () => {
  const { data } = await axios.get(`${API_URL}/user-address`);
  return data;
};

export const saveUserAddressApi = async payload => {
  let url = 'user-address';
  if (payload?.id) url += `/${payload?.id}?_method=PUT`;

  const { data } = await axios.post(`${API_URL}/${url}`, payload);
  return data;
};

export const deleteUserAddressApi = async payload => {
  const { data } = await axios.delete(`${API_URL}/user-address/${payload?.id}`);
  return data;
};

export const saveOrderApi = async payload => {
  let url = 'orders';
  if (payload?.id) url += `/${payload?.id}?_method=PUT`;

  const { data } = await axios.post(`${API_URL}/${url}`, payload);
  return data;
};

export const getOrderApi = async (payload?: any) => {
  const { data } = await axios.get(`${API_URL}/orders/${payload?.id}`);
  return data;
};

export const searchOrderApi = async (payload?: any) => {
  const { data } = await axios.post(`${API_URL}/orders/search`, payload);
  return data;
};
export const getPaymentModeApi = async () => {
  const { data } = await axios.get(`${API_URL}/payment-modes`);
  return data;
};

export const orderActionApi = async (payload?: any) => {
  const { data } = await axios.post(`${API_URL}/orders/action`, payload);
  return data;
};

export const getOrderTrackingStatusApi = async (payload?: any) => {
  const { data } = await axios.post(`${API_URL}/orders/getOrderTrackingStatus`, payload);
  return data;
};

export const getPartsCategoryOptionBySupplierType = async category => {
  const { data } = await axios.get(
    `${API_URL}/spare-parts-categories/getPartsCategoryOptionBySupplierType`,
    { params: { category } },
  );
  return data;
};

export const getAllNotificationApi = async (payload: { type?: string }) => {
  const { data } = await axios.get(`${API_URL}/notifications?type=${payload?.type}`);
  return data;
};

export const markAsReadNotificationApi = async id => {
  const { data } = await axios.get(`${API_URL}/notifications/${id}`);
  return data;
};
export const removeNotificationApi = async id => {
  const { data } = await axios.delete(`${API_URL}/notifications/${id}`);
  return data;
};

export const updateProfileApi = async payload => {
  const { data } = await axios.post(`${API_URL}/user/updateProfile/${payload?.id}`, payload);
  return data;
};

export const updateSupplierBusinessApi = async payload => {
  const { data } = await axios.post(`${API_URL}/supplier-business/update/${payload?.id}`, payload);
  return data;
};

export const updateSupplierBusinessFileApi = async payload => {
  const formData = jsonToFormData(payload);

  const { data } = await axios.post(
    `${API_URL}/supplier-business/update/${payload?.id}`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
        Accept: 'application/json',
      },
    },
  );
  return data;
};

export const contactUsApi = async payload => {
  const formData = jsonToFormData(payload);
  const { data } = await axios.post(`${API_URL}/contact-query`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const saveReviewApi = async (payload: any) => {
  const formData = jsonToFormData(payload);
  const { data } = await axios.post(`${API_URL}/reviews`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};
export const fetchReviewApi = async (payload: any) => {
  const { data } = await axios.get(`${API_URL}/reviewById/${payload.id}`);
  return data;
};
export const getSpecializationList = async () => {
  const { data } = await axios.get(`${API_URL}/specialization`);
  return data;
};

export const getRfqSupplierListByRfq = async id => {
  const { data } = await axios.get(`${API_URL}/rfq/getRfqSupplier/${id}`);
  return data;
};

export const getVehicleByVinNumber = async vinNumber => {
  const data = await axios.get(
    `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vinNumber}?format=json`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    },
  );
  return data;
};

export const getMakeAndModel = async search => {
  const { data } = await axios.get(`${API_URL}/make-model/${search}`);
  return data?.data;
};

export const addRfqRequestApi = async payload => {
  console.log('p', payload);
  const formData = jsonToFormData(payload);
  let url = 'rfq/add';
  const { data } = await axios.post(`${API_URL}/${url}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const getChatRoomApi = async () => {
  const { data } = await axios.get(`${API_URL}/chat`);
  return data;
};

export const getChatMessagesApi = async ({ chat_room_id }: { chat_room_id: number }) => {
  const { data } = await axios.get(`${API_URL}/chat/${chat_room_id}`);
  return data;
};

export const addChatMessageApi = async (payload: ChatMessagePayload) => {
  console.log('p', payload);
  const formData = jsonToFormData(payload);
  let url = 'chat/addNewMessage';
  const { data } = await axios.post(`${API_URL}/${url}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const uploadFileInChatMessageApi = async payload => {
  console.log('p', payload);
  const formData = jsonToFormData(payload);
  console.log('formData', formData);

  let url = 'chat/uploadFileInChatMessage';
  const { data } = await axios.post(`${API_URL}/${url}`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
      Accept: 'application/json',
    },
  });
  return data;
};

export const getPointHistoryApi = async () => {
  const { data } = await axios.get(`${API_URL}/user-points`);
  return data;
};

export const getTotalPointsApi = async () => {
  const { data } = await axios.get(`${API_URL}/user-points/getTotal`);
  return data;
};

export const redeemPointsApi = async payload => {
  const { data } = await axios.post(`${API_URL}/user-points`, payload, {
    headers: {
      'content-type': 'application/json',
      Accept: 'application/json',
    },
  });
  return data;
};
