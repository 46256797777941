import OutsideWrapper from 'components/OutsideWrapper/OutsideWrapper';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getUserSession } from 'services/auth';
import { logoutUser } from 'store/user/actions';
import './SideBar.scss';
const authNavigation = [
  {
    label: 'Login',
    path: ROUTES.LOGIN,
    protected: false,
    order: 1,
  },
  // {
  //   label: 'Sign up',
  //   path: ROUTES.SIGNUP,
  //   protected: false,
  //   order: 2,
  // },

  {
    label: 'Register business',
    path: ROUTES.REGISTER_BUSINESS,
    protected: false,
    order: 3,
  },
];
const commonNavigation = [
  // {
  //   label: 'Dashboard',
  //   path: ROUTES.DASHBOARD,
  //   protected: true,
  //   order: 1,
  // },
  {
    label: 'My orders',
    path: ROUTES.ORDERS,
    protected: true,
    order: 2,
  },

  {
    label: 'My profile',
    path: ROUTES.PROFILE,
    protected: true,
    order: 7,
  },
  // {
  //   label: ' My Chat',
  //   path: ROUTES.CHAT,
  //   protected: true,
  //   order: 8,
  // },
  {
    label: 'Contact us',
    path: ROUTES.CONTACT_US,
    protected: true,
    order: 9,
  },
];
const supplierNavigation = [
  ...commonNavigation,
  {
    label: 'RFQ Response',
    path: ROUTES.RFQ_RESPONSE_LIST,
    protected: true,
    order: 3,
  },
  {
    label: 'Add New Business',
    path: ROUTES.ADD_BUSINESS,
    protected: true,
    order: 6,
  },
];
const serviceProviderNavigation = [
  ...commonNavigation,
  {
    label: 'Search Spare part',
    path: `${ROUTES.LIST_SUPPLIER}?new=true`,
    protected: true,
    order: 3,
  },
  {
    label: 'RFQ',
    path: ROUTES.RFQ_LIST,
    protected: true,
    order: 4,
  },
  // {
  //   label: 'Create RFQ',
  //   path: `${ROUTES.LIST_SUPPLIER}?new=true`,
  //   protected: true,
  //   order: 5,
  // },
  {
    label: 'Create RFQ',
    path: `${ROUTES.ADD_RFQ}`,
    protected: true,
    order: 5,
  },
  {
    label: 'Add New Business',
    path: ROUTES.ADD_SERVICE_PROVIDER_BUSINESS,
    protected: true,
    order: 6,
  },
];

const userNavigation = [
  ...commonNavigation,
  {
    label: 'Create RFQ',
    path: `${ROUTES.LIST_SUPPLIER}?new=true`,
    protected: true,
    order: 5,
  },
  {
    label: 'RFQ list',
    path: ROUTES.RFQ_LIST,
    protected: true,
    order: 4,
  },
];
const SideBar = ({ open, sm, setOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = getUserSession();
  const ref = useRef(null);
  const { pathname } = useLocation();
  const [navigation, setNavigation] = useState(authNavigation);

  const logout = () => {
    dispatch(logoutUser(history, user?.token));
    setOpen(false);
  };
  const closeSideBar = () => setOpen(false);
  useEffect(() => {
    if (user?.roles.find(v => v.role_slug === 'supplier')) {
      setNavigation(supplierNavigation);
    }
    if (user?.roles.find(v => v.role_slug === 'service-provider')) {
      setNavigation(serviceProviderNavigation);
    }
    if (user?.roles.find(v => v.role_slug === 'user')) {
      setNavigation(userNavigation);
    }
  }, [user]);
  useEffect(() => {
    if (sm) {
      if (open) {
        ref.current.style.width = '60%';
      } else {
        ref.current.style.width = '0%';
      }
    }
  }, [open]);
  return (
    <div className={`sidebar-root ${sm ? (open ? 'd-block' : 'd-none') : 'd-block'}`}>
      <div className={'rfqMenu'}>
        <OutsideWrapper handleOutsideClick={closeSideBar}>
          <div ref={ref} id="mySidenav" className={`${sm ? 'sidenav' : ''}`}>
            {sm && user && (
              <div className="dropdown-item text-white d-flex align-items-center">
                <div className="dropdown-menu-avatar">
                  <span className="text-white">{(user?.name).slice(0, 1)}</span>
                </div>
                <div className="d-flex flex-column">
                  <span className="text-white">{user?.name}</span>
                  <span className="text-white" style={{ fontSize: 11 }}>
                    {user?.phoneCode}-
                    {user?.phoneNumber && [...Array(user?.phoneNumber?.length - 4)].map(v => '*')}
                    {user?.phoneNumber && user?.phoneNumber.slice(-4)}
                  </span>
                </div>
              </div>
            )}
            <ul className="nav nav-pills flex-column" id="myTab" role="tablist">
              {navigation
                .sort((a, b) => a.order - b.order)
                .map(v => (
                  <li className="nav-item" key={`${v.path}-${v.order}`}>
                    <Link
                      to={v.path}
                      className={`nav-link ${pathname === v.path && 'active'} ${
                        !sm ? 'text-white' : ''
                      }`}>
                      {v.label}
                    </Link>
                  </li>
                ))}
            </ul>
            {sm && user && (
              <ul className="logout-container">
                <hr className="dropdown-divider"></hr>
                <li className="nav-item" key={'logout-btn-link'}>
                  <Button
                    style={{ textTransform: 'capitalize' }}
                    className=""
                    variant="flat"
                    onClick={logout}>
                    <span className="text-secondary">Logout</span>
                  </Button>
                </li>
              </ul>
            )}
          </div>
        </OutsideWrapper>
      </div>
    </div>
  );
};

export default SideBar;
