import { find as _find } from 'lodash';
import { createSelector } from 'reselect';

import { RootReducer } from '../rootReducer';

// import { countries } from 'pages/Signup/constants';

export const cartCount = createSelector(
  (state: RootReducer) => state.cart,
  ({ data }) => ({ cartItemCount: data?.length }),
);

export const cartIds = createSelector(
  (state: RootReducer) => state.cart,
  ({ data }) => ({ cartIdList: data?.map(v => v?.rfqResponseId) }),
);
