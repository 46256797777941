import { IAction } from 'store/app/interfaces';
import {
  SAVE_RFQ_RESPONSE,
  SAVE_RFQ_RESPONSE_SUCCESS,
  SAVE_RFQ_RESPONSE_FAILED,
  GET_RFQ_RESPONSE,
  GET_RFQ_RESPONSE_SUCCESS,
  GET_RFQ_RESPONSE_FAILED,
  GET_RFQ_RESPONSE_DETAIL,
  GET_RFQ_RESPONSE_DETAIL_SUCCESS,
  GET_RFQ_RESPONSE_DETAIL_FAILED,
  DELETE_RFQ_RESPONSE,
  DELETE_RFQ_RESPONSE_SUCCESS,
  DELETE_RFQ_RESPONSE_FAILED,
  GET_RFQ_RESPONSE_QUOTATION_DETAIL,
  GET_RFQ_RESPONSE_QUOTATION_DETAIL_SUCCESS,
  GET_RFQ_RESPONSE_QUOTATION_DETAIL_FAILED,
} from './actions';
import { IRfqResponseReducer } from './interfaces';

const initialState: IRfqResponseReducer = {
  rfqResponse: {
    data: null,
    error: null,
    loading: null,
  },
  rfqResponseList: {
    data: null,
    error: null,
    loading: null,
  },
  rfqResponseDetail: {
    data: null,
    error: null,
    loading: null,
  },
  rfqResponseDetailQuotation: {
    data: null,
    error: null,
    loading: null,
  },
};

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case SAVE_RFQ_RESPONSE:
      return {
        ...state,
        rfqResponse: {
          ...state.rfqResponse,
          data: null,
          error: null,
          loading: true,
        },
      };
    case SAVE_RFQ_RESPONSE_SUCCESS:
      return {
        ...state,
        rfqResponse: {
          ...state.rfqResponse,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case SAVE_RFQ_RESPONSE_FAILED:
      return {
        ...state,
        rfqResponse: {
          ...state.rfqResponse,
          error: action.payload,
          data: null,
          loading: false,
        },
      };
    case GET_RFQ_RESPONSE:
      return {
        ...state,
        rfqResponseList: {
          ...state.rfqResponseList,
          loading: true,
        },
      };
    case GET_RFQ_RESPONSE_SUCCESS:
      return {
        ...state,
        rfqResponseList: {
          ...state.rfqResponseList,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case GET_RFQ_RESPONSE_FAILED:
      return {
        ...state,
        rfqResponseList: {
          ...state.rfqResponseList,
          error: action.payload,
          data: null,
          loading: false,
        },
      };

    case GET_RFQ_RESPONSE_DETAIL:
      return {
        ...state,
        rfqResponseDetail: {
          ...state.rfqResponseDetail,
          loading: true,
        },
      };
    case GET_RFQ_RESPONSE_DETAIL_SUCCESS:
      return {
        ...state,
        rfqResponseDetail: {
          ...state.rfqResponseDetail,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case GET_RFQ_RESPONSE_DETAIL_FAILED:
      return {
        ...state,
        rfqResponseDetail: {
          ...state.rfqResponseDetail,
          error: action.payload,
          data: null,
          loading: false,
        },
      };

    case GET_RFQ_RESPONSE_QUOTATION_DETAIL:
      return {
        ...state,
        rfqResponseDetailQuotation: {
          ...state.rfqResponseDetailQuotation,
          loading: true,
        },
      };
    case GET_RFQ_RESPONSE_QUOTATION_DETAIL_SUCCESS:
      return {
        ...state,
        rfqResponseDetailQuotation: {
          ...state.rfqResponseDetailQuotation,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case GET_RFQ_RESPONSE_QUOTATION_DETAIL_FAILED:
      return {
        ...state,
        rfqResponseDetailQuotation: {
          ...state.rfqResponseDetailQuotation,
          error: action.payload,
          data: null,
          loading: false,
        },
      };

    case DELETE_RFQ_RESPONSE:
      return {
        ...state,
        rfqResponseDetailQuotation: {
          ...state.rfqResponseDetailQuotation,
          loading: true,
        },
      };
    case DELETE_RFQ_RESPONSE_SUCCESS: {
      return {
        ...state,
        rfqResponseDetailQuotation: {
          ...state.rfqResponseDetailQuotation,
          data: state?.rfqResponseDetailQuotation?.data?.filter(
            v => v?.id !== parseInt(action.payload?.id),
          ),
          error: null,
          loading: false,
        },
      };
    }
    case DELETE_RFQ_RESPONSE_FAILED:
      return {
        ...state,
        rfqResponse: {
          ...state.rfqResponse,
          error: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};
