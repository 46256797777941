import { IAction } from 'store/app/interfaces';
import { SignupPayload } from 'store/user/interfaces';
import {
  SEARCH_SUPPLIER_BUSINESS,
  SEARCH_SUPPLIER_BUSINESS_SUCCESS,
  SEARCH_SUPPLIER_BUSINESS_FAILED,
  RESET_SEARCH_SUPPLIER_BUSINESS,
  SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE,
  SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE_SUCCESS,
  SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE_FAILED,
  SUPPLIER_BUSINESS_DETAIL,
  SUPPLIER_BUSINESS_DETAIL_SUCCESS,
  SELECT_SUPPLIER_BUSINESS,
  SET_RFQ_SELECTION_PREFERENCE,
  RESET_SELECTED_SUPPLIER_BUSINESS,
} from './actions';
import { ISupplierReducer } from './interfaces';

const initialState: ISupplierReducer = {
  data: null,
  error: null,
  loading: false,
  search: null,
  suppliers: {
    data: null,
    error: null,
    loading: false,
  },

  supplier: {
    data: null,
    error: null,
    loading: false,
  },
  selectedSupplier: [],
  autoRfq: true,
};

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case SEARCH_SUPPLIER_BUSINESS:
      return {
        ...state,
        search: action.payload,
        loading: true,
        selectedSupplier:
          state.search?.category !== action?.payload?.category ? [] : state.selectedSupplier,
      };
    case SEARCH_SUPPLIER_BUSINESS_SUCCESS:
      return {
        ...state,
        suppliers: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case SEARCH_SUPPLIER_BUSINESS_FAILED:
      return {
        ...state,
        suppliers: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };

    case SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE:
      return {
        ...state,
        search: action.payload,
        loading: true,
      };
    case SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        suppliers: {
          data: {
            ...action.payload,
            data: {
              ...action?.payload?.data,
              data: [...state?.suppliers?.data?.data?.data, ...action.payload?.data?.data],
            },
          },
          error: null,
          loading: false,
        },
      };
    case SEARCH_SUPPLIER_BUSINESS_NEXT_PAGE_FAILED:
      return {
        ...state,
        suppliers: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    case SUPPLIER_BUSINESS_DETAIL:
      return {
        ...state,
        supplier: {
          error: null,
          loading: true,
        },
      };
    case SUPPLIER_BUSINESS_DETAIL_SUCCESS:
      return {
        ...state,
        supplier: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case SUPPLIER_BUSINESS_DETAIL_SUCCESS:
      return {
        ...state,
        supplier: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };

    case SELECT_SUPPLIER_BUSINESS:
      return {
        ...state,
        selectedSupplier: action.payload,
      };
    case SET_RFQ_SELECTION_PREFERENCE:
      return {
        ...state,
        selectedSupplier: [],
        autoRfq: action.payload,
      };
    case RESET_SEARCH_SUPPLIER_BUSINESS:
      return {
        ...state,
        suppliers: initialState.suppliers,
        search: initialState.search,
      };

    case RESET_SELECTED_SUPPLIER_BUSINESS:
      return {
        ...state,
        selectedSupplier: initialState.selectedSupplier,
        autoRfq: initialState.autoRfq,
      };

    default:
      return state;
  }
};
