import { find as _find } from 'lodash';
import { createSelector } from 'reselect';

import { RootReducer } from '../rootReducer';

// import { countries } from 'pages/Signup/constants';

export const countrySelector = createSelector(
  (state: RootReducer) => state.app,
  ({ country: { data } }) => ({ country: data }),
);

export const provinceSelector = createSelector(
  (state: RootReducer) => state.app,
  ({ province }) => ({ ...province }),
);

export const locationSelector = createSelector(
  (state: RootReducer) => state.app,
  ({ province, currentLocation }) => ({ ...province, currentLocation }),
);
export const citiesSelector = createSelector(
  (state: RootReducer) => state.app,
  ({ cities, currentLocation }) => ({ ...cities, currentLocation }),
);
export const countryCodeSelector = createSelector(
  (state: RootReducer) => state.app,
  ({ countryCode: { data, loading } }) => ({ countryCode: data, loading }),
);
