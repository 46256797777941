import {
  GET_ALL_SUPPLIER_BUSINESS,
  GET_ALL_SUPPLIER_BUSINESS_SUCCESS,
  GET_ALL_SUPPLIER_BUSINESS_FAILED,
  SAVE_BUSINESS_IN_STEP,
  SAVE_BUSINESS_IN_STEP_SUCCESS,
  SAVE_BUSINESS_IN_STEP_FAILED,
  RESET_BUSINESS_IN_STEP_FORM,
  GET_BUSINESS_IN_STEP,
  GET_BUSINESS_IN_STEP_FAILED,
  GET_BUSINESS_IN_STEP_SUCCESS,
  SAVE_SUPPLIER_BUSINESS,
  SAVE_SUPPLIER_BUSINESS_SUCCESS,
  SAVE_SUPPLIER_BUSINESS_FAILED,
  UPDATE_SUPPLIER_BUSINESS,
  UPDATE_SUPPLIER_BUSINESS_SUCCESS,
  UPDATE_SUPPLIER_BUSINESS_FAILED,
  SAVE_SERVICE_PROVIDER_BUSINESS,
  SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  SAVE_SERVICE_PROVIDER_BUSINESS_FAILED,
  ADD_NEW_SERVICE_PROVIDER_BUSINESS,
  ADD_NEW_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  ADD_NEW_SERVICE_PROVIDER_BUSINESS_FAILED,
} from './actions';
import { IBusinessReducer } from './interfaces';

const initialState: IBusinessReducer = {
  data: null,
  error: null,
  loading: false,
  showSuccessRegistrationMsg: false,
  businesses: {
    data: [],
    error: null,
    loading: false,
  },
  completedStep: {
    data: { step1: null, step2: null, step3: null, step4: null, userId: null, id: null },
    loading: false,
    error: null,
    stepLoading: false,
  },
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_SUPPLIER_BUSINESS:
      return {
        ...state,
        businesses: {
          error: null,
          loading: true,
        },
      };
    case GET_ALL_SUPPLIER_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_ALL_SUPPLIER_BUSINESS_FAILED:
      return {
        ...state,
        businesses: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    case SAVE_BUSINESS_IN_STEP:
      return {
        ...state,
        completedStep: {
          ...state.completedStep,
          stepLoading: true,
        },
      };
    case SAVE_BUSINESS_IN_STEP_SUCCESS:
      return {
        ...state,
        completedStep: {
          ...state.completedStep,
          data: {
            ...state.completedStep.data,
            [`step${action.payload.step}`]: action.payload,
            id: action?.payload?.id,
            userId: action?.payload?.userId,
          },
          error: null,
          stepLoading: false,
        },
      };
    case SAVE_BUSINESS_IN_STEP_FAILED:
      return {
        ...state,
        completedStep: {
          ...state.completedStep,
          error: action.payload,
          stepLoading: false,
        },
      };
    case GET_BUSINESS_IN_STEP:
      return {
        ...state,
        completedStep: {
          ...state.completedStep,
          stepLoading: false,
          error: null,
          data: null,
          loading: true,
        },
      };
    case GET_BUSINESS_IN_STEP_SUCCESS:
      return {
        ...state,
        completedStep: {
          ...state.completedStep,
          data: action?.payload,
          error: null,
          loading: false,
        },
      };

    case GET_BUSINESS_IN_STEP_FAILED:
      return {
        ...state,
        completedStep: {
          ...state.completedStep,
          error: action?.payload,
          data: null,
          loading: false,
        },
      };

    case RESET_BUSINESS_IN_STEP_FORM:
      return { ...state, completedStep: initialState.completedStep };

    case SAVE_SUPPLIER_BUSINESS:
      return {
        ...state,
        loading: true,
      };
    case SAVE_SUPPLIER_BUSINESS_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload,
        loading: false,
      };
    case SAVE_SUPPLIER_BUSINESS_FAILED:
      return {
        ...state,
        data: null,
        error: action.payload,
        loading: false,
      };
    case ADD_NEW_SERVICE_PROVIDER_BUSINESS:
      return {
        ...state,
        loading: true,
      };
    case ADD_NEW_SERVICE_PROVIDER_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: {
          ...state.businesses,
          data: [action.payload, ...state.businesses.data],
          loading: false,
          error: null,
        },
        showSuccessRegistrationMsg: true,
      };
    case ADD_NEW_SERVICE_PROVIDER_BUSINESS_FAILED:
      return {
        ...state,
        businesses: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };

    case SAVE_SERVICE_PROVIDER_BUSINESS:
      return {
        ...state,
        loading: true,
      };
    case SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload,
        loading: false,
      };
    case SAVE_SERVICE_PROVIDER_BUSINESS_FAILED:
      return {
        ...state,
        data: null,
        error: action.payload,
        loading: false,
      };
    case UPDATE_SUPPLIER_BUSINESS:
      return {
        ...state,
        businesses: {
          ...state.businesses,
          // loading: true,
        },
      };
    case UPDATE_SUPPLIER_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: {
          ...state.businesses,
          data: state?.businesses?.data?.map(v =>
            v?.id === action.payload?.id ? { ...v, ...action?.payload } : v,
          ),
          loading: false,
        },
      };
    case UPDATE_SUPPLIER_BUSINESS_FAILED:
      return {
        ...state,
        ...state,
        businesses: {
          ...state.businesses,
          error: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};
