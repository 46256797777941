import { joiResolver } from '@hookform/resolvers/joi';
import CustomModal from 'components/CustomModal/CustomModal';
import CustomToolTip from 'components/CustomToolTip/CustomToolTip';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import Input from 'components/Input/Input';
import ListLoader from 'components/ListLoader/ListLoader';
import dayjs from 'dayjs';
import { useModal } from 'hooks/useModal';
import { useEffect } from 'react';
import { Badge, Button, Card, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getPointHistory, getTotalPoint, redeemPoints } from 'store/pointHistory/actions';
import {
  pointsHistorySelector,
  redeemPointStateSelector,
  totalPointSelector,
} from 'store/pointHistory/selectors';
import { userSelector } from 'store/user/selectors';
const badgeColor = {
  EARNED: 'success',
  PROCESSING: 'warning',
  ADDON: 'info',
  REDEEMED: 'danger',
};
const PointHistory = () => {
  const dispatch = useDispatch();
  const {
    data: { data, ...pagination },
    loading,
    error,
  } = useSelector(pointsHistorySelector);
  const { user } = useSelector(userSelector);
  const { data: totalPoints } = useSelector(totalPointSelector);
  const {
    data: redeemPointData,
    loading: redeemPointLoading,
    error: redeemPointFieldError,
  } = useSelector(redeemPointStateSelector);
  const { open, onOpen, onClose } = useModal();
  const { handleSubmit, control, setError } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      points: null,
    },
  });

  const handleChange = (handler: any) => (e: any) => {
    if (e.target.value <= totalPoints) {
      handler(e);
      setError('points', null);
    } else {
      setError('points', { message: `You can redeem maximum ${totalPoints}` });
    }
  };

  const onSubmit = ({ points }: any) => {
    dispatch(
      redeemPoints({
        points: -points,
        status: 'PROCESSING',
        comments: `${points} has been deducted`,
        user_id: user.id,
      }),
    );
    onClose();
  };
  const handleRefresh = e => {
    dispatch(getPointHistory());
    dispatch(getTotalPoint());
  };
  const handleOpenRedeemForm = e => {
    dispatch(getTotalPoint());
    onOpen();
  };
  useEffect(() => {
    handleRefresh({});
  }, []);

  return (
    <DashboardLayout>
      <Card className="mt-2">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h5>
            Remaining points: <span className="text-bold">{totalPoints}</span>
          </h5>
          <div className="d-flex">
            <Button variant="outline-primary" className="btn-sm" onClick={handleRefresh}>
              <i className="fa fa-refresh" />
            </Button>
            <Button
              variant="outline-secondary ms-1"
              className="btn-sm"
              onClick={handleOpenRedeemForm}>
              Redeem
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <h5>Point transaction history</h5>
          {loading ? (
            <ListLoader
              count={5}
              containerStyle={{ padding: 0 }}
              leftContentStyle={{ height: 63, width: 50, flexBasis: '12%' }}
              hideLine3={true}
            />
          ) : (
            data?.map((v: any) => (
              <div key={v?.id} className="d-flex  mb-2">
                <div
                  className={`${
                    v?.status === 'EARNED' || v?.status === 'ADDON' ? 'bg-success' : 'bg-danger'
                  } p-2 d-flex align-items-center`}
                  style={{ borderRadius: 10 }}>
                  <h4 className="text-white">
                    {v?.status === 'EARNED' || v?.status === 'ADDON' ? '+' : ''}
                    {v?.points} Pts
                  </h4>
                </div>
                <div className="d-flex justify-content-between flex-wrap" style={{ flex: 1 }}>
                  <div className="d-flex flex-column ms-2">
                    <span className="text-lightgrey">{v?.comments}</span>
                    <div className="d-flex align-items-center">
                      <Badge bg={badgeColor[v?.status]} style={{ maxWidth: 100 }}>
                        {v?.status}
                      </Badge>
                      {v?.status === 'PROCESSING' && (
                        <CustomToolTip
                          toolTipContent={<i className="fa fa-info-circle ms-1 text-black"></i>}>
                          <span className="font-size-11 text-grey">
                            you will get your rewards within 3 to 5 business days
                          </span>
                        </CustomToolTip>
                      )}
                    </div>
                  </div>
                  <span className="text-grey font-size-11 p-1 ps-2">
                    <i className="fa fa-clock-o"></i>{' '}
                    {dayjs(v?.created_at).format('hh:mmA, DD MMM YY')}
                  </span>
                </div>
              </div>
            ))
          )}
        </Card.Body>
      </Card>

      <CustomModal
        open={open}
        onCancel={onClose}
        closeModal={onClose}
        submitButtonText="Redeem"
        noFooter
        centered>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-center justify-content-between">
            <Controller
              name="points"
              control={control}
              rules={{
                required: { value: true, message: 'Redeem points is required' },
              }}
              render={({
                field: { onChange, ...rest },
                fieldState: { error: redeemPointError },
              }) => (
                <div className="d-flex flex-column">
                  <Form.Label>Enter points</Form.Label>
                  <Form.Control
                    {...rest}
                    type="number"
                    onChange={handleChange(onChange)}
                    placeholder="Enter point to redeem"
                    required
                  />
                  {redeemPointError?.message && (
                    <span className="font-size-11 mt-1 text-danger">
                      {redeemPointError.message}
                    </span>
                  )}
                </div>
              )}
            />
            <div style={{ marginTop: 30 }}>
              <Button variant="secondary" type="submit" disabled={redeemPointLoading}>
                {redeemPointLoading ? 'Saving...' : ' Redeem'}
              </Button>
            </div>
          </div>
        </Form>
      </CustomModal>
    </DashboardLayout>
  );
};

export default PointHistory;
