import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import { rootSaga } from './rootSaga';

import { persistReducer, persistStore } from 'redux-persist';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createFilter from 'redux-persist-transform-filter';

// const transform = createTransform(
//   // hydration (inbound)
//   (inboundState: ISupplierReducer, key) => {
//     const { supplier, suppliers, data, error, loading, ...restOfInboundState } = inboundState;
//     return restOfInboundState;
//   },
//   // rehydration (outbound)
//   (outboundState, key) => {
//     return { ...outboundState };
//   },
//   { whitelist: ['supplierBusiness'] },
// );

const supplierBusinessPersist = createFilter('supplierBusiness', [
  'search',
  'selectedSupplier',
  'autoRfq',
]);
const businessPersist = createFilter('business', ['completedStep']);
const userPersist = createFilter('user', ['user']);
const persistConfig = {
  // configuration object for redux-persist
  key: 'root',
  storage: localforage, // define which storage to use
  whitelist: ['supplierBusiness', 'business', 'user'],
  stateReconciler: autoMergeLevel2,
  transforms: [supplierBusinessPersist, businessPersist, userPersist],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
if (
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_BASE_URL.indexOf('stage') !== -1
) {
  const { logger } = require('redux-logger');

  middlewares.push(logger);
}
// Mount it on the Store
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));

// Run the saga
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
