import { IAction } from 'store/app/interfaces';
import {
  SAVE_RFQ_REQUEST,
  SAVE_RFQ_REQUEST_SUCCESS,
  SAVE_RFQ_REQUEST_FAILED,
  GET_RFQ_REQUEST,
  GET_RFQ_REQUEST_SUCCESS,
  GET_RFQ_REQUEST_FAILED,
  GET_RFQ_REQUEST_DETAIL,
  GET_RFQ_REQUEST_DETAIL_SUCCESS,
  GET_RFQ_REQUEST_DETAIL_FAILED,
  CANCEL_RFQ_REQUEST,
  CANCEL_RFQ_REQUEST_SUCCESS,
  CANCEL_RFQ_REQUEST_FAILED,
  GET_RFQ_REQUEST_DETAIL_RESPONSES,
  GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS,
  GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED,
  ADD_RFQ_REQUEST,
  ADD_RFQ_REQUEST_SUCCESS,
  ADD_RFQ_REQUEST_FAILED,
} from './actions';
import { IRfqReducer } from './interfaces';

const initialState: IRfqReducer = {
  rfq: {
    data: null,
    error: null,
    loading: null,
  },
  rfqList: {
    data: null,
    error: null,
    loading: null,
  },
  rfqDetail: {
    data: null,
    error: null,
    loading: null,
  },
  rfqDetailResponses: {
    data: null,
    error: null,
    loading: null,
  },
};

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case SAVE_RFQ_REQUEST:
      return {
        ...state,
        rfq: {
          ...state.rfq,
          data: null,
          error: null,
          loading: true,
        },
      };
    case SAVE_RFQ_REQUEST_SUCCESS:
      return {
        ...state,
        rfq: {
          ...state.rfq,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case SAVE_RFQ_REQUEST_FAILED:
      return {
        ...state,
        rfq: {
          ...state.rfq,
          error: action.payload,
          data: null,
          loading: false,
        },
      };
    case GET_RFQ_REQUEST:
      return {
        ...state,
        rfqList: {
          ...state.rfqList,
          loading: true,
        },
      };
    case GET_RFQ_REQUEST_SUCCESS:
      return {
        ...state,
        rfqList: {
          ...state.rfqList,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case GET_RFQ_REQUEST_FAILED:
      return {
        ...state,
        rfqList: {
          ...state.rfqList,
          error: action.payload,
          data: null,
          loading: false,
        },
      };

    case GET_RFQ_REQUEST_DETAIL_RESPONSES:
      return {
        ...state,
        rfqDetailResponses: {
          ...state.rfqDetailResponses,
          loading: true,
        },
      };
    case GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS:
      return {
        ...state,
        rfqDetailResponses: {
          ...state.rfqDetailResponses,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED:
      return {
        ...state,
        rfqDetailResponses: {
          ...state.rfqDetailResponses,
          error: action.payload,
          data: null,
          loading: false,
        },
      };

    case GET_RFQ_REQUEST_DETAIL:
      return {
        ...state,
        rfqDetail: {
          ...state.rfqDetail,
          loading: true,
        },
      };
    case GET_RFQ_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        rfqDetail: {
          ...state.rfqDetail,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case GET_RFQ_REQUEST_DETAIL_FAILED:
      return {
        ...state,
        rfqDetail: {
          ...state.rfqDetail,
          error: action.payload,
          data: null,
          loading: false,
        },
      };
    case CANCEL_RFQ_REQUEST:
      return {
        ...state,
        rfqList: {
          ...state.rfqList,
          loading: true,
        },
      };
    case CANCEL_RFQ_REQUEST_SUCCESS:
      return {
        ...state,
        rfqList: {
          ...state.rfqList,
          error: null,
          data: {
            ...state?.rfqList?.data,
            data: state?.rfqList?.data?.data
              ? state?.rfqList?.data?.data.map(v =>
                  action.payload?.id === v?.id
                    ? {
                        ...v,
                        rfqRequestStatus: 4,
                      }
                    : { ...v },
                )
              : [],
          },
          loading: false,
        },
      };
    case CANCEL_RFQ_REQUEST_FAILED:
      return {
        ...state,
        rfqList: {
          ...state.rfqList,
          error: action.payload,
          data: null,
          loading: false,
        },
      };

    case ADD_RFQ_REQUEST:
      return {
        ...state,
        rfq: {
          ...state.rfq,
          data: null,
          error: null,
          loading: true,
        },
      };
    case ADD_RFQ_REQUEST_SUCCESS:
      return {
        ...state,
        rfq: {
          ...state.rfq,
          error: null,
          data: action.payload,
          loading: false,
        },
      };
    case ADD_RFQ_REQUEST_FAILED:
      return {
        ...state,
        rfq: {
          ...state.rfq,
          error: action.payload,
          data: null,
          loading: false,
        },
      };
    default:
      return state;
  }
};
