import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  getBusinessInStepFailed,
  getBusinessInStepSuccess,
  GET_BUSINESS_IN_STEP,
  resetBusinessInStepForm,
  saveBusinessInStepFailed,
  saveBusinessInStepSuccess,
  SAVE_BUSINESS_IN_STEP,
  GET_ALL_SUPPLIER_BUSINESS,
  getAllSupplierBusinessSuccess,
  getAllSupplierBusinessFailed,
  saveSupplierBusinessFailed,
  saveSupplierBusinessSuccess,
  SAVE_SUPPLIER_BUSINESS,
  SAVE_SUPPLIER_BUSINESS_SUCCESS,
  UPDATE_SUPPLIER_BUSINESS,
  updateSupplierBusinessSuccess,
  updateSupplierBusinessFailed,
  addNewServiceProviderBusinessFailed,
  addNewServiceProviderBusinessSuccess,
  ADD_NEW_SERVICE_PROVIDER_BUSINESS,
  ADD_NEW_SERVICE_PROVIDER_BUSINESS_SUCCESS,
} from './actions';

import {
  getAllSupplierBusinessApi,
  getBusinessInStepApi,
  saveSupplierBusinessApi,
  saveBusinessInStepApi,
  whoAmIApi,
  getAllServiceProviderBusinessApi,
  updateSupplierBusinessApi,
  updateSupplierBusinessFileApi,
  addNewServiceProviderBusinessApi,
} from 'services/api';
import { ActionType, ActionTypeWithCallback, ActionTypeWithHistory } from 'store/rootReducer';
import { IResponse } from 'store/app/interfaces';
import { logError, updateUser } from 'store/user/actions';
import { addNotification } from 'services/notifications';
import { getUserSession, saveUserSession } from 'services/auth';
import { ROUTES } from 'routes/constants';
import { appNotification } from 'store/app/actions';

function* getAllSupplierBusinessSaga({ payload }: ActionType) {
  try {
    let data: IResponse;
    console.log('payload', payload);

    if (payload === 'service-provider') data = yield call(getAllServiceProviderBusinessApi);
    if (payload === 'supplier') data = yield call(getAllSupplierBusinessApi);

    yield put(getAllSupplierBusinessSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(getAllSupplierBusinessFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(getAllSupplierBusinessFailed(errors));
    }
  }
}

function* saveBusinessInStepSaga({
  payload: { payload, onSuccess, onError },
}: ActionTypeWithCallback) {
  try {
    const data: IResponse = yield call(saveBusinessInStepApi, payload);
    console.log('data api', data);

    yield put(saveBusinessInStepSuccess({ ...payload, id: data?.data?.id }));
    if (onSuccess)
      yield call(() => {
        onSuccess(data);
      });
  } catch (error: any) {
    yield put(logError(error));
    if (onError)
      yield call(() => {
        onError(error?.response);
      });
    console.log('error', error);

    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(saveBusinessInStepFailed({ message, step: payload?.step }));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);

      yield put(saveBusinessInStepFailed({ ...errors, step: payload?.step }));
    }
  }
}

function* getBusinessInStepSaga({ payload }: ActionType) {
  try {
    const { data, status, message }: IResponse = yield call(getBusinessInStepApi);
    const result = {
      id: data?.id,
      userId: data?.userId,
      step1: data?.step1 ? JSON.parse(data?.step1) : null,
      step2: data?.step2 ? JSON.parse(data?.step2) : null,
      step3: data?.step3 ? JSON.parse(data?.step3) : null,
      step4: data?.step4 ? JSON.parse(data?.step4) : null,
    };
    yield put(getBusinessInStepSuccess(result));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(getBusinessInStepFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(getBusinessInStepFailed(errors));
    }
  }
}

function* saveSupplierBusinessSaga({ payload }: ActionTypeWithHistory) {
  try {
    const data: Response = yield call(saveSupplierBusinessApi, payload?.payload);
    yield put(saveSupplierBusinessSuccess({ ...data, history: payload?.history }));
  } catch (error: any) {
    yield put(logError(error));
    console.log('error', error?.message);

    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(saveSupplierBusinessFailed(message));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);

      yield put(saveSupplierBusinessFailed(errors));
    }
  }
}

function* saveSupplierBusinessSuccessSaga({ payload }: ActionTypeWithHistory) {
  try {
    const response: IResponse = yield call(whoAmIApi);
    const user = getUserSession();
    const result = { ...user, ...response?.data };
    yield put(updateUser(result));
    yield put(resetBusinessInStepForm());
    yield call(() => {
      saveUserSession(result);
    });
    payload.history.push(ROUTES.HOME);
    yield put(
      appNotification({ type: 'success', message: 'Business has been successfully added' }),
    );
  } catch (error: any) {
    yield put(logError(error));
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(saveSupplierBusinessFailed(message));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);

      yield put(saveSupplierBusinessFailed(errors));
    }
  }
}

function* addNewServiceProviderBusinessSaga({
  payload: { payload, onSuccess, onError },
}: ActionTypeWithCallback) {
  try {
    const data = yield call(addNewServiceProviderBusinessApi, payload);
    if (data?.status === 'Error') {
      if (onError) onError(data?.errors);
      yield put(addNewServiceProviderBusinessFailed(data?.errors));
    } else {
      if (onSuccess) onSuccess(data?.data);
      yield put(addNewServiceProviderBusinessSuccess({ ...data?.data }));
    }
  } catch (error: any) {
    console.log('error', error?.message);

    if (error?.response?.status != 422) {
      let message = 'Oops something went wrong';
      if (onError) onError(error?.response?.data?.message || message);
      yield put(addNewServiceProviderBusinessFailed(message));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);

      yield put(addNewServiceProviderBusinessFailed(errors));
    }
  }
}

function* updateSupplierBusinessSaga({ payload }: ActionType) {
  try {
    const api = payload?.isFile ? updateSupplierBusinessFileApi : updateSupplierBusinessApi;
    console.log('payload?.isFile', payload?.isFile);
    const data: IResponse = yield call(api, payload);

    yield put(updateSupplierBusinessSuccess(data?.data));
  } catch (error: any) {
    yield put(appNotification({ type: 'danger', message: error?.message }));

    console.log('error', error);

    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(updateSupplierBusinessFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);

      yield put(updateSupplierBusinessFailed({ ...errors }));
    }
  }
}

function* businessSaga() {
  yield all([
    takeLatest(GET_ALL_SUPPLIER_BUSINESS, getAllSupplierBusinessSaga),
    takeLatest(SAVE_BUSINESS_IN_STEP, saveBusinessInStepSaga),
    takeLatest(GET_BUSINESS_IN_STEP, getBusinessInStepSaga),
    takeLatest(SAVE_SUPPLIER_BUSINESS, saveSupplierBusinessSaga),
    takeLatest(SAVE_SUPPLIER_BUSINESS_SUCCESS, saveSupplierBusinessSuccessSaga),
    takeLatest(UPDATE_SUPPLIER_BUSINESS, updateSupplierBusinessSaga),
    takeLatest(ADD_NEW_SERVICE_PROVIDER_BUSINESS, addNewServiceProviderBusinessSaga),
  ]);
}

export default businessSaga;
