import io from 'socket.io-client';
import Echo from 'laravel-echo';

declare global {
  interface Window {
    io;
    Pusher;
    Echo: Echo;
  }
}
window.Pusher = require('pusher-js');
window.io = io;

export function createSocketConnection(token: string) {
  try {
    if (!window.Echo) {
      // window.Echo = new Echo({
      //   broadcaster: 'socket.io',
      //   wsHost: window.location.hostname,
      //   wsPort: 6001,
      //   transports: ['websocket', 'polling', 'flashsocket'],
      //   auth: {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   },
      // });

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_API_KEY,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        forceTLS: false,
        encrypted: false,
        wsHost: window.location.hostname,
        // wssHost: window.location.hostname,
        wsPort: 6001,
        // wssPort: 6001,
        authEndpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            // 'Content-Type': 'application/json',
          },
        },
        // enabledTransports: ['ws'],
      });
    }
  } catch (error) {
    console.log('socket error', error);
  }
}
