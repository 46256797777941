import { ReactNode, useEffect, useRef } from 'react';

interface IProps {
	handleOutsideClick: () => void;
	children: ReactNode;
}

const OutsideWrapper = ({ handleOutsideClick, children }: IProps) => {
	const wrapperRef = useRef(null);
	useEffect(() => {
		const handleClickOutside = event => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				handleOutsideClick();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleOutsideClick, wrapperRef]);
	return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideWrapper;
