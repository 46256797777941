import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  saveCartSuccess,
  getCartSuccess,
  GET_CART,
  SAVE_CART,
  getCartFailed,
  saveCartFailed,
  deleteCartSuccess,
  deleteCartFailed,
  REMOVE_CART,
} from './actions';

import i18n from 'config/i18n';
import { deleteCartApi, getAllCartApi, getCartApi, saveCartApi } from 'services/api';
import { addNotification } from 'services/notifications';
import { ActionType, ActionTypeWithCallback } from 'store/rootReducer';
import { logError } from 'store/user/actions';
import { IResponse } from 'store/app/interfaces';
import { appNotification } from 'store/app/actions';

function* getCartSaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(getAllCartApi);
    const { data: result } = data;
    yield put(getCartSuccess(result));
  } catch (e: any) {
    yield put(getCartFailed(e?.response?.data));
  }
}

function* saveCartSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response: IResponse = yield call(saveCartApi, payload);
    if (response?.status === 'Success') {
      if (onSuccess) onSuccess(response?.data);
      yield put(saveCartSuccess(response?.data));
    } else {
      yield put(appNotification({ type: 'danger', message: response?.message }));
    }
  } catch (e: any) {
    if (onError) onError(e?.response);
    yield put(saveCartFailed(e?.response?.data));
  }
}

function* deleteCartSaga({ payload }: ActionType) {
  try {
    const response: IResponse = yield call(deleteCartApi, payload);
    if (response?.status === 'Success') yield put(deleteCartSuccess(response?.data));
    else yield put(deleteCartFailed(response?.message));
  } catch (e: any) {
    yield put(deleteCartFailed(e?.response?.data));
  }
}
function* cartSaga() {
  yield all([
    takeLatest(GET_CART, getCartSaga),
    takeLatest(SAVE_CART, saveCartSaga),
    takeLatest(REMOVE_CART, deleteCartSaga),
  ]);
}

export default cartSaga;
