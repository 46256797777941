declare global {
  interface Window {
    notifyReact;
  }
}
window.notifyReact = function ({ data, type }) {
  if (window && window.postMessage) {
    window.postMessage(JSON.stringify({ data, type }));
  }
};
export const notifyReact = (data, type) => window && window.notifyReact({ data, type });
