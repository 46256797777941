export const GET_POINT_HISTORY = 'GET_POINT_HISTORY';
export const GET_POINT_HISTORY_SUCCESS = 'GET_POINT_HISTORY_SUCCESS';
export const GET_POINT_HISTORY_FAILED = 'GET_POINT_HISTORY_FAILED';

export const GET_TOTAL_POINT = 'GET_TOTAL_POINT';
export const GET_TOTAL_POINT_SUCCESS = 'GET_TOTAL_POINT_SUCCESS';
export const GET_TOTAL_POINT_FAILED = 'GET_TOTAL_POINT_FAILED';

export const REDEEM_POINTS = 'REDEEM_POINTS';
export const REDEEM_POINTS_SUCCESS = 'REDEEM_POINTS_SUCCESS';
export const REDEEM_POINTS_FAILED = 'REDEEM_POINTS_FAILED';

export const getPointHistory = () => ({
  type: GET_POINT_HISTORY,
  payload: {},
});

export const getPointHistorySuccess = (payload: any) => ({
  type: GET_POINT_HISTORY_SUCCESS,
  payload,
});

export const getPointHistoryFailed = (payload: any) => ({
  type: GET_POINT_HISTORY_FAILED,
  payload,
});

export const getTotalPoint = () => ({
  type: GET_TOTAL_POINT,
  payload: {},
});

export const getTotalPointSuccess = (payload: any) => ({
  type: GET_TOTAL_POINT_SUCCESS,
  payload,
});

export const getTotalPointFailed = (payload: any) => ({
  type: GET_TOTAL_POINT_FAILED,
  payload,
});

export const redeemPoints = (payload: {
  points: number;
  status: string;
  comments?: string;
  user_id?: null;
}) => ({
  type: REDEEM_POINTS,
  payload,
});

export const redeemPointsSuccess = (payload: any) => ({
  type: REDEEM_POINTS_SUCCESS,
  payload,
});

export const redeemPointsFailed = (payload: any) => ({
  type: REDEEM_POINTS_FAILED,
  payload,
});
