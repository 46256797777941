import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getUserSession } from 'services/auth';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const history = useHistory();
  const user = getUserSession();
  useEffect(() => {
    window.scrollTo(0, 0);
    // this is used for if business is not added then user can't go anywhere
    if (user) {
      if (user?.roles?.find(v => v?.role_slug === 'supplier')) {
        if (!user?.isProfileCompleted) {
          history.replace(ROUTES.ADD_BUSINESS);
        } else {
          if (pathname === ROUTES.HOME) history.replace(ROUTES.RFQ_RESPONSE_LIST);
        }
      }
      if (user?.roles?.find(v => v?.role_slug === 'service-provider'))
        if (!user?.isProfileCompleted) {
          history.replace(ROUTES.ADD_SERVICE_PROVIDER_BUSINESS);
        } else {
          if (pathname === ROUTES.HOME) history.replace(ROUTES.RFQ_LIST);
        }
    }
  }, [pathname]);

  return null;
}
