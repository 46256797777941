import { ReactElement, ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
interface ICustomToolTip {
  toolTipContent: ReactElement;
  children: ReactNode;
  placement?: Placement;
}
const CustomToolTip = ({ toolTipContent, children, placement = 'bottom' }: ICustomToolTip) => {
  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      {children}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement={placement} delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      {toolTipContent}
    </OverlayTrigger>
  );
};

export default CustomToolTip;
