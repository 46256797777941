import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  deleteNotificationFailed,
  deleteNotificationSuccess,
  DELETE_NOTIFICATION,
  getAllNotificationFailed,
  getAllNotificationSuccess,
  getLiveFeedFailed,
  getLiveFeedSuccess,
  GET_ALL_NOTIFICATION,
  GET_LIVE_FEED,
  markAsReadNotificationFailed,
  markAsReadNotificationSuccess,
  MARK_READ_NOTIFICATION,
} from './actions';

import {
  getAllNotificationApi,
  markAsReadNotificationApi,
  removeNotificationApi,
} from 'services/api';
import { ActionType, ActionTypeWithCallback } from 'store/rootReducer';
import { IResponse } from 'store/app/interfaces';

function* getAllNotificationsSaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(getAllNotificationApi, payload);
    if (payload?.type === 'LiveFeed') yield put(getLiveFeedSuccess(data?.data));
    else yield put(getAllNotificationSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      if (payload?.type === 'LiveFeed') yield put(getLiveFeedFailed(error));
      else yield put(getAllNotificationFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      if (payload?.type === 'LiveFeed') yield put(getLiveFeedFailed(errors));
      else yield put(getAllNotificationFailed(errors));
    }
  }
}

function* markAsReadNotificationSaga({
  payload: { payload, onError, onSuccess },
}: ActionTypeWithCallback) {
  try {
    console.log('payload', payload);

    const data: IResponse = yield call(markAsReadNotificationApi, payload?.id);

    yield put(markAsReadNotificationSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(markAsReadNotificationFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(markAsReadNotificationFailed(errors));
    }
  }
}

function* deleteNotificationSaga({
  payload: { payload, onError, onSuccess },
}: ActionTypeWithCallback) {
  try {
    const data: IResponse = yield call(removeNotificationApi, payload?.id);
    yield put(deleteNotificationSuccess({ id: payload?.id }));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(deleteNotificationFailed(error));
    } else {
      const { errors } = error?.response?.data;
      yield put(deleteNotificationFailed({ id: payload?.id }));
    }
  }
}

function* notificationSaga() {
  yield all([takeLatest(GET_ALL_NOTIFICATION, getAllNotificationsSaga)]);
  yield all([takeLatest(GET_LIVE_FEED, getAllNotificationsSaga)]);
  yield all([takeLatest(MARK_READ_NOTIFICATION, markAsReadNotificationSaga)]);
  yield all([takeLatest(DELETE_NOTIFICATION, deleteNotificationSaga)]);
}

export default notificationSaga;
