import { ActionType, PayloadCallback } from 'store/rootReducer';

export const GET_ALL_NOTIFICATION = 'GET_ALL_NOTIFICATION';
export const GET_ALL_NOTIFICATION_SUCCESS = 'GET_ALL_NOTIFICATION_SUCCESS';
export const GET_ALL_NOTIFICATION_FAILED = 'GET_ALL_NOTIFICATION_FAILED';

export const MARK_READ_NOTIFICATION = 'MARK_READ_NOTIFICATION';
export const MARK_READ_NOTIFICATION_SUCCESS = 'MARK_READ_NOTIFICATION_SUCCESS';
export const MARK_READ_NOTIFICATION_FAILED = 'MARK_READ_NOTIFICATION_FAILED';

export const MARK_ALL_AS_READ_NOTIFICATION = 'MARK_ALL_AS_READ_NOTIFICATION';
export const MARK_ALL_AS_READ_NOTIFICATION_SUCCESS = 'MARK_ALL_AS_READ_NOTIFICATION_SUCCESS';
export const MARK_ALL_AS_READ_NOTIFICATION_FAILED = 'MARK_ALL_AS_READ_NOTIFICATION_FAILED';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED';

export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';
export const ADD_NEW_NOTIFICATION_SUCCESS = 'ADD_NEW_NOTIFICATION_SUCCESS';
export const ADD_NEW_NOTIFICATION_FAILED = 'ADD_NEW_NOTIFICATION_FAILED';
export const ADD_NEW_LIVE_FEED = 'ADD_NEW_LIVE_FEED';
export const ADD_NEW_LIVE_FEED_SUCCESS = 'ADD_NEW_LIVE_FEED_SUCCESS';
export const ADD_NEW_LIVE_FEED_FAILED = 'ADD_NEW_LIVE_FEED_FAILED';

// get live feed
export const GET_LIVE_FEED = 'GET_LIVE_FEED';
export const GET_LIVE_FEED_SUCCESS = 'GET_LIVE_FEED_SUCCESS';
export const GET_LIVE_FEED_FAILED = 'GET_LIVE_FEED_FAILED';

export const getAllNotification = () => ({
  type: GET_ALL_NOTIFICATION,
  payload: { type: 'App\\Notifications\\RealTimeNotification' },
});

export const getAllNotificationSuccess = data => ({
  type: GET_ALL_NOTIFICATION_SUCCESS,
  payload: data,
});

export const getAllNotificationFailed = error => ({
  type: GET_ALL_NOTIFICATION_FAILED,
  payload: error,
});

export const getLiveFeed = () => ({
  type: GET_LIVE_FEED,
  payload: { type: 'LiveFeed' },
});

export const getLiveFeedSuccess = data => ({
  type: GET_LIVE_FEED_SUCCESS,
  payload: data,
});

export const getLiveFeedFailed = error => ({
  type: GET_LIVE_FEED_FAILED,
  payload: error,
});
export const markAsReadNotification = (payload: PayloadCallback) => ({
  type: MARK_READ_NOTIFICATION,
  payload,
});

export const markAsReadNotificationSuccess = data => ({
  type: MARK_READ_NOTIFICATION_SUCCESS,
  payload: data,
});

export const markAsReadNotificationFailed = error => ({
  type: MARK_READ_NOTIFICATION_FAILED,
  payload: error,
});

export const markAllAsReadNotification = (payload: PayloadCallback) => ({
  type: MARK_ALL_AS_READ_NOTIFICATION,
  payload,
});

export const markAllAsReadNotificationSuccess = data => ({
  type: MARK_ALL_AS_READ_NOTIFICATION_SUCCESS,
  payload: data,
});

export const markAllAsReadNotificationFailed = error => ({
  type: MARK_ALL_AS_READ_NOTIFICATION_FAILED,
  payload: error,
});

export const deleteNotification = payload => ({
  type: DELETE_NOTIFICATION,
  payload,
});

export const deleteNotificationSuccess = data => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: data,
});

export const deleteNotificationFailed = error => ({
  type: DELETE_NOTIFICATION_FAILED,
  payload: error,
});

export const addNewNotification = payload => ({
  type: ADD_NEW_NOTIFICATION,
  payload,
});

export const addNewNotificationSuccess = data => ({
  type: ADD_NEW_NOTIFICATION_SUCCESS,
  payload: data,
});

export const addNewNotificationFailed = error => ({
  type: ADD_NEW_NOTIFICATION_FAILED,
  payload: error,
});

export const addNewLiveFeed = payload => ({
  type: ADD_NEW_LIVE_FEED,
  payload,
});

export const addNewLiveFeedSuccess = data => ({
  type: ADD_NEW_LIVE_FEED_SUCCESS,
  payload: data,
});

export const addNewLiveFeedFailed = error => ({
  type: ADD_NEW_LIVE_FEED_FAILED,
  payload: error,
});
