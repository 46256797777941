import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  deleteRfqResponseFailed,
  deleteRfqResponseSuccess,
  DELETE_RFQ_RESPONSE,
  getRfqResponseDetailFailed,
  getRfqResponseDetailQuotationFailed,
  getRfqResponseDetailQuotationSuccess,
  getRfqResponseDetailSuccess,
  getRfqResponseFailed,
  getRfqResponseSuccess,
  GET_RFQ_RESPONSE,
  GET_RFQ_RESPONSE_DETAIL,
  GET_RFQ_RESPONSE_QUOTATION_DETAIL,
  saveRfqResponseFailed,
  saveRfqResponseSuccess,
  SAVE_RFQ_RESPONSE,
} from './actions';

import i18n from 'config/i18n';
import {
  deleteRfqResponseApi,
  getRfqResponseApi,
  getRfqResponseDetailApi,
  getRfqResponseQuotationDetailApi,
  saveRfqResponseApi,
} from 'services/api';
import { addNotification } from 'services/notifications';
import { ActionType, ActionTypeWithCallback, ActionTypeWithHistory } from 'store/rootReducer';
import { logError } from 'store/user/actions';
import { ROUTES } from 'routes/constants';
import { IResponse } from 'store/app/interfaces';
import { resetSelectSupplierBusiness } from 'store/supplier/actions';
import { appNotification } from 'store/app/actions';

function* saveRfqResponseSaga({ payload }: ActionTypeWithCallback) {
  try {
    const data: Response = yield call(saveRfqResponseApi, payload?.payload);
    yield put(saveRfqResponseSuccess(data));
    if (payload?.onSuccess) payload?.onSuccess(data);
  } catch (error: any) {
    yield put(logError(error));
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(saveRfqResponseFailed(message));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);

      yield put(saveRfqResponseFailed(errors));
    }
    if (error?.response?.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}
function* getRfqResponseSaga({ payload }: ActionTypeWithHistory) {
  try {
    const data: IResponse = yield call(getRfqResponseApi, payload);
    yield put(getRfqResponseSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(getRfqResponseFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(getRfqResponseFailed(errors));
    }
  }
}

function* getRfqResponseDetailSaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(getRfqResponseDetailApi, payload);
    yield put(getRfqResponseDetailSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(getRfqResponseDetailFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(getRfqResponseDetailFailed(errors));
    }
  }
}
function* getRfqResponseDetailQuotationSaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(getRfqResponseQuotationDetailApi, payload);
    yield put(getRfqResponseDetailQuotationSuccess(data?.data));
  } catch (error: any) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(getRfqResponseDetailQuotationFailed(error));
    } else {
      const { errors } = error?.response?.data;
      console.log('errors', errors);
      yield put(getRfqResponseDetailQuotationFailed(errors));
    }
  }
}

function* deleteRfqResponseSaga({ payload }: ActionType) {
  try {
    console.log('payload', payload);

    const response: IResponse = yield call(deleteRfqResponseApi, payload);
    if (response?.status === 'Success') yield put(deleteRfqResponseSuccess(response?.data));
    else yield put(deleteRfqResponseFailed(response?.message));
  } catch (e: any) {
    yield put(deleteRfqResponseFailed(e?.response?.data));
  }
}

function* RfqResponseSaga() {
  yield all([
    takeLatest(SAVE_RFQ_RESPONSE, saveRfqResponseSaga),
    takeLatest(GET_RFQ_RESPONSE, getRfqResponseSaga),
    takeLatest(GET_RFQ_RESPONSE_DETAIL, getRfqResponseDetailSaga),
    takeLatest(GET_RFQ_RESPONSE_QUOTATION_DETAIL, getRfqResponseDetailQuotationSaga),
    takeLatest(DELETE_RFQ_RESPONSE, deleteRfqResponseSaga),
  ]);
}

export default RfqResponseSaga;
