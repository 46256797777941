// @flow
import { GET_ROLES, GET_ROLES_SUCCESS, GET_ROLES_FAILED } from './actions';
import { IRolesReducer } from './interfaces';

const INIT_STATE: IRolesReducer = {
  roles: [],
  data: [],
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES:
      return { ...state, loading: true };
    case GET_ROLES_SUCCESS:
      return { ...state, ...action.payload, loading: false, error: null };
    case GET_ROLES_FAILED:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};
