// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  FacebookAuthProvider,
} from 'firebase/auth';
import { getDatabase, ref, onValue, onChildAdded } from 'firebase/database';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const signInWithGoogle = async (authProvider = 'google', role_id?: number) => {
  try {
    const provider =
      authProvider === 'facebook' ? new FacebookAuthProvider() : new GoogleAuthProvider();
    const auth = getAuth();
    const result = await signInWithPopup(auth, provider);
    console.log('google result', result);
    return { ...result?.user?.providerData?.[0], role_id };
  } catch (error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log('error', error);
    return error;
  }
};
export const logoutGoogleUser = async () => {
  const auth = getAuth();
  return signOut(auth)
    .then(() => {
      return { status: true, message: 'successful logout' };
    })
    .catch(error => {
      return { status: false, error };
    });
};

export const listenFirebaseDb = (userId, callback, dbReference = '/notifications/user') => {
  try {
    console.log('firebase db', 'notifications/user' + userId);

    const db = getDatabase();
    console.log('firebase ins', db);

    const dbRef = ref(db, dbReference + userId);
    // onValue(dbRef, snapshot => {
    //   console.log('firebase snapshot', snapshot);

    //   const data = snapshot.val();
    //   callback(data);
    // });
    onChildAdded(
      dbRef,
      data => {
        console.log('firebase snapshot 2', data.val());
        callback(data.val());
      },
      e => console.log('firebase err', e),
    );
  } catch (error) {
    console.log('firebase db error', error);
  }
};
