import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  GET_POINT_HISTORY,
  GET_TOTAL_POINT,
  REDEEM_POINTS,
  getPointHistoryFailed,
  getPointHistorySuccess,
  getTotalPointFailed,
  getTotalPointSuccess,
  redeemPointsFailed,
  redeemPointsSuccess,
} from './actions';

import { getPointHistoryApi, getTotalPointsApi, redeemPointsApi } from 'services/api';
import { appNotification } from 'store/app/actions';
import { IResponse } from 'store/app/interfaces';

function* getPointHistorySaga() {
  try {
    const data: IResponse = yield call(getPointHistoryApi);
    yield put(getPointHistorySuccess(data?.data));
  } catch (e: any) {
    yield put(getPointHistoryFailed(e?.response?.data));

    appNotification({
      message: 'Oops something went wrong',
      type: 'danger',
    });
  }
}

function* getTotalPointSaga() {
  try {
    const data: IResponse = yield call(getTotalPointsApi);
    yield put(getTotalPointSuccess(data));
  } catch (e: any) {
    yield put(getTotalPointFailed(e?.response?.data));

    appNotification({
      message: 'Oops something went wrong',
      type: 'danger',
    });
  }
}
function* redeemPointsSaga({ payload }: any) {
  try {
    const data: IResponse = yield call(redeemPointsApi, payload);
    yield put(redeemPointsSuccess(data?.data));
    yield put(
      appNotification({
        type: 'success',
        message:
          'Redeem point request has been successfully placed, you will get your rewards within 3 to 5 business days',
      }),
    );
  } catch (e: any) {
    yield put(redeemPointsFailed(e?.response?.data));

    appNotification({
      message: 'Oops something went wrong',
      type: 'danger',
    });
  }
}

function* pointHistorySaga() {
  yield all([
    takeLatest(GET_POINT_HISTORY, getPointHistorySaga),
    takeLatest(GET_TOTAL_POINT, getTotalPointSaga),
    takeLatest(REDEEM_POINTS, redeemPointsSaga),
  ]);
}

export default pointHistorySaga;
