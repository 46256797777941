import { ActionTypeWithCallback, PayloadCallback } from 'store/rootReducer';
import {
  ConfirmForgotPasswordPayload,
  ConfirmSignupPayload,
  ForgotPasswordPayload,
  GetTokenPayload,
  SignupPayload,
  UserResponse,
  UserSettingsResponse,
  ChangePasswordPayload,
} from './interfaces';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const CONFIRM_FORGOT_PASSWORD = 'CONFIRM_FORGOT_PASSWORD';
export const CONFIRM_FORGOT_PASSWORD_SUCCESS = 'CONFIRM_FORGOT_PASSWORD_SUCCESS';
export const CONFIRM_FORGOT_PASSWORD_FAILURE = 'CONFIRM_FORGOT_PASSWORD_FAILURE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const SIGN_UP_USER = 'SIGN_UP_USER';
export const CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP';

export const WHO_AM_I_REQUEST = 'WHO_AM_I_REQUEST';

export const CLEAR_FORGOT_PASSWORD_EMAIL = 'CLEAR_FORGOT_PASSWORD_EMAIL';

export const FETCH_USER_SETTINGS = 'FETCH_USER_SETTINGS';
export const FETCH_USER_SETTINGS_SUCCESS = 'FETCH_USER_SETTINGS_SUCCESS';

export const GOOGLE_LOGIN = 'GOOGLE_LOGIN';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_WITH_PHONE_SUCCESS = 'LOGIN_USER_WITH_PHONE_SUCCESS';
export const LOGIN_USER_WITH_EMAIL_SUCCESS = 'LOGIN_USER_WITH_EMAIL_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILED = 'LOGOUT_USER_FAILED';
export const CLEAR_STORAGE_AFTER_LOGOUT = 'CLEAR_STORAGE_AFTER_LOGOUT';
export const RESET_ERROR = 'RESET_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const CONFIRM_OTP = 'CONFIRM_OTP';
export const CONFIRM_OTP_SUCCESS = 'CONFIRM_OTP_SUCCESS';
export const CONFIRM_OTP_FAILED = 'CONFIRM_OTP_FAILED';

export const LOGIN_WITH_OTP = 'LOGIN_WITH_OTP';
export const LOGIN_WITH_OTP_SUCCESS = 'LOGIN_WITH_OTP_SUCCESS';
export const LOGIN_WITH_OTP_FAILED = 'LOGIN_WITH_OTP_FAILED';

export const USER_UPDATE = 'USER_UPDATE';

export const GET_USER_ADDRESS = 'GET_USER_ADDRESS';
export const GET_USER_ADDRESS_SUCCESS = 'GET_USER_ADDRESS_SUCCESS';
export const GET_USER_ADDRESS_FAILED = 'GET_USER_ADDRESS_FAILED';

export const SAVE_USER_ADDRESS = 'SAVE_USER_ADDRESS';
export const SAVE_USER_ADDRESS_SUCCESS = 'SAVE_USER_ADDRESS_SUCCESS';
export const SAVE_USER_ADDRESS_FAILED = 'SAVE_USER_ADDRESS_FAILED';

export const EDIT_USER_ADDRESS = 'EDIT_USER_ADDRESS';
export const EDIT_USER_ADDRESS_SUCCESS = 'EDIT_USER_ADDRESS_SUCCESS';
export const EDIT_USER_ADDRESS_FAILED = 'EDIT_USER_ADDRESS_FAILED';

export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';
export const DELETE_USER_ADDRESS_SUCCESS = 'DELETE_USER_ADDRESS_SUCCESS';
export const DELETE_USER_ADDRESS_FAILED = 'DELETE_USER_ADDRESS_FAILED';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const fetchUser = (payload: GetTokenPayload) => ({
  type: FETCH_USER,
  payload,
});

export const updateUser = (payload: any) => ({
  type: USER_UPDATE,
  payload,
});

export const fetchUserSuccess = (payload: UserResponse) => ({
  type: FETCH_USER_SUCCESS,
  payload,
});

export const logError = (
  // @todo define exact type
  payload: any,
) => ({
  type: FETCH_USER_FAILURE,
  payload,
});

export const forgotPasswordRequest = (payload: ForgotPasswordPayload) => ({
  type: FORGOT_PASSWORD,
  payload,
});

export const forgotPasswordSuccess = (payload: any) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFailure = (payload: any) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload,
});

export const confirmForgotPasswordRequest = (payload: ConfirmForgotPasswordPayload) => ({
  type: CONFIRM_FORGOT_PASSWORD,
  payload,
});

export const confirmForgotPasswordSuccess = (payload: any) => ({
  type: CONFIRM_FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const confirmForgotPasswordFailure = (
  // @todo define exact type
  payload: any,
) => ({
  type: CONFIRM_FORGOT_PASSWORD_FAILURE,
  payload,
});

export const changePassword = (payload: ChangePasswordPayload) => ({
  type: CHANGE_PASSWORD,
  payload,
});

export const signupUser = (payload: SignupPayload) => ({
  type: SIGN_UP_USER,
  payload,
});

export const confirmSignup = (payload: ConfirmSignupPayload) => ({
  type: CONFIRM_SIGN_UP,
  payload,
});

export const clearForgotPwEmail = () => ({
  type: CLEAR_FORGOT_PASSWORD_EMAIL,
});

export const whoAmIRequest = () => ({
  type: WHO_AM_I_REQUEST,
});

export const fetchUserSettings = () => ({
  type: FETCH_USER_SETTINGS,
});

export const fetchUserSettingsSuccess = (payload: UserSettingsResponse) => ({
  type: FETCH_USER_SETTINGS_SUCCESS,
  payload,
});

export const googleLogin = (payload, history: any) => ({
  type: GOOGLE_LOGIN,
  payload: { payload, history },
});

export const googleLoginSuccess = user => ({
  type: GOOGLE_LOGIN_SUCCESS,
  payload: user,
});

export const googleLoginFailed = error => ({
  type: GOOGLE_LOGIN_FAILED,
  payload: error,
});

//parnter auth actions
export const loginUser = (payload: PayloadCallback) => ({
  type: LOGIN_USER,
  payload,
});

export const loginUserWithPhoneSuccess = user => ({
  type: LOGIN_USER_WITH_PHONE_SUCCESS,
  payload: user,
});
export const loginUserWithEmailSuccess = user => ({
  type: LOGIN_USER_WITH_EMAIL_SUCCESS,
  payload: user,
});

export const loginUserFailed = error => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const registerUser = (payload, history: any) => ({
  type: REGISTER_USER,
  payload: { payload, history },
});

export const registerUserSuccess = user => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});

export const registerUserFailed = error => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

// export const logoutUser = (payload, history: any) => ({
//   type: LOGOUT_USER,
//   payload: { history, payload },
// });

export const resetPassword = (payload: any) => ({
  type: RESET_PASSWORD,
  payload,
});

export const resetPasswordSuccess = passwordResetStatus => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: passwordResetStatus,
});

export const resetPasswordFailed = error => ({
  type: RESET_PASSWORD_FAILED,
  payload: error,
});

export const confirmOtp = (payload: PayloadCallback) => ({
  type: CONFIRM_OTP,
  payload,
});

export const confirmOtpSuccess = user => ({
  type: CONFIRM_OTP_SUCCESS,
  payload: user,
});

export const confirmOtpFailed = error => ({
  type: CONFIRM_OTP_FAILED,
  payload: error,
});

export const logoutUser = (history, token) => ({
  type: LOGOUT_USER,
  payload: { history, token },
});

export const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
});

export const clearStorageAfterLogOut = () => ({
  type: CLEAR_STORAGE_AFTER_LOGOUT,
});

export const logoutUserFailed = error => ({
  type: LOGOUT_USER_FAILED,
  payload: { error },
});

export const getUserAddress = () => ({
  type: GET_USER_ADDRESS,
  payload: {},
});

export const getUserAddressSuccess = user => ({
  type: GET_USER_ADDRESS_SUCCESS,
  payload: user,
});

export const getUserAddressFailed = error => ({
  type: GET_USER_ADDRESS_FAILED,
  payload: error,
});

export const saveUserAddress = (payload: PayloadCallback) => ({
  type: SAVE_USER_ADDRESS,
  payload,
});

export const saveUserAddressSuccess = (payload: any) => ({
  type: SAVE_USER_ADDRESS_SUCCESS,
  payload,
});

export const saveUserAddressFailed = error => ({
  type: SAVE_USER_ADDRESS_FAILED,
  payload: error,
});

export const editUserAddress = (payload: PayloadCallback) => ({
  type: EDIT_USER_ADDRESS,
  payload,
});

export const editUserAddressSuccess = (payload: any) => ({
  type: EDIT_USER_ADDRESS_SUCCESS,
  payload,
});

export const editUserAddressFailed = error => ({
  type: EDIT_USER_ADDRESS_FAILED,
  payload: error,
});

export const deleteUserAddress = (payload: any) => ({
  type: DELETE_USER_ADDRESS,
  payload,
});

export const deleteUserAddressSuccess = (user: PayloadCallback) => ({
  type: DELETE_USER_ADDRESS_SUCCESS,
  payload: user,
});

export const deleteUserAddressFailed = error => ({
  type: DELETE_USER_ADDRESS_FAILED,
  payload: error,
});

export const resetError = () => ({
  type: RESET_ERROR,
  payload: {},
});

export const updateProfile = (payload: any) => ({
  type: UPDATE_PROFILE,
  payload,
});

export const updateProfileSuccess = (user: PayloadCallback) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: user,
});

export const updateProfileFailed = error => ({
  type: UPDATE_PROFILE_FAILED,
  payload: error,
});

export const loginWithOtp = (payload: PayloadCallback) => ({
  type: LOGIN_WITH_OTP,
  payload,
});

export const loginWithOtpSuccess = user => ({
  type: LOGIN_WITH_OTP_SUCCESS,
  payload: user,
});

export const loginWithOtpFailed = error => ({
  type: LOGIN_WITH_OTP_FAILED,
  payload: error,
});
