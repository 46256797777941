import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  FETCH_SPARE_PARTS_CATEGORY,
  FETCH_CSRF_TOKEN,
  FETCH_SPARE_PARTS,
  FETCH_SPARE_PARTS_SUB_CATEGORY,
  FETCH_VEHICLE_MAKE,
  fetchSparePartSuccess,
  fetchVehicleMakeSuccess,
  fetchSparePartsCategory,
  fetchSparePartsCategorySuccess,
  fetchSparePartsCategoryFailure,
  fetchSparePartsSubCategorySuccess,
  fetchVehicleModelSuccess,
  FETCH_VEHICLE_MODEL,
} from './actions';

import i18n from 'config/i18n';
import {
  csrfToken,
  getCarModel,
  getSpartParts,
  getSpartPartsCategory,
  getSpartPartsSubCategory,
  getVehicleMake,
} from 'services/api';
import { addNotification } from 'services/notifications';
import { ActionType } from 'store/rootReducer';
import { logError } from 'store/user/actions';
import { IResponse } from 'store/app/interfaces';

function* sparePartSaga({ payload }: ActionType) {
  try {
    const data: Response = yield call(getSpartParts, payload);
    yield put(fetchSparePartSuccess(data));
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}

function* vehicleMakeSaga({ payload }: ActionType) {
  try {
    const data: Response = yield call(getVehicleMake, payload);
    yield put(fetchVehicleMakeSuccess(data));
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}

function* vehicleModelSaga({ payload }: ActionType) {
  try {
    const data: Response = yield call(getCarModel, payload);
    yield put(fetchVehicleModelSuccess(data));
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}

function* sparePartCategorySaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(getSpartPartsCategory, payload);
    yield put(fetchSparePartsCategorySuccess(data?.data));
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}
function* sparePartSubCategorySaga({ payload }: ActionType) {
  try {
    const data: IResponse = yield call(getSpartPartsSubCategory, payload);
    yield put(fetchSparePartsSubCategorySuccess(data?.data));
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}

function* csrfTokenSaga() {
  try {
    yield call(csrfToken);
    // yield put(fetchUserSuccess(data));
    // @todo define exact type
  } catch (e: any) {
    yield put(logError(e));
  }
}

function* homeSaga() {
  yield all([
    takeLatest(FETCH_CSRF_TOKEN, csrfTokenSaga),
    takeLatest(FETCH_SPARE_PARTS, sparePartSaga),
    takeLatest(FETCH_VEHICLE_MAKE, vehicleMakeSaga),
    takeLatest(FETCH_VEHICLE_MODEL, vehicleModelSaga),
    takeLatest(FETCH_SPARE_PARTS_CATEGORY, sparePartCategorySaga),
    takeLatest(FETCH_SPARE_PARTS_SUB_CATEGORY, sparePartSubCategorySaga),
  ]);
}

export default homeSaga;
