import { getUserSession } from 'services/auth';
import {
  FETCH_REVIEWS,
  FETCH_REVIEWS_FAILED,
  FETCH_REVIEWS_SUCCESS,
  SAVE_REVIEW,
  SAVE_REVIEW_FAILED,
  SAVE_REVIEW_SUCCESS,
} from 'store/reviews/actions';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_REVIEWS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_REVIEWS_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };

    case SAVE_REVIEW:
      return {
        ...state,
        loading: true,
      };
    case SAVE_REVIEW_SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state?.data],
        error: null,
        loading: false,
      };
    case SAVE_REVIEW_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
