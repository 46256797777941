import imageCompression from 'browser-image-compression';
import { IImageArg } from 'components/ChatBox/ChatBox';
import { toBase64 } from 'services/utils';

interface IAttachmentOption {
  onImageUpload: (file: IImageArg) => void;
  onFileUpload: (file: File[]) => void;
  onVideoUpload: (file: File[]) => void;
}

const ChatAttachmentOption = ({
  onImageUpload,
  onFileUpload,
  onVideoUpload,
}: IAttachmentOption) => {
  const handleImageChange = async e => {
    const imageFiles = Array.from(e.target.files);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      Promise.all(
        imageFiles.map(
          (imageFile: File) =>
            new Promise((resolve, reject) => {
              return Promise.all([
                imageCompression(imageFile, options),
                toBase64([imageFile], true),
              ]).then(res => resolve(res));
            }),
        ),
      ).then(res => {
        console.log('res', res);
        let files: IImageArg = { file: [], preview: [] };
        res.forEach((v, i) => {
          files.file[i] = v[0];
          files.preview[i] = v[1];
        });
        onImageUpload(files);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleVideoChange = e => {};
  const handleFileChange = e => {
    const imageFiles = Array.from(e.target.files);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      Promise.all(
        imageFiles.map(
          (imageFile: File) =>
            new Promise((resolve, reject) => {
              return Promise.all([imageCompression(imageFile, options)]).then(res => resolve(res));
            }),
        ),
      ).then(res => {
        console.log('res', res);
        let files = [];
        res.forEach((v, i) => {
          files[i] = v[0];
        });
        onFileUpload(files);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      className="p-2"
      style={{
        position: 'absolute',
        bottom: 75,
        background: 'white',
        borderRadius: 20,
        boxShadow: '#bebebe 2px 2px 6px',
      }}>
      <div>
        <label
          htmlFor="chat-att-image"
          style={{ borderRadius: 10, padding: 10, cursor: 'pointer' }}>
          <i className="font-size-24 fa fa-file-image-o" />
          <span className="ms-2">Image</span>
          <input
            id="chat-att-image"
            accept="image/*"
            type="file"
            name="image"
            multiple
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
        </label>
      </div>
      <div>
        <label
          htmlFor="chat-att-video"
          style={{ borderRadius: 10, padding: 10, cursor: 'pointer' }}>
          <i className="font-size-24 fa fa-file-video-o" />
          <span className="ms-2">Video</span>
          <input
            id="chat-att-video"
            accept="video/*"
            type="file"
            name="video"
            style={{ display: 'none' }}
            onChange={handleVideoChange}
          />
        </label>
        <label></label>
      </div>
      <div>
        <label htmlFor="chat-att-file" style={{ borderRadius: 10, padding: 10, cursor: 'pointer' }}>
          <i className="font-size-24 fa fa-file-o" />
          <span className="ms-2">File</span>
          <input
            id="chat-att-file"
            accept="file/*"
            type="file"
            name="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </label>
      </div>
    </div>
  );
};
export default ChatAttachmentOption;
