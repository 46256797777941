import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchReviewFailed,
  fetchReviewSuccess,
  FETCH_REVIEWS,
  saveReviewFailed,
  saveReviewSuccess,
  SAVE_REVIEW,
} from './actions';

import { fetchReviewApi, saveReviewApi } from 'services/api';
import { ActionType, ActionTypeWithCallback } from 'store/rootReducer';
import { appNotification } from 'store/app/actions';
import { getAllNotification } from 'store/notifications/actions';

function* fetchReviewSaga({ payload }: ActionType) {
  try {
    const data = yield call(fetchReviewApi, payload);
    yield put(fetchReviewSuccess(data?.data));
  } catch (e: any) {
    yield put(fetchReviewFailed(e?.response?.data));
    yield put(appNotification({ type: 'danger', message: 'Oops something went wrong' }));
  }
}

function* saveReviewSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response = yield call(saveReviewApi, payload);
    console.log('success resp', response);

    yield put(saveReviewSuccess(response?.data));
    if (onSuccess)
      yield call(() => {
        onSuccess(response);
      });
  } catch (error) {
    console.log('error', error);

    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(appNotification({ type: 'danger', message }));
      yield put(saveReviewFailed(error?.response?.message));
      yield call(() => onError(error));
    } else {
      const { errors } = error?.response?.data;
      yield put(saveReviewFailed(errors));
    }
  }
}

function* userSaga() {
  yield all([takeLatest(FETCH_REVIEWS, fetchReviewSaga), takeLatest(SAVE_REVIEW, saveReviewSaga)]);
}

export default userSaga;
