import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  confirmForgotPasswordFailure,
  confirmForgotPasswordSuccess,
  CONFIRM_FORGOT_PASSWORD,
  CONFIRM_SIGN_UP,
  logError,
  fetchUserSettingsSuccess,
  fetchUserSuccess,
  FETCH_USER,
  FETCH_USER_SETTINGS,
  forgotPasswordSuccess,
  FORGOT_PASSWORD,
  SIGN_UP_USER,
  WHO_AM_I_REQUEST,
  CHANGE_PASSWORD,
  googleLoginSuccess,
  GOOGLE_LOGIN,
  registerUserFailed,
  confirmOtpFailed,
  confirmOtpSuccess,
  registerUser,
  registerUserSuccess,
  loginUserFailed,
  loginUserWithPhoneSuccess,
  loginUserWithEmailSuccess,
  resetPasswordSuccess,
  resetPasswordFailed,
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  CONFIRM_OTP,
  logoutUserSuccess,
  logoutUserFailed,
  googleLoginFailed,
  clearStorageAfterLogOut,
  saveUserAddressSuccess,
  saveUserAddressFailed,
  SAVE_USER_ADDRESS,
  getUserAddressSuccess,
  getUserAddressFailed,
  GET_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  deleteUserAddressSuccess,
  deleteUserAddressFailed,
  EDIT_USER_ADDRESS,
  editUserAddressSuccess,
  editUserAddressFailed,
  LOGIN_USER_WITH_PHONE_SUCCESS,
  UPDATE_PROFILE,
  updateProfileSuccess,
  updateProfileFailed,
  loginWithOtpFailed,
  loginWithOtpSuccess,
  LOGIN_WITH_OTP,
} from './actions';
import { UserResponse, UserSettingsResponse } from './interfaces';

import i18n from 'config/i18n';
import {
  confirmForgotPasswordApi,
  confirmSignupApi,
  fetchUserSettingsApi,
  forgotPasswordApi,
  getToken,
  signupUserApi,
  whoAmIApi,
  changePassword as changePasswordAPI,
  socialLoginApi,
  resetPasswardApi,
  confirmOtpApi,
  registerApi,
  logoutApi,
  loginApi,
  saveUserAddressApi,
  getUserAddressApi,
  getAllSupplierBusinessApi,
  deleteUserAddressApi,
  updateProfileApi,
  loginWithOtpApi,
} from 'services/api';
import { clearUserSession, saveUserSession } from 'services/auth';
import { addNotification } from 'services/notifications';
import {
  ActionType,
  ActionTypeWithCallback,
  ActionTypeWithHistory,
  PayloadCallback,
} from 'store/rootReducer';
import { ROUTES } from 'routes/constants';
import { IResponse } from 'store/app/interfaces';
import { AxiosError } from 'axios';
import { appNotification } from 'store/app/actions';
import { getAllNotification } from 'store/notifications/actions';
import { notifyReact } from 'services/mobileService';
// import { createSocketConnection } from 'services/socketService';

function* fetchUser({ payload }: ActionType) {
  try {
    const data: UserResponse = yield call(getToken, payload);
    yield put(fetchUserSuccess(data));
    saveUserSession(data.token);
    // @todo use react-router-rom for redirecting
    window.location.pathname = '/portfolios';
    // @todo define exact type
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}

function* whoAmI() {
  try {
    const data = yield call(whoAmIApi);
    yield put(fetchUserSuccess(data?.data));
    saveUserSession(data?.data);
    // @todo define exact type
  } catch (e: any) {
    yield put(logError(e));
  }
}

function* forgotPassword({ payload }: ActionType) {
  try {
    yield call(forgotPasswordApi, payload);
    yield put(forgotPasswordSuccess(payload));
    // @todo define exact type
  } catch (e: any) {
    yield put(forgotPasswordSuccess(e));
  }
}

function* confirmForgotPassword({ payload }: ActionType) {
  try {
    yield call(confirmForgotPasswordApi, payload);
    yield put(confirmForgotPasswordSuccess(payload));
    addNotification({
      message: i18n.t('success.passwordChanged'),
      title: i18n.t('success.success'),
      type: 'success',
    });
    // @todo use react-router-rom for redirecting
    window.location.pathname = '/login';

    // @todo define exact type
  } catch (e: any) {
    yield put(confirmForgotPasswordFailure(e));
    addNotification({
      message: i18n.t('error.resetPassword'),
      title: i18n.t('error.error'),
      type: 'danger',
    });
  }
}

function* changePassword({ payload }: ActionType) {
  try {
    yield call(changePasswordAPI, payload);
    addNotification({
      message: i18n.t('success.passwordChanged'),
      title: i18n.t('success.success'),
      type: 'success',
    });
  } catch (e: any) {
    addNotification({
      message: i18n.t('error.resetPassword'),
      title: i18n.t('error.error'),
      type: 'danger',
    });
  }
}

function* signupUser({ payload }: ActionType) {
  try {
    yield call(signupUserApi, payload);
    // @todo ask ux for proper success message
    addNotification({
      message: i18n.t('success.success'),
      title: i18n.t('success.success'),
      type: 'success',
    });

    // @todo use react-router-rom for redirecting
    window.location.pathname = '/verify-account';

    // @todo define exact type
  } catch (e: any) {
    // @todo ask ux for proper error message
    addNotification({
      message: i18n.t('error.somethingWentWrong'),
      title: i18n.t('error.error'),
      type: 'danger',
    });
  }
}

function* confirmSignup({ payload }: ActionType) {
  try {
    yield call(confirmSignupApi, payload);
    // @todo ask ux for proper success message
    addNotification({
      message: i18n.t('success.success'),
      title: i18n.t('success.success'),
      type: 'success',
    });

    // @todo use react-router-rom for redirecting
    window.location.pathname = '/login';

    // @todo define exact type
  } catch (e: any) {
    // @todo ask ux for proper error message
    addNotification({
      message: i18n.t('error.somethingWentWrong'),
      title: i18n.t('error.error'),
      type: 'danger',
    });
  }
}

function* fetchUserSettingsSaga() {
  try {
    const data: UserSettingsResponse = yield call(fetchUserSettingsApi);
    yield put(fetchUserSettingsSuccess(data));
    // @todo define exact type
  } catch (error: any) {
    yield put(logError(error));
  }
}

function* googleLoginSaga({ payload: { payload, history } }: ActionTypeWithHistory) {
  try {
    const data: IResponse = yield call(socialLoginApi, payload);
    if (data?.status === 'Success') {
      yield put(googleLoginSuccess(data?.data));
      saveUserSession(data?.data);
      yield call(() => history.push(ROUTES.HOME));
    } else {
      yield put(logError(data?.error));
      yield put(googleLoginFailed(data?.error));
      yield put(appNotification({ type: 'danger', message: data?.message }));
    }
    // @todo define exact type
  } catch (error: any) {
    yield put(logError(error));
    yield put(googleLoginFailed(error?.message));
    yield put(appNotification({ type: 'danger', message: error?.message }));
  }
}

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* loginSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response = yield call(loginApi, payload);

    if (payload?.signupprovider === 'phone') {
      yield put(loginUserWithPhoneSuccess(response?.data));
    }
    if (payload?.signupprovider === 'email') {
      yield put(loginUserWithEmailSuccess(response?.data));
    }
    yield call(() => {
      saveUserSession(response?.data);
    });
    // yield put(getAllNotification());
    yield call(() => {
      onSuccess(response);
    });
    notifyReact(response, 'ERROR');
  } catch (error) {
    notifyReact(error, 'ERROR');
    if (error.response.status != 422) {
      let message = '';
      console.log('error', error?.response);

      if (error) message = error?.response?.data?.message || 'Oops something went wrong';
      yield put(loginUserFailed(message));
      yield call(() => onError(message));
    } else {
      const { errors } = error?.response?.data;

      yield put(loginUserFailed(errors));
    }
    saveUserSession(null);
  }
}

/**
 * Login success saga
 * @param {*} payload - response
 */
function* loginSuccessSaga({ payload }: ActionType) {
  try {
    // createSocketConnection(payload?.token);
  } catch (error) {
    // console.log('loginSuccessSaga', error);
  }
}

// /**
//  * Logout the user
//  * @param {*} param0
//  */
// function* logoutSaga({ payload: { history, payload } }: ActionTypeWithHistory) {
//   try {
//     const response = yield call(logoutApi);
//     console.log('response', response);

//     if (response.status !== 'Error') {
//       saveUserSession(null);
//       yield call(() => {
//         history.push('/login');
//       });
//     } else {
//       console.log('error', response);
//     }
//   } catch (error) {
//     console.log('error', error);
//   }
// }

/**
 * Register the user
 */
function* registerSaga({ payload: { history, payload } }: ActionTypeWithHistory) {
  try {
    const response = yield call(registerApi, payload);
    if (response?.status === 'Error') yield put(registerUserFailed(response?.errors));
    else {
      console.log(response);
      yield put(registerUserSuccess({ ...payload, id: response?.data?.id }));
      yield call(() => {
        history.push('/verify-otp');
      });
    }
  } catch (error) {
    if (error.response.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(registerUserFailed(message));
    } else {
      const { errors } = error?.response?.data;
      yield put(registerUserFailed(errors));
    }
  }
}

/**
 * confirm the user with otp
 */
function* confirmOtpSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response = yield call(confirmOtpApi, payload);
    if (response?.status === 'Error') {
      if (onError) onError(response?.errors);

      yield put(confirmOtpFailed(response?.errors));
    } else {
      saveUserSession(response?.data);
      if (onSuccess) onSuccess(response?.data);
      yield put(confirmOtpSuccess(response?.data));
    }
  } catch (error) {
    let message = 'Oops something went wrong';
    if (onError) onError(error?.response?.data?.message);

    yield put(confirmOtpFailed(message));
  }
}

/**
 * reset password
 */
function* resetPasswordSaga({ payload }: ActionType) {
  try {
    const response = yield call(resetPasswardApi, payload);
    yield put(resetPasswordSuccess(response.message));
  } catch (error) {
    let message;
    switch (error.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      default:
        message = error;
    }
    yield put(resetPasswordFailed(message));
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logoutSaga({ payload: { history, token } }: any) {
  try {
    const response = yield call(logoutApi, token);
    console.log('response', response);

    if (response.status !== 'Error') {
      clearUserSession();
      yield put(logoutUserSuccess());
      yield put(clearStorageAfterLogOut());
    } else {
      yield put(logoutUserFailed('Oops something went wrong'));
      yield put(appNotification({ type: 'danger', message: 'Oops something went wrong' }));
      console.log('error', response);
    }
    history.push(ROUTES.HOME);
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 419) {
      yield put(clearStorageAfterLogOut());
      yield put(logoutUserFailed('Oops something went wrong'));
    } else {
      yield put(appNotification({ type: 'danger', message: 'Oops something went wrong' }));
    }
    history.push(ROUTES.HOME);

    console.log('error', error);
  }
}

function* getUserAddressSaga(payload: ActionType) {
  try {
    const response: IResponse = yield call(getUserAddressApi);
    if (response?.status === 'Success') yield put(getUserAddressSuccess(response?.data));
    else yield put(getUserAddressFailed(response?.error));
  } catch (error) {
    if (error.response.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(getUserAddressFailed(message));
    } else {
      const { errors } = error?.response?.data;
      yield put(getUserAddressFailed(errors));
    }
  }
}

function* saveUserAddressSaga({
  payload: { payload, onSuccess, onError },
}: ActionTypeWithCallback) {
  try {
    const response = yield call(saveUserAddressApi, payload);
    console.log('success resp', response);

    yield put(saveUserAddressSuccess(response?.data));

    yield call(() => {
      onSuccess(response);
    });
  } catch (error) {
    console.log('error', error);

    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(appNotification({ type: 'danger', message }));
      yield put(saveUserAddressFailed(error?.response?.message));
      yield call(() => onError(error));
    } else {
      const { errors } = error?.response?.data;
      yield put(saveUserAddressFailed(errors));
    }
  }
}
function* editUserAddressSaga({
  payload: { payload, onSuccess, onError },
}: ActionTypeWithCallback) {
  try {
    const response = yield call(saveUserAddressApi, payload);
    console.log('success resp', response);

    yield put(editUserAddressSuccess(response?.data));

    yield call(() => {
      onSuccess(response);
    });
  } catch (error) {
    console.log('error', error);

    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(appNotification({ type: 'danger', message }));
      yield put(editUserAddressFailed(error?.response?.message));
      yield call(() => onError(error));
    } else {
      const { errors } = error?.response?.data;
      yield put(editUserAddressFailed(errors));
    }
  }
}

function* deleteUserAddressSaga({ payload }: ActionType) {
  try {
    const response: IResponse = yield call(deleteUserAddressApi, payload);
    if (response?.status === 'Success') yield put(deleteUserAddressSuccess(response?.data));
    else yield put(deleteUserAddressFailed(response?.message));
  } catch (e: any) {
    yield put(deleteUserAddressFailed(e?.response?.data));
  }
}

function* updateProfileSaga({ payload }: ActionType) {
  try {
    const response: IResponse = yield call(updateProfileApi, payload);
    if (response?.status === 'Success') yield put(updateProfileSuccess(response?.data));
    else yield put(updateProfileFailed(response?.message));
  } catch (e: any) {
    yield put(updateProfileFailed(e?.response?.data));
  }
}

/**
 * confirm the user with otp
 */
function* loginWithOtpSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response = yield call(loginWithOtpApi, payload);
    if (response?.status === 'Error') {
      if (onError) onError(response?.errors);

      yield put(loginWithOtpFailed(response?.errors));
    } else {
      saveUserSession(response?.data);
      if (onSuccess) onSuccess(response?.data);
      yield put(loginWithOtpSuccess(response?.data));
    }
  } catch (error) {
    let message = 'Oops something went wrong';
    if (onError) onError(error?.response?.data?.message);

    yield put(loginWithOtpFailed(message));
  }
}

function* userSaga() {
  yield all([
    takeLatest(FETCH_USER, fetchUser),
    takeLatest(WHO_AM_I_REQUEST, whoAmI),
    takeLatest(FORGOT_PASSWORD, forgotPassword),
    takeLatest(CONFIRM_FORGOT_PASSWORD, confirmForgotPassword),
    takeLatest(SIGN_UP_USER, signupUser),
    takeLatest(CONFIRM_SIGN_UP, confirmSignup),
    takeLatest(FETCH_USER_SETTINGS, fetchUserSettingsSaga),
    takeLatest(CHANGE_PASSWORD, changePassword),
    takeLatest(GOOGLE_LOGIN, googleLoginSaga),
    // user auth sagas
    takeLatest(LOGIN_USER, loginSaga),
    takeLatest(LOGIN_USER_WITH_PHONE_SUCCESS, loginSuccessSaga),
    takeLatest(REGISTER_USER, registerSaga),
    takeLatest(LOGOUT_USER, logoutSaga),
    takeLatest(RESET_PASSWORD, resetPasswordSaga),
    takeLatest(CONFIRM_OTP, confirmOtpSaga),
    takeLatest(LOGIN_WITH_OTP, loginWithOtpSaga),
    // user address saga
    takeLatest(SAVE_USER_ADDRESS, saveUserAddressSaga),
    takeLatest(EDIT_USER_ADDRESS, editUserAddressSaga),
    takeLatest(GET_USER_ADDRESS, getUserAddressSaga),
    takeLatest(DELETE_USER_ADDRESS, deleteUserAddressSaga),
    takeLatest(UPDATE_PROFILE, updateProfileSaga),
  ]);
}

export default userSaga;
