import { Spinner as Loader, SpinnerProps } from 'react-bootstrap';

const Spinner = ({ animation = 'border' }: { animation?: 'border' | 'grow' }) => (
  <div
    style={{
      height: '80vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <Loader animation={animation} />
  </div>
);

export default Spinner;
