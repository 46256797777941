import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getUserSession } from 'services/auth';
import { listenFirebaseDb } from 'services/firebase';
import { createSocketConnection } from 'services/socketService';
import { userSelector } from 'store/user/selectors';

function listen(callBack: (payload: any) => void, channel: string) {
  if (window.Echo) {
    console.log('notifcation listen', channel);
    window.Echo.private(channel)
      .listen('.message.new', (payload: any) => {
        console.log('notifcation payload', payload);

        callBack(payload);
      })
      .error(e => console.log('echo error', e));

    return function cleanUp() {
      window.Echo.leaveChannel(`private-${channel}`);
    };
  }
}

type ISocket = {
  type: string; // @TODO more type added when needed
  callBack: (payload: any) => void;
};

export const usePusherSocket = ({ callBack, type = 'NOTIFICATION' }: ISocket, deps?: any[]) => {
  const { user } = useSelector(userSelector);
  const user2 = getUserSession();
  useEffect(() => {
    console.log('useSOcket', user?.token, user2?.token);
    if (user) {
      createSocketConnection(user?.token);
      switch (type) {
        case 'NOTIFICATION':
          return listen(callBack, `App.Models.User.${user?.id}`);
        case 'NOTIFY_ALL':
          return listen(callBack, 'notify-all');
        default:
          return listen(callBack, type);
      }
    }
  }, [user?.token, ...deps]);
};

export const useSocket = (
  { callBack }: { callBack: (payload: any) => void },
  deps,
  fbDBref = '/notifications/user',
) => {
  const { user } = useSelector(userSelector);
  useEffect(() => {
    console.log('useSocket user', user);

    if (user) {
      listenFirebaseDb(user?.id, callBack, fbDBref);
    }
  }, [user?.token, ...deps]);
};
