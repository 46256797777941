import { ActionTypeWithCallback, PayloadCallback } from 'store/rootReducer';
import { IOrderActionPayload } from './interfaces';

export const SAVE_ORDER = 'SAVE_ORDER';
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
export const SAVE_ORDER_FAILED = 'SAVE_ORDER_FAILED';

export const SEARCH_ORDER = 'SEARCH_ORDER';
export const SEARCH_ORDER_SUCCESS = 'SEARCH_ORDER_SUCCESS';
export const SEARCH_ORDER_FAILED = 'SEARCH_ORDER_FAILED';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAILED = 'GET_ORDER_FAILED';

export const ORDER_ACTION = 'ORDER_ACTION';
export const ORDER_ACTION_SUCCESS = 'ORDER_ACTION_SUCCESS';
export const ORDER_ACTION_FAILED = 'ORDER_ACTION_FAILED';


export const saveOrder = (payload: PayloadCallback) => ({
  type: SAVE_ORDER,
  payload,
});

export const saveOrderSuccess = (success: any) => ({
  type: SAVE_ORDER_SUCCESS,
  payload: success,
});

export const saveOrderFailed = error => ({
  type: SAVE_ORDER_FAILED,
  payload: error,
});

export const searchOrder = (payload: PayloadCallback) => ({
  type: SEARCH_ORDER,
  payload,
});

export const searchOrderSuccess = (success: any) => ({
  type: SEARCH_ORDER_SUCCESS,
  payload: success,
});

export const searchOrderFailed = error => ({
  type: SEARCH_ORDER_FAILED,
  payload: error,
});

export const getOrder = (payload: PayloadCallback) => ({
  type: GET_ORDER,
  payload,
});

export const getOrderSuccess = (success: any) => ({
  type: GET_ORDER_SUCCESS,
  payload: success,
});

export const getOrderFailed = error => ({
  type: GET_ORDER_FAILED,
  payload: error,
});

export const orderAction = (payload: PayloadCallback) => ({
  type: ORDER_ACTION,
  payload,
});

export const orderActionSuccess = (success: any) => ({
  type: ORDER_ACTION_SUCCESS,
  payload: success,
});

export const orderActionFailed = error => ({
  type: ORDER_ACTION_FAILED,
  payload: error,
});

