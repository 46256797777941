import { find as _find } from 'lodash';
import { createSelector } from 'reselect';

import { RootReducer } from '../rootReducer';

export const userSelector = createSelector(
  (state: RootReducer) => state.user,
  ({ user, error, loading }) => ({ user, error, loading }),
);

export const userOtpSelector = createSelector(
  (state: RootReducer) => state.user,
  ({ otpData }) => ({ otpData }),
);

export const userAddressSelector = createSelector(
  (state: RootReducer) => state.user,
  ({ userAddress }) => ({ ...userAddress }),
);

export const currentRoleSelector = createSelector(
  (state: RootReducer) => state.user,
  ({ user }) => {
    const roles = user?.roles?.map(v => v?.role_slug);
    if (roles?.includes('supplier')) return 'supplier';
    if (roles?.includes('service-provider')) return 'service-provider';
    if (roles?.includes('user')) return 'user';
    if (roles?.includes('insurance-provider')) return 'insurance-provider';
    if (roles?.includes('admin')) return 'admin';
    return roles?.[0];
  },
);
