import { ActionTypeWithCallback, PayloadCallback } from 'store/rootReducer';

export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAILED = 'FETCH_REVIEWS_FAILED';

export const SAVE_REVIEW = 'SAVE_REVIEW';
export const SAVE_REVIEW_SUCCESS = 'SAVE_REVIEW_SUCCESS';
export const SAVE_REVIEW_FAILED = 'SAVE_REVIEW_FAILED';

export const fetchReview = (payload: any) => ({
  type: FETCH_REVIEWS,
  payload,
});

export const fetchReviewSuccess = (payload: any) => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload,
});

export const fetchReviewFailed = (payload: any) => ({
  type: FETCH_REVIEWS_FAILED,
  payload,
});

export const saveReview = (payload: PayloadCallback) => ({
  type: SAVE_REVIEW,
  payload,
});

export const saveReviewSuccess = (payload: any) => ({
  type: SAVE_REVIEW_SUCCESS,
  payload,
});

export const saveReviewFailed = error => ({
  type: SAVE_REVIEW_FAILED,
  payload: error,
});
