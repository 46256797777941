import { ActionTypeWithCallback, PayloadCallback } from 'store/rootReducer';
import { CART_PAYLOAD } from './interfaces';

export const GET_CART = 'GET_CART';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILED = 'GET_CART_FAILED';

export const SAVE_CART = 'SAVE_CART';
export const SAVE_CART_SUCCESS = 'SAVE_CART_SUCCESS';
export const SAVE_CART_FAILED = 'SAVE_CART_FAILED';

export const REMOVE_CART = 'REMOVE_CART';
export const REMOVE_CART_SUCCESS = 'REMOVE_CART_SUCCESS';
export const REMOVE_CART_FAILED = 'REMOVE_CART_FAILED';

export const getCart = (payload?: any) => ({
  type: GET_CART,
  payload,
});

export const getCartSuccess = (payload: any) => ({
  type: GET_CART_SUCCESS,
  payload,
});

export const getCartFailed = (payload: any) => ({
  type: GET_CART_FAILED,
  payload,
});

export const saveCart = (payload: PayloadCallback) => ({
  type: SAVE_CART,
  payload,
});

export const saveCartSuccess = (payload: any) => ({
  type: SAVE_CART_SUCCESS,
  payload,
});

export const saveCartFailed = (payload: any) => ({
  type: SAVE_CART_FAILED,
  payload,
});

export const deleteCart = (payload: { id: number }) => ({
  type: REMOVE_CART,
  payload,
});

export const deleteCartSuccess = (payload: any) => ({
  type: REMOVE_CART_SUCCESS,
  payload,
});

export const deleteCartFailed = (payload: any) => ({
  type: REMOVE_CART_FAILED,
  payload,
});
