import { CitiesPayload, ProvincePayload } from 'store/app/interfaces';
import { Search } from './interfaces';

export const APP_NOTIFICATION = 'APP_NOTIFICATION';

export const FETCH_COUNTRY = 'FETCH_COUNTRY';
export const FETCH_COUNTRY_SUCCESS = 'FETCH_COUNTRY_SUCCESS';
export const FETCH_COUNTRY_FAILURE = 'FETCH_COUNTRY_FAILURE';

export const FETCH_COUNTRY_CODE = 'FETCH_COUNTRY_CODE';
export const FETCH_COUNTRY_CODE_SUCCESS = 'FETCH_COUNTRY_CODE_SUCCESS';
export const FETCH_COUNTRY_CODE_FAILURE = 'FETCH_COUNTRY_CODE_FAILURE';

export const FETCH_PROVINCE = 'FETCH_PROVINCE';
export const FETCH_PROVINCE_SUCCESS = 'FETCH_PROVINCE_SUCCESS';
export const FETCH_PROVINCE_FAILURE = 'FETCH_PROVINCE_FAILURE';

export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE';

export const SAVE_CURRENT_LOCATION = 'SAVE_CURRENT_LOCATION';

export const fetchCountry = (payload: Search) => ({
  type: FETCH_COUNTRY,
  payload,
});

export const fetchCountrySuccess = (payload: any) => ({
  type: FETCH_COUNTRY_SUCCESS,
  payload,
});

export const fetchCountryFailure = (payload: any) => ({
  type: FETCH_COUNTRY_FAILURE,
  payload,
});

export const fetchCountryCode = (payload: Search) => ({
  type: FETCH_COUNTRY_CODE,
  payload,
});

export const fetchCountryCodeSuccess = (payload: any) => ({
  type: FETCH_COUNTRY_CODE_SUCCESS,
  payload,
});

export const fetchCountryCodeFailure = (payload: any) => ({
  type: FETCH_COUNTRY_CODE_FAILURE,
  payload,
});

export const fetchProvince = (payload: ProvincePayload) => ({
  type: FETCH_PROVINCE,
  payload,
});

export const fetchProvinceSuccess = (payload: any) => ({
  type: FETCH_PROVINCE_SUCCESS,
  payload,
});

export const fetchProvinceFailure = (payload: any) => ({
  type: FETCH_PROVINCE_FAILURE,
  payload,
});

export const appNotification = ({ type, message }) => ({
  type: APP_NOTIFICATION,
  payload: { type, message },
});

export const saveCurrentLocation = ({ currentLocation }) => ({
  type: SAVE_CURRENT_LOCATION,
  payload: { currentLocation },
});

export const fetchCities = (payload: CitiesPayload) => ({
  type: FETCH_CITIES,
  payload,
});

export const fetchCitiesSuccess = (payload: any) => ({
  type: FETCH_CITIES_SUCCESS,
  payload,
});

export const fetchCitiesFailure = (payload: any) => ({
  type: FETCH_CITIES_FAILURE,
  payload,
});
