import dayjs from 'dayjs';
import { differenceBy as _differenceBy, values } from 'lodash';
const countries = require('../json/countryCodeTz.json');

// import { monthOptions } from 'components/DatePicker/constants';
import i18n from 'config/i18n';
// import { ColoredText } from 'pages/Securities/Securities.styled';
import { Benchmark } from 'store/user/interfaces';
import { COLORS } from 'theme/colors';
import localforage from 'localforage';
import { useHistory } from 'react-router-dom';

export const emailRegex = /^[\w\-.+]+@([\w-]+\.)+[\w-]{2,4}$/;
export const passwordRegex = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/;
export const numberRegex = /^[0-9]*$/;

export const objectKeysToArray = (obj: any) => Object.keys(obj).map(key => key);

export const validateMinMax = (lt: string, gt: string) => parseInt(lt) < parseInt(gt);

export const roundNumber = (value: number, decimals?: number) => {
  if (typeof value === 'number') {
    if (decimals) return value.toFixed(decimals);
    return Math.round(value).toLocaleString('en-US');
  }

  return i18n.t('general.calculating');
};

export const findBenchmarkName = (uuid: string, benchmarks: Benchmark[]) => {
  if (benchmarks) {
    const benchmark = benchmarks?.find(item => item.uuid === uuid);
    return benchmark?.name;
  }

  return '';
};

export const checkIfValueExists = (object: any) => {
  const array = Object.values(object);
  let hasValue = false;

  array.map((item: any) => {
    if (item?.gte || item?.lte) {
      hasValue = true;
    }
    if (item.length > 0) hasValue = true;
  });
  return hasValue;
};

export const formatDropdownData = (array: any[], key?: string, value?: string) => {
  if (!key) {
    const formattedData = array?.map((item: string, index: number) => ({
      key: `${item}-${index}`,
      label: item?.toLowerCase().replace(/\_/g, ' '),
      value: item,
    }));
    return formattedData;
  }

  if (array) {
    const itemValue = value ? value : key;
    const formattedData = array?.map((item, index) => ({
      key: item[itemValue],
      label: item[key],
      value: item[itemValue],
    }));
    return formattedData;
  }

  return [];
};

export const formatPrice = (price: number, currencyCode: string) => {
  if (price) {
    return `${price.toFixed(2)} ${currencyCode}`;
  }

  return i18n.t('general.calculating');
};

// export const formatAbsoluteChange = (absoluteChange: number) => {
//   const roundedChange = parseFloat(absoluteChange?.toFixed(2));

//   if (roundedChange > 0.00) {
//     return <ColoredText color={COLORS.green}>{`+${roundedChange}`}</ColoredText>;
//   }
//   if (roundedChange < 0.00) {
//     return <ColoredText color={COLORS.red}>{roundedChange}</ColoredText>;
//   }
//   if (roundedChange === 0.00) {
//     return <ColoredText color={COLORS.greyBlue}>0.00</ColoredText>;
//   }

//   return <ColoredText color={COLORS.greyBlue}>/</ColoredText>;
// };

export const formatPercentage = (value: number, nanReturn = i18n.t('general.calulating')) => {
  if (value) return (value * 100).toFixed(2);
  return nanReturn;
};

export const watchIsSelected = (subsetOf: any[], subset: any[], comparator: string) => {
  const diff = _differenceBy(subset, subsetOf, comparator);
  if (!subsetOf || !subset) return false;
  if (diff.length === 0) return true;
  if (diff.length > 0) return false;
};

export const boldTextByReg = (value: string, term: string) => {
  const regex = new RegExp(term.toLowerCase());
  const boldedText = value.toLowerCase().replace(regex, `<span>${term}</span>`);

  return boldedText;
};

export const calcPercentage = (value: number) => {
  if (typeof value === 'number') {
    return `${(value * 100).toFixed(2)}%`;
  }

  return i18n.t('general.calculating');
};

export const formatValue = (value: number) => {
  if (typeof value === 'number') {
    return `$${roundNumber(value)}`;
  }

  return '/';
};

export const isArrowUp = (value: number) => {
  if (typeof value === 'number') {
    const roundedValue = parseFloat(value.toFixed(2));
    if (roundedValue >= 0.0) return true;
    else return false;
  }

  return '/';
};

export const calcTooltipDifference = (a: number, b: number) => {
  if (a && b) {
    return (a - b).toFixed(2);
  }

  return '/';
};

export const setItem = async (key: string, value: any) => {
  try {
    const v = await localforage.setItem(key, value);
    // This code runs once the value has been loaded
    // from the offline store.
    console.log(v);
    return v;
  } catch (err) {
    // This code runs if there were any errors.
    console.log(err);
  }
};
export const getItem = async (key: string) => {
  try {
    const v = await localforage.getItem(key);
    // This code runs once the value has been loaded
    // from the offline store.
    return v;
  } catch (err) {
    // This code runs if there were any errors.
    console.log(err);
  }
};

export const deleteItem = async (key: string) => {
  try {
    const v = await localforage.removeItem(key);
    // This code runs once the value has been loaded
    // from the offline store.
    return v;
  } catch (err) {
    // This code runs if there were any errors.
    console.log(err);
  }
};
export const clearAllItems = async () => {
  try {
    const v = await localforage.clear();
    // This code runs once the value has been loaded
    // from the offline store.
    return v;
  } catch (err) {
    // This code runs if there were any errors.
    console.log(err);
  }
};

export const toBase64 = async (files: File[], onlyBase64 = false) => {
  const allPromises = files.map(
    (file: File) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        if (onlyBase64) reader.onload = () => resolve(reader.result);
        else
          reader.onload = () =>
            resolve({ name: file.name, size: file.size, type: file.type, base64: reader.result });

        reader.onerror = error => reject(error);
      }),
  );
  return Promise.all(allPromises).then(result => result);
};

export const formatArrayField = (data, fieldName, idField = undefined, renderValue?) => {
  const s = {};
  if (data) {
    let newData = data;
    if (typeof data === 'object') newData = Object.keys(data);
    if (idField === 'INDEX') s[`${fieldName}`] = [];
    newData.forEach((k: string, i) => {
      if (idField === 'INDEX') {
        const str = `${k}`;
        const indexdata = str.match(/\[(.*)\]/);
        const index = typeof indexdata[1] === 'string' ? parseInt(indexdata[1]) : indexdata[1];
        s[`${fieldName}`][i] = data?.[k];
      } else {
        s[`${fieldName}[${i}]`] = data[k]?.[idField]
          ? data[k]?.[idField]
          : renderValue
          ? renderValue(data[k], i)
          : data[k];
      }
    });
  } else s[`${fieldName}[]`] = null;
  return s;
};

export const getFormData = object =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

export const getCountryCode = async () => {
  try {
    const tzAsync = await fetch('http://ip-api.com/json');
    const result = await tzAsync.json();
    console.log('ipaip', result);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (countries[timezone]) {
      return countries[timezone];
    }
  } catch (error) {}
};

export const tryParseJSONObject = jsonString => {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return false;
};

export const timeDropdownOption = (day?: string) => {
  const options = [];
  for (let i = 0; i < 48; i++) {
    const label = dayjs('2021-12-12T00:00:00')
      .add(i * 30, 'minutes')
      .format('hh:mm A');
    const value = dayjs('2021-12-12T00:00:00')
      .add(i * 30, 'minutes')
      .format('HH:mm:ss');
    if (day) options.push({ label, value, day });
    else options.push({ label, value });
  }
  return options;
};

export const getFilePath = (path, filePathKey = 'filePath') =>
  path?.length ? path?.map(photo => photo?.[filePathKey])?.filter(v1 => v1) : null;
