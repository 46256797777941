import { DropdownOption } from 'components/Dropdown/Dropdown.interfaces';
import Notifications from 'components/Notifications/Notifications';
import SearchDropdown from 'components/SearchDropdown/SearchDropdown';
import Spinner from 'components/Spinner/Spinner';
import { useModal } from 'hooks/useModal';
import { debounce } from 'lodash';
import { closeNav, openNav } from 'pages/Home/custom';
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Dropdown, Modal, ModalBody, Overlay } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getUserSession } from 'services/auth';
import { clearAllItems, deleteItem, formatDropdownData, getItem, setItem } from 'services/utils';
import { fetchCities, fetchProvince, saveCurrentLocation } from 'store/app/actions';
import { IResponse, IResult } from 'store/app/interfaces';
import { locationSelector, provinceSelector } from 'store/app/selectors';
import { cartCount } from 'store/cart/selectors';
import { logoutUser } from 'store/user/actions';
import { userSelector } from 'store/user/selectors';
import './header.scss';
import { ReactComponent as NotifcationIcon } from '../../assets/icons/bell.svg';
import { ReactComponent as LiveFeedIcon } from '../../assets/icons/live-feed.svg';

import HeaderMobileView from './HeaderMobileView';
import { liveFeedCountSelector, notificationCountSelector } from 'store/notifications/selectors';
import OutsideWrapper from 'components/OutsideWrapper/OutsideWrapper';
import { pointsHistorySelector, totalPointSelector } from 'store/pointHistory/selectors';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
interface IHeaderProps {
  fixedHeader?: boolean;
  onMobileSearchClick?: any;
  toggleHeaderOnScrolling?: boolean;
  openSideNav?: boolean;
  toggleSideNav?;
  noContainer?: boolean;
}
const Header = ({
  fixedHeader = false,
  onMobileSearchClick,
  toggleHeaderOnScrolling = true,
  openSideNav = false,
  toggleSideNav,
  noContainer = false,
}: IHeaderProps) => {
  // const user = getUserSession();
  const sm = useMediaQuery({ minWidth: 992 });
  const {
    open: notificationOpen,
    setOpen: setNotificationOpen,
    onClose: onCloseNotification,
    onOpen: onOpenNotification,
  } = useModal();
  const { loading: userLoading, user } = useSelector(userSelector);
  const { data: totalPoints } = useSelector(totalPointSelector);
  const notficationDropDownRef = useRef(null);
  const isSupplier = user?.roles?.find(v => v?.role_slug === 'supplier');
  const notificationCount = useSelector(notificationCountSelector);
  const [scrolling, setScrolling] = useState(fixedHeader);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const { currentLocation, ...locations } = useSelector(locationSelector);
  const { data: provinceData } = useSelector(provinceSelector);
  const { cartItemCount } = useSelector(cartCount);
  const { liveFeedCount } = useSelector(liveFeedCountSelector);
  const [show, setShow] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const toggleLocation = useCallback(e => {
    console.log('show');

    setShow(!show);
  }, []);
  const handleClose = () => {
    setShow(false);
  };
  const handleLoginModalClose = () => {
    setShowLoginModal(false);
  };

  const handleSelectLocaltion = useCallback(
    async (optionValue: any) => {
      if (locations?.data?.length) {
        const location: any = await getItem('location');
        const option = locations?.data?.find(v => v.id === optionValue?.target?.value);
        dispatch(saveCurrentLocation({ currentLocation: option }));
        setDefaultLocation(option);
        if (option?.id !== location?.id) setShow(false);
      }
    },
    [locations?.data, show],
  );

  const handleLocationClose = e => {
    handleSelectLocaltion(e);
    // setTimeout(() => handleClose(), 500);
  };

  const getDefaultLocation = useCallback(async () => {
    const location: any = currentLocation || (await getItem('location'));
    if (location?.id) setDefaultLocation(location);
  }, []);
  const logout = () => {
    dispatch(logoutUser(history, user?.token));
  };
  const navigateTo = (route: string) => (e: any) => history.push(route);

  useLayoutEffect(() => {
    if (!fixedHeader) {
      const scrollDemo = document.querySelector('#main_searchBar');
      const onScroll = (e: any) => {
        // setScrollTop(e.target.documentElement.scrollTop);
        let s = scrollDemo?.getBoundingClientRect()?.top || 0;
        setScrolling(s < 0);
      };
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, []);
  useEffect(() => {
    if (provinceData === null) dispatch(fetchProvince({ countryId: 234, search: '' }));
    getDefaultLocation();
  }, [provinceData]);

  return (
    <div className="header-root">
      <div className={noContainer ? 'px-2' : 'container'}>
        {sm ? (
          <div className="desktop_menu d-none d-lg-block">
            <nav className="navbar navbar-expand-lg">
              <div className="header_left">
                <Link to={ROUTES.HOME}>
                  <Logo style={{ maxWidth: '70%', width: '70%' }} />
                </Link>
                {
                  <SearchDropdown
                    containerClass="abu-dropdown"
                    style={{ fontWeight: 700 }}
                    options={locations?.data}
                    loading={locations?.loading}
                    optionLabel="provinceName"
                    optionValue="id"
                    name="location"
                    placeholder="Select Location"
                    onChange={handleSelectLocaltion}
                    defaultOption={defaultLocation}
                  />
                }
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <form className="form-inline my-2 ms-auto my-lg-0">
                  <div className="header_right">
                    {user ? (
                      <>
                        {toggleSideNav ? null : (
                          <Link to={`${isSupplier ? ROUTES.RFQ_RESPONSE_LIST : ROUTES.RFQ_LIST}`}>
                            <span>Dashboard</span>
                          </Link>
                        )}
                        {isSupplier ? (
                          <Link to={`${ROUTES.LIVE_FEED}`}>
                            <span className="cart">
                              <LiveFeedIcon height={24} width={24} />
                              <span className="badge bg-secondary text-white">{liveFeedCount}</span>
                            </span>
                            <div className="ms-3"></div>
                          </Link>
                        ) : (
                          <>
                            <div
                              onClick={navigateTo(ROUTES.POINT_HISTORY)}
                              className="mx-2 p-1 d-flex align-items-center"
                              style={{ borderRadius: 16, border: '1px solid', cursor: 'pointer' }}>
                              <img src={require('../../assets/images/star.png')} />
                              <span className="font-size-11">
                                <b>{totalPoints}</b>
                              </span>
                            </div>
                            <Link to={`${ROUTES.CART}`}>
                              <span className="cart">
                                <img
                                  src={require('../../assets/images/carticon.png')}
                                  style={{ width: 22, height: 22 }}
                                />
                                <span className="badge bg-secondary text-white">
                                  {cartItemCount}
                                </span>
                              </span>
                            </Link>
                          </>
                        )}

                        {/* <div style={{ position: 'relative' }}>
                          <div
                            className="notification-icon-container"
                            onClick={e => setNotificationOpen(!notificationOpen)}>
                            <NotifcationIcon width={22} height={22} />
                            <span className="badge bg-secondary text-white notification-badge">
                              {notificationCount}
                            </span>
                          </div>
                          {notificationOpen && (
                            <div style={{ position: 'absolute' }}>
                              <OutsideWrapper handleOutsideClick={onCloseNotification}>
                                <Notifications />
                              </OutsideWrapper>
                            </div>
                          )}
                        </div> */}
                        <Dropdown>
                          <Dropdown.Toggle variant="flat" id="dropdown-basic">
                            <div className="notification-icon-container">
                              <NotifcationIcon width={22} height={22} />
                              <span className="badge bg-secondary text-white notification-badge">
                                {notificationCount}
                              </span>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>{<Notifications />}</Dropdown.Menu>
                        </Dropdown>
                      </>
                    ) : (
                      <Link to={`${ROUTES.REGISTER_BUSINESS}`} className="me-2">
                        <span>Register your business</span>
                      </Link>
                    )}

                    <div className="dropdown ">
                      <button
                        className="btn btn-secondary dropdown-toggle btn-menu"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        {/* <i className="fa fa-bars" aria-hidden="true"></i> */}
                        <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                      </button>

                      <div
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton">
                        {user ? (
                          <>
                            {user?.id && (
                              <div className="dropdown-item text-secondary d-flex align-items-center">
                                <Link to={ROUTES.PROFILE} className="link">
                                  <div className="dropdown-menu-avatar">
                                    <span className="text-white">{(user?.name).slice(0, 1)}</span>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="text-secondary">{user?.name}</span>
                                    <span className="text-secondary" style={{ fontSize: 11 }}>
                                      {user?.phoneCode}-
                                      {user?.phoneNumber &&
                                        [...Array(user?.phoneNumber?.length - 4)].map(v => '*')}
                                      {user?.phoneNumber && user?.phoneNumber.slice(-4)}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            )}
                            <hr className="dropdown-divider"></hr>
                            <Button
                              style={{ textTransform: 'capitalize' }}
                              className="dropdown-item"
                              variant="link"
                              onClick={logout}>
                              <span className="text-secondary">Logout</span>
                            </Button>
                          </>
                        ) : (
                          <>
                            <Link className="dropdown-item" to={ROUTES.LOGIN}>
                              Login
                            </Link>
                            {/* <Link className="dropdown-item" to={ROUTES.SIGNUP}>
                              Sign up
                            </Link> */}
                          </>
                        )}
                      </div>
                    </div>

                    <img
                      src={require('../../assets/images/en_icon.png')}
                      className="img-fluid ms-3"
                    />
                  </div>
                </form>
              </div>
            </nav>
          </div>
        ) : (
          <HeaderMobileView
            toggleSideNav={toggleSideNav}
            isSupplier={isSupplier}
            cartItemCount={cartItemCount}
            isLoggedIn={!!user}
            notificationOpen={notificationOpen}
            onCloseNotification={onCloseNotification}
            onOpenNotification={onOpenNotification}
            notificationCount={notificationCount}
            liveFeedCount={liveFeedCount}
            totalPoints={totalPoints}
            navigateTo={navigateTo(ROUTES.POINT_HISTORY)}
          />
        )}
      </div>

      <div
        className={`header_two main-div ${
          scrolling && toggleHeaderOnScrolling ? 'header_two_show' : 'd-none'
        }`}>
        <div className="p-2 header-info">
          <div className={noContainer ? '' : 'container'}>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-3 col-8">
                <div className="header_two_logo">
                  <Link to={ROUTES.HOME}>
                    <Logo style={{ maxWidth: '70%', width: '70%' }} />
                  </Link>
                </div>
              </div>
              <div className="col-md-7 col-4">
                <div className="header_two_searchbar">
                  <div className="searchBar">
                    <div className="form-group has-search mb-0">
                      <span className="fa fa-search form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search for spare parts"
                      />
                    </div>
                  </div>
                </div>
                <div className="mobileSeachbar">
                  <a href="#search" onClick={onMobileSearchClick}>
                    <i className="fa fa-search"></i>
                  </a>
                </div>
              </div>
              <div className="col-md-2">
                <div className="header_right align-items-center justify-content-center text-right d-flex">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle btn-menu"
                      type="button"
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      {/* <i className="fa fa-bars" aria-hidden="true"></i> */}
                      <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton2">
                      {user ? (
                        <>
                          {user?.id && (
                            <div className="dropdown-item text-secondary d-flex align-items-center">
                              <Link to={ROUTES.PROFILE} className="link">
                                <div className="dropdown-menu-avatar">
                                  <span className="text-white">{(user?.name).slice(0, 1)}</span>
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="text-secondary">{user?.name}</span>
                                  <span className="text-secondary" style={{ fontSize: 11 }}>
                                    {user?.phoneCode}-
                                    {user?.phoneNumber &&
                                      [...Array(user?.phoneNumber?.length - 4)].map(v => '*')}
                                    {user?.phoneNumber && user?.phoneNumber.slice(-4)}
                                  </span>
                                </div>
                              </Link>
                            </div>
                          )}
                          <hr className="dropdown-divider"></hr>
                          <Button
                            style={{ textTransform: 'capitalize' }}
                            className="dropdown-item"
                            variant="link"
                            onClick={logout}>
                            <span className="text-secondary">Logout</span>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Link className="dropdown-item" to={ROUTES.LOGIN}>
                            Login
                          </Link>
                          {/* <Link className="dropdown-item" to={ROUTES.SIGNUP}>
                            Sign up
                          </Link> */}
                        </>
                      )}
                    </div>
                  </div>

                  <img
                    src={require('../../assets/images/en_icon.png')}
                    className="img-fluid ms-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* user menu dropdown */}
      <Modal
        show={showLoginModal}
        onHide={handleLoginModalClose}
        className="header-root"
        style={{ backgroundColor: 'transparent' }}>
        <ModalBody className="uerMenu" style={{ padding: '0 10px 10px 0' }}>
          <div className="userloginLinks text-right">
            {user ? (
              <>
                {user?.id && (
                  <div className="dropdown-item text-secondary d-flex align-items-center">
                    <Link to={ROUTES.PROFILE} className="link">
                      <div className="dropdown-menu-avatar">
                        <span className="text-white">{(user?.name).slice(0, 1)}</span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="text-secondary">{user?.name}</span>
                        <span className="text-secondary" style={{ fontSize: 11 }}>
                          {user?.phoneCode}-
                          {user?.phoneNumber &&
                            [...Array(user?.phoneNumber?.length - 4)].map(v => '*')}
                          {user?.phoneNumber && user?.phoneNumber.slice(-4)}
                        </span>
                      </div>
                    </Link>
                  </div>
                )}
                <hr className="dropdown-divider"></hr>

                <div className="dropdown-item text-secondary d-flex align-items-center">
                  <Link
                    to={`${isSupplier ? ROUTES.RFQ_RESPONSE_LIST : ROUTES.RFQ_LIST}`}
                    className="text-black">
                    Dashboard
                  </Link>
                </div>
                <hr className="dropdown-divider"></hr>
                <Button
                  style={{ textTransform: 'capitalize' }}
                  className="dropdown-item"
                  variant="link"
                  onClick={logout}>
                  <span className="text-secondary">Logout</span>
                </Button>
              </>
            ) : (
              <>
                <Link className="dropdown-item" to={ROUTES.LOGIN}>
                  <span className="text-primary-2">
                    <b>Login</b>
                  </span>
                </Link>
                {/* <Link className="dropdown-item" to={ROUTES.SIGNUP}>
                  <span className="text-primary-2">
                    <b>Sign up</b>
                  </span>
                </Link> */}
                <Link className="dropdown-item" to={`${ROUTES.REGISTER_BUSINESS}`}>
                  <span className="text-primary-2">
                    <b>Register your business</b>
                  </span>
                </Link>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
      {/* location dropdown */}
      <Modal
        show={show}
        onHide={handleClose}
        className="header-root"
        style={{ backgroundColor: 'transparent' }}>
        <ModalBody>
          <SearchDropdown
            containerClass="abu-dropdown"
            style={{ fontWeight: 700 }}
            options={locations?.data}
            loading={locations?.loading}
            optionLabel="provinceName"
            optionValue="id"
            name="location"
            placeholder="Select Location"
            onChange={handleSelectLocaltion}
            defaultOption={defaultLocation}
          />
        </ModalBody>
      </Modal>
      {userLoading && (
        <Modal fullscreen show={userLoading} centered>
          <Spinner />
        </Modal>
      )}
    </div>
  );
};

export default memo(Header);
