import { createGlobalStyle } from 'styled-components';

import { COLORS } from './colors';

const GlobalStyle = createGlobalStyle`
  #root {

  }
  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    font-family: 'DM Sans', sans-serif;
    margin: 0;
    background:#F2F2F2 !important;
  }
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem !important;
  }
  *:focus {
    outline: none;
  }

  a {
    text-decoration: none;
    :hover,
    :focus {
      text-decoration: underline;
    }
  }



  ul {
    margin: 0;
    list-style: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  div, ul {
    // ::-webkit-scrollbar {
    //   width: 6px;
    //   height: 6px;
    //   background: transparent;
    // }

    // ::-webkit-scrollbar-thumb {
    //   background: ${COLORS.inputField};
    //   border-radius: 20px;
    // }
  }
  .btn-block {
    display: block;
    width: 100%;
  }
  ::-webkit-input-placeholder {
    color: #7b7678;
  }
  .btn-primary {
    background-color: ${COLORS.primary};
    color: white;
    :hover {
      background-color: ${COLORS.secondary};
    }
  }

  .btn-secondary {
    background-color: ${COLORS.secondary};
    color: white;
    :hover {
      background-color: ${COLORS.primary};
    }
  }
  .text-primary {
    color:${COLORS.primary} !important
  }
  .text-primary-2 {
    color:${COLORS.secondary} !important
  }
  .bg-primary{
    background-color:${COLORS.primary} !important
  }
  .bg-secondary{
    background-color:${COLORS.secondary} !important
  }
`;

export default GlobalStyle;
