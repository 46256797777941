import passwordComplexity from 'joi-password-complexity';

import Joi, { Root } from '../services/joi/joi';

class FormValidationRules {
  private _email: Root;

  private _name: Root;

  private _password: Root;

  private _termsOfService: Root;

  private _country: Root;

  private _optionalField: Root;

  get email() {
    return Joi.string()
      .email({ tlds: { allow: false } })
      .label('Email');
  }

  set email(value) {
    FormValidationRules.throwSetError();
  }

  get name() {
    return Joi.string()
      .min(3)
      .max(30)
      .pattern(/^[a-zA-Z ]*$/)
      .required()
      .messages({
        'string.pattern.base': 'Should contains letters only',
      });
  }

  set name(value) {
    FormValidationRules.throwSetError();
  }

  get businessName() {
    return Joi.string()
      .pattern(/^[a-zA-Z ]*$/)
      .required()
      .messages({
        'string.pattern.base': 'Should contains letters only',
      });
  }

  set businessName(value) {
    FormValidationRules.throwSetError();
  }

  get phoneNumber() {
    return Joi.string()
      .min(7)
      .max(11)
      .pattern(/^[0-9]*$/)
      .messages({
        'string.pattern.base': 'Should contains numbers only',
      });
  }

  set phoneNumber(value) {
    FormValidationRules.throwSetError();
  }

  get phoneCode() {
    return Joi.number().min(1).max(999).required().messages({
      'any.empty': '"" is a required field',
      'number.base': 'Should contains numbers only',
    });
  }

  set phoneCode(value) {
    FormValidationRules.throwSetError();
  }

  get password() {
    return passwordComplexity({
      min: 8,
      max: 25,
      lowerCase: 1,
      upperCase: 1,
      numeric: 1,
      symbol: 1,
      requirementCount: 4,
    }).label('Password');
  }

  set password(value) {
    FormValidationRules.throwSetError();
  }

  get pin() {
    return passwordComplexity({
      min: 6,
      max: 6,
      numeric: 1,
    })
      .required()
      .label('Pin');
  }

  set pin(value) {
    FormValidationRules.throwSetError();
  }

  get pin_confirmation() {
    return Joi.any()
      .equal(Joi.ref('pin'))
      .required()
      .label('Confirm Pin')
      .messages({ 'any.only': '{{#label}} does not match' });
  }

  set pin_confirmation(value) {
    FormValidationRules.throwSetError();
  }

  get termsOfService() {
    return Joi.boolean().valid(true).required().messages({
      'any.only': 'Please confirm that you have read the Terms and Conditions',
    });
  }

  set termsOfService(value) {
    FormValidationRules.throwSetError();
  }

  get country() {
    return Joi.string()
      .pattern(/^[a-zA-Z]{2,}$/)
      .allow('')
      .message('Invalid entry');
  }

  set country(value) {
    FormValidationRules.throwSetError();
  }

  get optionalField() {
    return Joi.allow('', 0);
  }

  set optionalField(value) {
    FormValidationRules.throwSetError();
  }

  get otp() {
    return Joi.string()
      .length(6)
      .pattern(/^[0-9]+$/)
      .required()
      .messages({
        'string.pattern.base': 'Invalid code',
        'string.length': 'Invalid code',
      })
      .label('Otp');
  }

  set otp(value) {
    FormValidationRules.throwSetError();
  }

  private static throwSetError(): void {
    throw new Error("It's not possible to set this field");
  }
}

const formValidationRules = new FormValidationRules();
export default formValidationRules;
