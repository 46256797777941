import styled from 'styled-components';

import { COLORS } from 'theme/colors';

interface InputElementProps {
  hasError: boolean;
  hasIcon: boolean;
  disabled?: boolean;
}

interface LabelProps {
  disabled?: boolean;
}

export const InputContainer = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  pointer-events: none;
  position: relative;
`;

export const InputElement = styled.input<InputElementProps>`
  // background: ${COLORS.background1};
  border: 1px solid transparent;
  border-color: ${({ hasError }) => hasError && COLORS.red};
  border-radius: 25px;
  box-sizing: border-box;
  caret-color: ${COLORS.grayDark};
  color: ${({ disabled }) => disabled ? COLORS.grayDark : COLORS.kiskiBlack};
  font-size: 14px;
  font-weight: 400;
  height: 50px;
  padding-left: ${({ hasIcon }) => hasIcon ? '45px' : '20px'};
  pointer-events: auto;
  width: 326px;

  :-webkit-autofill{
    -webkit-text-fill-color: ${COLORS.kiskiBlack} !important;
    box-shadow: 0 0 0 30px ${COLORS.background1} inset !important;
    border-color: ${({ hasError }) => hasError && COLORS.red};
  }

  ::placeholder {
    color: ${COLORS.grayDark};
  }
`;

export const Label = styled.span<LabelProps>`
  color: ${COLORS.black};
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 12px 26px;
  opacity: ${({ disabled }) => disabled ? '0.3' : '1'};
`;

export const Error = styled.span.attrs({ className: 'error' })`
  color: ${COLORS.red};
  font-size: 14px;
  height: 14px;
  line-height: 1;
  margin: 10px 0 0;
  overflow: hidden;
  padding-left: 6px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: wrap;
`;

export const EyeButton = styled.button`
  background: transparent;
  display: flex;
  pointer-events: auto;
  position: absolute;
  right: 20px;
  top: 42px;
`;

export const IconContainer = styled.button.attrs({
  type: 'button',
  tabIndex: -1,
  className: 'input-icon',
})`
  background: transparent;
  height: 14px;
  pointer-events: auto;
  position: absolute;
  width: 14px;
  left: 20px;
`;

export const InputBox = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;
