import Cookie from 'js-cookie';

/**
 * Sets the session
 * @param {*} user
 */
export const saveUserSession = (user: any) => {
  if (user) Cookie.set('user', JSON.stringify(user), { path: '/' });
  else Cookie.remove('user', { path: '/' });
};
export const getUserSession = () => {
  const user = Cookie.get('user');
  return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export const getUserRole = () => {
  const user = Cookie.get('user');
  const currentUser = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
  if (currentUser) {
    const currentRole = currentUser?.roles?.map(v => v?.role_slug);
    if (currentRole?.includes('supplier')) return 'supplier';
    if (currentRole?.includes('service-provider')) return 'service-provider';
    if (currentRole?.includes('user')) return 'user';
    if (currentRole?.includes('insurance-provider')) return 'insurance-provider';
    if (currentRole?.includes('admin')) return 'admin';
    return currentRole?.[0];
  }
  return null;
};
export const getCsrfToken = () => {
  const csrfToken = Cookie.get('XSRF-TOKEN');
  return { csrfToken };
};
export const clearUserSession = () => {
  Cookie.remove('user');
  Cookie.remove('XSRF-TOKEN');
};

export const isUserAuthenticated = () => {
  const user = getUserSession();
  if (!user) {
    return false;
  }
  return !!user.token;
  // const decoded = (user.token);
  // const currentTime = Date.now() / 1000;
  // if (decoded.exp < currentTime) {
  //     console.warn('access token expired');
  //     return false;
  // } else {
  //     return true;
  // }
};

export const getLoggedInUserRoles = () => {
  const user = Cookie.get('user');
  const u = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
  if (u) {
    return u?.roles?.map(v => v?.role_slug);
  }
  return [];
};
