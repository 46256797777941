import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { getRoles, getRolesFailed, getRolesSuccess, GET_ROLES } from './actions';
import { fetchRoles } from './api';
import { IRoles } from './interfaces';

function* getRolesSaga() {
  try {
    const data: IRoles = yield call(fetchRoles);
    yield put(getRolesSuccess(data));
  } catch (error) {
    let message = 'Failed to get user roles';
    yield put(getRolesFailed(message));
  }
}

export function* watchGetRoles() {
  yield takeLatest(GET_ROLES, getRolesSaga);
}

function* rolesSaga() {
  yield all([fork(watchGetRoles)]);
}

export default rolesSaga;
