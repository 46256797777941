import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/animation.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import './assets/scss/theme.scss';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store/store';
import Spinner from 'components/Spinner/Spinner';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Spinner animation="border" />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
