// @todo Communicate with design team and name colors properly

export const COLORS = {
  // Colors from FIGMA
  orange: '#FF896F',
  lightOrange: '#FFCDBB',
  red: '#F6694A',
  lightGreen: '#5FD1A8',
  green: '#2ABC87',
  purple: '#906BEB',
  lightPurple: '#C7B1FC',
  grayDark: '#6E6464',
  greyBlue: '#7A7A98',
  lightGrayBlue: '#9797AC',
  background3: '#F7F4F4',
  background2: '#F5F1F0',
  background1: '#ECE5E3',
  inputFieldBg: '#e9eff6',
  inputField: '#707070',
  darker: '#C1AFAA',
  opt2: '#2E876C',
  kiskiBlack: '#0B0B14',
  gray: '#EDE5E3',
  blackLight: '#413B3A',
  tableBoxShadow: '#F0EBEB',
  regularColumnText: '#5A5655',
  grayLight: '#DCDAD7',
  disabled: '#CCCCCC',
  nameBlack: '#1E233A',
  dividerGray: '#E6E4F1',
  lightGreyBlue: '#656582',
  lightGray: '#66667d',

  // Basic colors
  black: '#000000',
  white: '#ffffff',
  primary: '#244e77',
  secondary: '#be3850',
};

export type COLORSTYPE = typeof COLORS;
