import { Redirect } from 'react-router-dom';

import { ROUTES } from 'routes/constants';
import { getUserSession } from 'services/auth';

const DefaultRoute = () => {
  const user = getUserSession();

  return user ? (
    <Redirect to={{ pathname: ROUTES.HOME }} exact />
  ) : (
    <Redirect to={{ pathname: ROUTES.HOME }} exact />
  );
};

export default DefaultRoute;
