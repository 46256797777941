import { Redirect, Route } from 'react-router-dom';

import { ROUTES } from 'routes/constants';
import { getUserSession } from 'services/auth';

interface Props {
  children: JSX.Element;
  path: string;
  exact?: boolean;
}

const PublicRoute = ({ children, path, exact = false }: Props) => {
  const user = getUserSession();
  console.log('path', path);

  if (user) {
    return <Redirect to={{ pathname: ROUTES.HOME }} exact={exact} />;
  }
  return (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  );
};

export default PublicRoute;
