import {
  GET_ALL_NOTIFICATION,
  GET_ALL_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATION_FAILED,
  MARK_READ_NOTIFICATION,
  MARK_READ_NOTIFICATION_SUCCESS,
  MARK_READ_NOTIFICATION_FAILED,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_FAILED,
  DELETE_NOTIFICATION_SUCCESS,
  ADD_NEW_NOTIFICATION,
  GET_LIVE_FEED,
  GET_LIVE_FEED_SUCCESS,
  GET_LIVE_FEED_FAILED,
  ADD_NEW_LIVE_FEED,
} from './actions';
import { INotificationReducer } from './interfaces';

const initialState: INotificationReducer = {
  notifications: {
    data: [],
    error: null,
    loading: false,
  },
  liveFeed: {
    data: [],
    error: null,
    loading: false,
  },
  notificationLoading: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: true,
        },
      };
    case GET_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_ALL_NOTIFICATION_FAILED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: action.payload,
          error: action.payload,
          loading: false,
        },
      };
    case GET_LIVE_FEED:
      return {
        ...state,
        liveFeed: {
          ...state.liveFeed,
          loading: true,
        },
      };
    case GET_LIVE_FEED_SUCCESS:
      return {
        ...state,
        liveFeed: {
          ...state.liveFeed,
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case GET_LIVE_FEED_FAILED:
      return {
        ...state,
        liveFeed: {
          ...state.liveFeed,
          data: action.payload,
          error: action.payload,
          loading: false,
        },
      };

    case MARK_READ_NOTIFICATION:
      return {
        ...state,
        notificationLoading: true,
      };
    case MARK_READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: {
          data: state?.notifications?.data?.map(v =>
            v?.id === action?.payload?.id ? action?.payload : { ...v },
          ),
        },
        notificationLoading: false,
      };
    case MARK_READ_NOTIFICATION_FAILED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          error: action.payload,
        },
        notificationLoading: false,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notificationLoading: true,
      };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: {
          data: state?.notifications?.data?.filter(v => v?.id !== action?.payload?.id),
          error: null,
          loading: false,
        },
        notificationLoading: false,
      };

    case DELETE_NOTIFICATION_FAILED:
      return {
        ...state,
        notificationLoading: false,
        notifications: {
          data: state?.notifications?.data?.filter(v => v?.id !== action?.payload?.id),
          error: null,
          loading: false,
        },
        error: action?.payload,
      };
    case ADD_NEW_NOTIFICATION:
      return {
        ...state,
        notificationLoading: false,
        notifications: !!state?.notifications
          ? { data: [action?.payload, ...state?.notifications?.data] }
          : [action?.payload],
      };
    case ADD_NEW_LIVE_FEED:
      return {
        ...state,
        notificationLoading: false,
        liveFeed: !!state?.liveFeed
          ? { data: [action?.payload, ...state?.liveFeed?.data] }
          : [action?.payload],
      };

    default:
      return state;
  }
};
