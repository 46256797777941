import { find as _find } from 'lodash';
import { createSelector } from 'reselect';

import { RootReducer } from '../rootReducer';

export const notificationSelector = createSelector(
  (state: RootReducer) => state.notifications,
  data => ({ ...data }),
);

export const notificationCountSelector = createSelector(
  (state: RootReducer) => state.notifications?.notifications,
  ({ data }) => (data?.length && data?.filter(v => v?.read_at === null)?.length) || 0,
);

export const liveFeedCountSelector = createSelector(
  (state: RootReducer) => state.notifications?.liveFeed,
  ({ data }) => data?.length || 0,
);
