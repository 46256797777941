import { combineReducers } from 'redux';

// import { MasterData } from './masterData/interfaces';
// import masterDataReducer from './masterData/reducer';
// import { PortfolioSettings } from './portfolioSettings/interfaces';
// import portfolioSettingsReducer from './portfolioSettings/reducer';
import { UserReducer } from './user/interfaces';
import userReducer from './user/reducer';
import homeReducer from './home/reducer';
import appReducer from './app/reducer';
import rolesReducer from './roles/reducer';
import rfqReducer from './rfq/reducer';
import rfqResponseReducer from './rfqResponse/reducer';
import cartReducer from './cart/reducer';
import businessReducer from './business/reducer';
import orderReducer from './orders/reducer';
import notificationReducer from './notifications/reducer';
import reviewReducer from './reviews/reducer';
import supplierBusinessReducer from './supplier/reducer';
import chatReducer from './chat/reducer';
import pointHistoryReducer from './pointHistory/reducer';
import { IHomeReducerInitialState } from './home/interfaces';
import { IAppReducerInitialState, IResult } from './app/interfaces';
import { IRolesReducer } from './roles/interfaces';
import { ISupplierReducer } from './supplier/interfaces';
import { IRfqReducer } from './rfq/interfaces';
import { IRfqResponseReducer } from './rfqResponse/interfaces';
import storage from 'redux-persist/lib/storage';
import { CLEAR_STORAGE_AFTER_LOGOUT } from './user/actions';
import { ICartReducerInitialState } from './cart/interfaces';
import { IBusinessReducer } from './business/interfaces';
import { IOrderReducer } from './orders/interfaces';
import { INotificationReducer } from './notifications/interfaces';
import { IChatReducer } from './chat/interfaces';
import { IPointHistoryReducer } from './pointHistory/interfaces';

const RESET_ERROR_STATE = 'RESET_ERROR_STATE';
const mainReducer = combineReducers({
  user: userReducer,
  home: homeReducer,
  app: appReducer,
  roles: rolesReducer,
  supplierBusiness: supplierBusinessReducer,
  business: businessReducer,
  rfq: rfqReducer,
  rfqResponse: rfqResponseReducer,
  cart: cartReducer,
  orders: orderReducer,
  notifications: notificationReducer,
  reviews: reviewReducer,
  chat: chatReducer,
  pointHistory: pointHistoryReducer,
});

const updateObject = (target, update) => {
  // for each key/value pair in update object
  for (const [key, value] of Object.entries(update)) {
    // if target has the relevant key and
    // the type in target and update is the same
    if (target.hasOwnProperty(key) && typeof value === typeof target[key]) {
      // update value if string,number or boolean
      if (['string', 'number', 'boolean'].includes(typeof value) || Array.isArray(value)) {
        target[key] = value;
      } else {
        // if type is object then go one level deeper
        if (typeof value === 'object') {
          updateObject(target[key], value);
        }
      }
    }
  }
};
const rootReducer = (state, action) => {
  if (action.type === CLEAR_STORAGE_AFTER_LOGOUT) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    // storage.removeItem('persist:otherKey')

    return mainReducer(
      {
        home: state.home,
        app: state.app,
        business: undefined,
        cart: undefined,
        orders: undefined,
        rfq: undefined,
        rfqResponse: undefined,
        roles: undefined,
        supplierBusiness: undefined,
        user: undefined,
        notifications: undefined,
        reviews: undefined,
        chat: undefined,
        pointHistory: undefined,
      },
      action,
    );
  }
  if (action.type === RESET_ERROR_STATE) {
    let targetState = state?.[action?.payload?.store];
    let updateState = action?.payload?.update;

    // return mainReducer({...state,[action.payload.store]:{...state?.[action.payload.store],{...action?.payload?.}}},action);
  }
  return mainReducer(state, action);
};

export type AppState = ReturnType<typeof rootReducer>;

export interface ActionType {
  type: string;
  payload?: any;
}

export interface PayloadHistory {
  payload: any;
  history: any;
}
export interface PayloadCallback {
  payload: any;
  onSuccess?: (data: any) => void;
  onError?: (data: any) => void;
}
export interface ActionTypeWithHistory {
  type: string;
  payload?: PayloadHistory;
}
export interface ActionTypeWithCallback {
  type: string;
  payload?: PayloadCallback;
}
export interface RootReducer {
  user: UserReducer;
  home: IHomeReducerInitialState;
  app: IAppReducerInitialState;
  roles: IRolesReducer;
  supplierBusiness: ISupplierReducer;
  business: IBusinessReducer;
  rfq: IRfqReducer;
  rfqResponse: IRfqResponseReducer;
  cart: ICartReducerInitialState;
  orders: IOrderReducer;
  notifications: INotificationReducer;
  reviews: IResult;
  chat: IChatReducer;
  pointHistory: IPointHistoryReducer;
}

export interface BaseResponse {
  result?: any;
  success: boolean;
}

export type StatusType = 'initial' | 'pending' | 'resolved' | 'rejected';

export default rootReducer;
