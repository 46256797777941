import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  saveOrderSuccess,
  saveOrderFailed,
  SAVE_ORDER,
  searchOrderSuccess,
  searchOrderFailed,
  getOrderSuccess,
  getOrderFailed,
  SEARCH_ORDER,
  GET_ORDER,
  ORDER_ACTION,
  orderActionSuccess,
  orderActionFailed,
} from './actions';
import { orderActionApi, getOrderApi, saveOrderApi, searchOrderApi } from 'services/api';
import { ActionType, ActionTypeWithCallback, ActionTypeWithHistory } from 'store/rootReducer';
import { appNotification } from 'store/app/actions';

function* saveOrderSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response = yield call(saveOrderApi, payload);

    yield put(saveOrderSuccess(response?.data));
    if (onSuccess)
      yield call(() => {
        onSuccess(response);
      });
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(appNotification({ type: 'danger', message }));
      if (onError) yield call(() => onError(error));
    } else {
      const { errors } = error?.response?.data;
      yield put(saveOrderFailed(errors));
    }
  }
}

function* searchOrderSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response = yield call(searchOrderApi, payload);

    yield put(searchOrderSuccess(response?.data));
    if (onSuccess)
      yield call(() => {
        onSuccess(response);
      });
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(appNotification({ type: 'danger', message }));
      if (onError) yield call(() => onError(error));
    } else {
      const { errors } = error?.response?.data;
      yield put(searchOrderFailed(errors));
    }
  }
}

function* getOrderSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response = yield call(getOrderApi, payload);

    yield put(getOrderSuccess(response?.data));
    if (onSuccess)
      yield call(() => {
        onSuccess(response);
      });
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = '';
      if (error) message = 'Oops something went wrong';
      yield put(appNotification({ type: 'danger', message }));
      if (onError) yield call(() => onError(error));
      yield put(getOrderFailed(''));
    } else {
      const { errors } = error?.response?.data;
      yield put(getOrderFailed(errors));
    }
  }
}

function* orderActionSaga({ payload: { payload, onSuccess, onError } }: ActionTypeWithCallback) {
  try {
    const response = yield call(orderActionApi, payload);

    yield put(orderActionSuccess(response?.data));
    if (onSuccess)
      yield call(() => {
        onSuccess(response);
      });
  } catch (error) {
    if (error?.response?.status != 422) {
      let message = error?.response?.data?.message || 'Oops something went wrong';
      yield put(appNotification({ type: 'danger', message }));
      if (onError) yield call(() => onError(error));
      yield put(orderActionFailed(''));
    } else {
      const { errors } = error?.response?.data;
      yield put(orderActionFailed(errors));
    }
  }
}

function* orderSaga() {
  yield all([
    takeLatest(SAVE_ORDER, saveOrderSaga),
    takeLatest(SEARCH_ORDER, searchOrderSaga),
    takeLatest(GET_ORDER, getOrderSaga),
    takeLatest(ORDER_ACTION, orderActionSaga),
  ]);
}

export default orderSaga;
