import { PayloadCallback } from 'store/rootReducer';

export const GET_ALL_SUPPLIER_BUSINESS = 'GET_ALL_SUPPLIER_BUSINESS';
export const GET_ALL_SUPPLIER_BUSINESS_SUCCESS = 'GET_ALL_SUPPLIER_BUSINESS_SUCCESS';
export const GET_ALL_SUPPLIER_BUSINESS_FAILED = 'GET_ALL_SUPPLIER_BUSINESS_FAILED';

export const SAVE_SUPPLIER_BUSINESS = 'SAVE_SUPPLIER_BUSINESS';
export const SAVE_SUPPLIER_BUSINESS_SUCCESS = 'SAVE_SUPPLIER_BUSINESS_SUCCESS';
export const SAVE_SUPPLIER_BUSINESS_FAILED = 'SAVE_SUPPLIER_BUSINESS_FAILED';

export const SAVE_BUSINESS_IN_STEP = 'SAVE_BUSINESS_IN_STEP';
export const SAVE_BUSINESS_IN_STEP_SUCCESS = 'SAVE_BUSINESS_IN_STEP_SUCCESS';
export const SAVE_BUSINESS_IN_STEP_FAILED = 'SAVE_BUSINESS_IN_STEP_FAILED';

export const RESET_BUSINESS_IN_STEP_FORM = 'RESET_BUSINESS_IN_STEP_FORM';

export const GET_BUSINESS_IN_STEP = 'GET_BUSINESS_IN_STEP';
export const GET_BUSINESS_IN_STEP_SUCCESS = 'GET_BUSINESS_IN_STEP_SUCCESS';
export const GET_BUSINESS_IN_STEP_FAILED = 'GET_BUSINESS_IN_STEP_FAILED';

export const SAVE_SERVICE_PROVIDER_BUSINESS = 'SAVE_SERVICE_PROVIDER_BUSINESS';
export const SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS = 'SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS';
export const SAVE_SERVICE_PROVIDER_BUSINESS_FAILED = 'SAVE_SERVICE_PROVIDER_BUSINESS_FAILED';

export const UPDATE_SUPPLIER_BUSINESS = 'UPDATE_SUPPLIER_BUSINESS';
export const UPDATE_SUPPLIER_BUSINESS_SUCCESS = 'UPDATE_SUPPLIER_BUSINESS_SUCCESS';
export const UPDATE_SUPPLIER_BUSINESS_FAILED = 'UPDATE_SUPPLIER_BUSINESS_FAILED';

export const ADD_NEW_SERVICE_PROVIDER_BUSINESS = 'ADD_NEW_SERVICE_PROVIDER_BUSINESS';
export const ADD_NEW_SERVICE_PROVIDER_BUSINESS_SUCCESS =
  'ADD_NEW_SERVICE_PROVIDER_BUSINESS_SUCCESS';
export const ADD_NEW_SERVICE_PROVIDER_BUSINESS_FAILED = 'ADD_NEW_SERVICE_PROVIDER_BUSINESS_FAILED';

export const getAllSupplierBusiness = payload => ({
  type: GET_ALL_SUPPLIER_BUSINESS,
  payload,
});

export const getAllSupplierBusinessSuccess = data => ({
  type: GET_ALL_SUPPLIER_BUSINESS_SUCCESS,
  payload: data,
});

export const getAllSupplierBusinessFailed = error => ({
  type: GET_ALL_SUPPLIER_BUSINESS_FAILED,
  payload: error,
});

export const saveBusinessInStep = (payload: PayloadCallback) => ({
  type: SAVE_BUSINESS_IN_STEP,
  payload,
});

export const saveBusinessInStepSuccess = data => ({
  type: SAVE_BUSINESS_IN_STEP_SUCCESS,
  payload: data,
});

export const saveBusinessInStepFailed = error => ({
  type: SAVE_BUSINESS_IN_STEP_FAILED,
  payload: error,
});

export const getBusinessInStep = payload => ({
  type: GET_BUSINESS_IN_STEP,
  payload,
});

export const getBusinessInStepSuccess = data => ({
  type: GET_BUSINESS_IN_STEP_SUCCESS,
  payload: data,
});

export const getBusinessInStepFailed = error => ({
  type: GET_BUSINESS_IN_STEP_FAILED,
  payload: error,
});

export const resetBusinessInStepForm = () => ({
  type: RESET_BUSINESS_IN_STEP_FORM,
});

export const saveSupplierBusiness = ({ payload, history }) => ({
  type: SAVE_SUPPLIER_BUSINESS,
  payload: { payload, history },
});

export const saveSupplierBusinessSuccess = data => ({
  type: SAVE_SUPPLIER_BUSINESS_SUCCESS,
  payload: data,
});

export const saveSupplierBusinessFailed = error => ({
  type: SAVE_SUPPLIER_BUSINESS_FAILED,
  payload: error,
});

export const addNewServiceProviderBusiness = (payload: PayloadCallback) => ({
  type: ADD_NEW_SERVICE_PROVIDER_BUSINESS,
  payload,
});

export const addNewServiceProviderBusinessSuccess = data => ({
  type: ADD_NEW_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  payload: data,
});

export const addNewServiceProviderBusinessFailed = error => ({
  type: ADD_NEW_SERVICE_PROVIDER_BUSINESS_FAILED,
  payload: error,
});

export const updateSupplierBusiness = (payload: any) => ({
  type: UPDATE_SUPPLIER_BUSINESS,
  payload,
});

export const updateSupplierBusinessSuccess = data => ({
  type: UPDATE_SUPPLIER_BUSINESS_SUCCESS,
  payload: data,
});

export const updateSupplierBusinessFailed = error => ({
  type: UPDATE_SUPPLIER_BUSINESS_FAILED,
  payload: error,
});

export const saveServiceProviderBusiness = ({ payload, history }) => ({
  type: SAVE_SERVICE_PROVIDER_BUSINESS,
  payload: { payload, history },
});

export const saveServiceProviderBusinessSuccess = data => ({
  type: SAVE_SERVICE_PROVIDER_BUSINESS_SUCCESS,
  payload: data,
});

export const saveServiceProviderBusinessFailed = error => ({
  type: SAVE_SERVICE_PROVIDER_BUSINESS_FAILED,
  payload: error,
});
