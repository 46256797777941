import 'react-notifications-component/dist/theme.css';
import { Suspense, useEffect } from 'react';
// import ReactNotification from 'react-notifications-component';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import Spinner from './components/Spinner/Spinner';
import Routes from './routes/Routes';

import 'config/i18n';
import { resetError, whoAmIRequest } from 'store/user/actions';
import GlobalStyle from 'theme/globalStyles';
import { fetchCsrfToken } from 'store/home/actions';
import { getRoles } from 'store/roles/actions';
import { RootReducer } from 'store/rootReducer';
import { Button, Toast, ToastBody, ToastHeader } from 'react-bootstrap';
import { appNotification, fetchCountry, fetchCountryCode } from 'store/app/actions';
import { getCountryCode } from 'services/utils';
import { getCart } from 'store/cart/actions';
import { getUserSession } from 'services/auth';
import {
  addNewLiveFeed,
  addNewNotification,
  getAllNotification,
  getLiveFeed,
} from 'store/notifications/actions';
import { userSelector } from 'store/user/selectors';
import { useSocket } from 'services/hooks/useSocketHook';
import { notifyReact } from 'services/mobileService';
import IconButton from 'components/IconButton/IconButton';
import { useModal } from 'hooks/useModal';
import CustomModal from 'components/CustomModal/CustomModal';
import Chat from 'components/Chat/Chat';
import { chatRoomSelector } from 'store/chat/selectors';
import { getChatRoom } from 'store/chat/actions';
import { getTotalPoint } from 'store/pointHistory/actions';

function App() {
  const dispatch = useDispatch();
  const { type, message } = useSelector((state: RootReducer) => state.app.alertMsg);
  const { open: chatOpen, onOpen: openChat, onClose: closeChat } = useModal();
  const { data: rooms, loading: roomLoading, error: roomError } = useSelector(chatRoomSelector);

  // const user = getUserSession();
  const { user } = useSelector(userSelector);
  useEffect(() => {
    dispatch(fetchCsrfToken());
    // dispatch(whoAmIRequest());
    dispatch(getRoles());
    dispatch(fetchCountry({ search: '' }));
    dispatch(fetchCountryCode({ search: '' }));

    if (user?.id) {
      dispatch(getAllNotification());
      dispatch(getCart());
      dispatch(getTotalPoint());
      notifyReact({ id: user?.id }, 'GET_USER');
    }
  }, [dispatch, user]);

  const closeToast = e => dispatch(appNotification({ type: 'info', message: null }));

  useEffect(() => {
    let timer: any;
    if (message) {
      timer = setTimeout(() => dispatch(appNotification({ type: 'info', message: null })), 3000);
    }
    return () => clearTimeout(timer);
  }, [message]);

  useEffect(() => {
    if (chatOpen) {
      dispatch(getChatRoom());
    }
  }, [chatOpen]);

  useSocket(
    {
      callBack: notification => {
        console.log('app-user2', notification);
        dispatch(
          addNewNotification({
            ...notification,
            read_at: null,
            created_at: new Date().toISOString(),
          }),
        );
      },
    },
    [],
  );
  useSocket(
    {
      callBack: feed => {
        console.log('app-user2', feed);
        dispatch(
          addNewLiveFeed({
            ...feed,
            read_at: null,
            created_at: new Date().toISOString(),
          }),
        );
      },
    },
    [],
    '/LiveFeed/user',
  );
  const googleTranslateScript = () => {
    const script = document.createElement('script');
    const script2 = document.createElement('script');
    const id = 'goog-translate-script';
    const funcId = 'google-translate-function';
    script.id = id;
    script.src =
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    script2.id = funcId;
    script2.append(`function googleTranslateElementInit() {
      new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
      document
      .querySelector('#google_translate_element select')
      .addEventListener('change', function (e) {
        if(e.target.value === 'ar')
          document.querySelector('#main-container').dir = 'rtl'
        else
          document.querySelector('#main-container').dir = 'ltr'

        document.body.setAttribute(
          'style',
          'position: relative;min-height: 100%;top: 0px !important;',
        );
      });

    }`);
    document.body.appendChild(script2);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
    };
  };
  // useEffect(() => {
  //   window.addEventListener('message', function (data: any) {
  //     // const details = JSON.parse(data?.data);
  //     // console.log('details', details);
  //   });
  //   return window.removeEventListener('message', function (e) {
  //     // console.log('details', e);
  //   });
  // }, []);
  // useEffect(() => {
  //   return googleTranslateScript();
  // }, []);

  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        {/* <div id="google_translate_element"></div> */}
        <Routes />
      </Suspense>
      {message && (
        <Toast
          className={`bg-${type} text-white`}
          style={{ position: 'fixed', top: 60, right: 10, zIndex: 100 }}>
          <ToastBody>
            <div className="d-flex justify-content-between align-items-center">
              <span>{message}</span>{' '}
              <Button variant="flat" onClick={closeToast}>
                <i className="fa fa-close text-white" />
              </Button>
            </div>
          </ToastBody>
        </Toast>
      )}
      {/* <ReactNotification /> */}
      {user?.whats_app_group?.groupUrl && (
        <div
          style={{
            position: 'fixed',
            bottom: '44%',
            right: '0%',
            borderRadius: '20px 0 0 20px',
            padding: '2px 5px 2px 7px',
            background: ' rgb(38, 211, 103)',
            display: 'flex',
            zIndex: '10000',
            opacity: '0.7',
            cursor: 'pointer',
          }}>
          <a
            target="_blank"
            href={user?.whats_app_group?.groupUrl}
            rel="noreferrer"
            className="d-flex justify-content-center align-items-center"
            style={{ padding: '3px 0px' }}>
            <i className="fa fa-2x fa-whatsapp text-white"></i>
          </a>
        </div>
      )}
      {user && (
        <div
          style={{
            position: 'fixed',
            bottom: '44%',
            right: '0%',
            borderRadius: '20px 0 0 20px',
            padding: '2px 5px 2px 7px',
            background: ' rgb(38, 211, 103)',
            display: 'flex',
            zIndex: '1055',
            opacity: '0.7',
            cursor: 'pointer',
          }}>
          <IconButton onClick={openChat}>
            <i className="fa fa-comments text-white font-size-20" />
          </IconButton>
        </div>
      )}
      <GlobalStyle />
      <CustomModal noHeader noFooter fullscreen open={chatOpen} closeModal={closeChat}>
        <Chat closeChat={closeChat} rooms={rooms} loading={roomLoading} currentUser={user} />
      </CustomModal>
    </Router>
  );
}

export default App;
