import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchCitiesSuccess,
  fetchCountryCodeSuccess,
  fetchCountrySuccess,
  fetchProvinceSuccess,
  FETCH_CITIES,
  FETCH_COUNTRY,
  FETCH_COUNTRY_CODE,
  FETCH_PROVINCE,
  SAVE_CURRENT_LOCATION,
} from './actions';

import i18n from 'config/i18n';
import { getCitiesApi, getCounrty, getCountryCode, getProvince } from 'services/api';
import { addNotification } from 'services/notifications';
import { ActionType } from 'store/rootReducer';
import { logError } from 'store/user/actions';
import localforage, { setItem } from 'localforage';

function* countrySaga({ payload }: ActionType) {
  try {
    const data: Response = yield call(getCounrty, payload);
    yield put(fetchCountrySuccess(data));
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}

function* countryCodeSaga({ payload }: ActionType) {
  try {
    const data: Response = yield call(getCountryCode, payload);
    yield put(fetchCountryCodeSuccess(data));
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}

function* provinceSaga({ payload }: ActionType) {
  try {
    const data: Response = yield call(getProvince, payload);
    yield put(fetchProvinceSuccess(data));
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}

function* citiesSaga({ payload }: ActionType) {
  try {
    const data: Response = yield call(getCitiesApi, payload);
    yield put(fetchCitiesSuccess(data));
  } catch (e: any) {
    yield put(logError(e));
    if (e?.response?.data.status === 417) {
      addNotification({
        message: i18n.t('error.incorrectCredentials'),
        title: i18n.t('error.error'),
        type: 'danger',
      });
    }
  }
}
function* saveCurrentLocationSaga({ payload }: ActionType) {
  yield call([localforage, localforage.setItem], 'location', payload.currentLocation);
}

function* appSaga() {
  yield all([
    takeLatest(FETCH_COUNTRY, countrySaga),
    takeLatest(FETCH_PROVINCE, provinceSaga),
    takeLatest(FETCH_CITIES, citiesSaga),
    takeLatest(FETCH_COUNTRY_CODE, countryCodeSaga),
    takeLatest(SAVE_CURRENT_LOCATION, saveCurrentLocationSaga),
  ]);
}

export default appSaga;
