import './Notifications.scss';
import IconButton from 'components/IconButton/IconButton';
import { useEffect } from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserSession } from 'services/auth';
import {
  addNewNotification,
  deleteNotification,
  getAllNotification,
  markAsReadNotification,
} from 'store/notifications/actions';
import { notificationSelector } from 'store/notifications/selectors';
import { ReactComponent as NotifcationIcon } from '../../assets/icons/bell.svg';
import dayjs from 'dayjs';
interface INotificationsProps {
  onCloseNotification?: () => void;
}
const Notifications = ({ onCloseNotification }: INotificationsProps) => {
  const user = getUserSession();
  const dispatch = useDispatch();
  const {
    notifications: { data, loading, error },
    notificationLoading,
  } = useSelector(notificationSelector);
  const history = useHistory();
  const handleClick = item => e => {
    if (item?.data?.link) {
      history.push(item?.data?.link);
      dispatch(deleteNotification({ payload: item }));
      if (onCloseNotification) onCloseNotification();
    }
  };

  if (loading)
    return (
      <div>
        <Spinner animation="border" />
      </div>
    );
  return (
    <>
      <div className="notification-root">
        {data?.length ? (
          <ul className="notification-list">
            {data?.map(v => (
              <li key={v?.id} onClick={handleClick(v)}>
                <span className="font-size-13 text-black">
                  {v?.read_at ? v?.data?.message : <b>{v?.data?.message}</b>}
                </span>
                <span className="font-size-11 d-block">
                  <i className="fa fa-clock-o pe-1 pt-1" />
                  {dayjs(v?.created_at).format('DD MMM @ hh:mm A')}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <NotifcationIcon width={32} height={32} />
            <span>No notifications</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Notifications;
