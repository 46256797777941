import { PayloadCallback } from 'store/rootReducer';

export const SAVE_RFQ_REQUEST = 'SAVE_RFQ_REQUEST';
export const SAVE_RFQ_REQUEST_SUCCESS = 'SAVE_RFQ_REQUEST_SUCCESS';
export const SAVE_RFQ_REQUEST_FAILED = 'SAVE_RFQ_REQUEST_FAILED';

export const GET_RFQ_REQUEST = 'GET_RFQ_REQUEST';
export const GET_RFQ_REQUEST_SUCCESS = 'GET_RFQ_REQUEST_SUCCESS';
export const GET_RFQ_REQUEST_FAILED = 'GET_RFQ_REQUEST_FAILED';

export const GET_RFQ_REQUEST_DETAIL = 'GET_RFQ_REQUEST_DETAIL';
export const GET_RFQ_REQUEST_DETAIL_SUCCESS = 'GET_RFQ_REQUEST_DETAIL_SUCCESS';
export const GET_RFQ_REQUEST_DETAIL_FAILED = 'GET_RFQ_REQUEST_DETAIL_FAILED';

export const GET_RFQ_REQUEST_DETAIL_RESPONSES = 'GET_RFQ_REQUEST_DETAIL_RESPONSES';
export const GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS = 'GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS';
export const GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED = 'GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED';

export const CANCEL_RFQ_REQUEST = 'CANCEL_RFQ_REQUEST';
export const CANCEL_RFQ_REQUEST_SUCCESS = 'CANCEL_RFQ_REQUEST_SUCCESS';
export const CANCEL_RFQ_REQUEST_FAILED = 'CANCEL_RFQ_REQUEST_FAILED';

export const ADD_RFQ_REQUEST = 'ADD_RFQ_REQUEST';
export const ADD_RFQ_REQUEST_SUCCESS = 'ADD_RFQ_REQUEST_SUCCESS';
export const ADD_RFQ_REQUEST_FAILED = 'ADD_RFQ_REQUEST_FAILED';

export const saveRfqRequest = ({ payload, onSuccess }: PayloadCallback) => ({
  type: SAVE_RFQ_REQUEST,
  payload: { payload, onSuccess },
});

export const saveRfqRequestSuccess = data => ({
  type: SAVE_RFQ_REQUEST_SUCCESS,
  payload: data,
});

export const saveRfqRequestFailed = error => ({
  type: SAVE_RFQ_REQUEST_FAILED,
  payload: error,
});

export const getRfqRequest = ({ payload }) => ({
  type: GET_RFQ_REQUEST,
  payload,
});

export const getRfqRequestSuccess = data => ({
  type: GET_RFQ_REQUEST_SUCCESS,
  payload: data,
});

export const getRfqRequestFailed = error => ({
  type: GET_RFQ_REQUEST_FAILED,
  payload: error,
});

export const getRfqRequestDetail = ({ payload }) => ({
  type: GET_RFQ_REQUEST_DETAIL,
  payload,
});

export const getRfqRequestDetailSuccess = data => ({
  type: GET_RFQ_REQUEST_DETAIL_SUCCESS,
  payload: data,
});

export const getRfqRequestDetailFailed = error => ({
  type: GET_RFQ_REQUEST_DETAIL_FAILED,
  payload: error,
});

export const getRfqRequestDetailResponses = ({ payload }) => ({
  type: GET_RFQ_REQUEST_DETAIL_RESPONSES,
  payload,
});

export const getRfqRequestDetailResponsesSuccess = data => ({
  type: GET_RFQ_REQUEST_DETAIL_RESPONSES_SUCCESS,
  payload: data,
});

export const getRfqRequestDetailResponsesFailed = error => ({
  type: GET_RFQ_REQUEST_DETAIL_RESPONSES_FAILED,
  payload: error,
});

export const cancelRfqRequest = (payload: PayloadCallback) => ({
  type: CANCEL_RFQ_REQUEST,
  payload,
});

export const cancelRfqRequestSuccess = payload => ({
  type: CANCEL_RFQ_REQUEST_SUCCESS,
  payload,
});

export const cancelRfqRequestFailed = payload => ({
  type: CANCEL_RFQ_REQUEST_FAILED,
  payload,
});

export const addRfqRequest = ({ payload, onSuccess }: PayloadCallback) => ({
  type: ADD_RFQ_REQUEST,
  payload: { payload, onSuccess },
});

export const addRfqRequestSuccess = data => ({
  type: ADD_RFQ_REQUEST_SUCCESS,
  payload: data,
});

export const addRfqRequestFailed = error => ({
  type: ADD_RFQ_REQUEST_FAILED,
  payload: error,
});
